import React, { useContext, useRef, useEffect, useState } from 'react'
import L from 'leaflet'
// import 'leaflet/dist/leaflet.css'
import 'leaflet-imageoverlay-rotated'
import 'leaflet-toolbar'
// import 'leaflet-distortableimage'
import MapControls from './MapControls'
import { Loader, Alert, Whisper, Tooltip } from 'rsuite'
import * as turf from '@turf/turf'
import { useTranslation } from 'react-i18next'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import * as FA from '@fortawesome/free-solid-svg-icons'
import '../styles/sidenav.css'
import Cookies from 'js-cookie'
import { addGeoJSON, deleteGeoJSON, getDataPoints, getGeoJSON, getFields } from '../http/API'
import FieldsListMain from '../sidecomponents/sidewindows/FieldsListMain'
import DataPointSelector from '../sidecomponents/sidewindows/DataPointSelector'
import YieldPrediction from '../pages/YieldPrediction'
import DataSave from '../pages/DataSave'

const MapPred = () => {
  const [jsonToSave, saveJSON] = useState('')
  const [markerJSON, setMarkerJSON] = useState('')
  const [jsonToDisplay, setJSON] = useState([])
  const [datapoints, setPoints] = useState([])
  const [fieldToUpdate, setFieldToUpdate] = useState()
  const [updateKey, update] = useState(0)
  const [fieldID, setFieldID] = useState()
  const [coords, setCoords] = useState()
  const [checker, sureness] = useState(false)
  const [cropType, setCropType] = useState()
  const [foundFields, setFoundFields] = useState([])

  const dic = {
    maslo: ['Подсолнечник'],
    gor: ['Горох посевной'],
    zerno: ['Пшеница озимая', 'Пшеница яровая'],
    kormo: ['Ячмень яровой', 'Кукуруза на силос']
  }

  const [mapType, setMap] = useState(Cookies.get('pref-map') || 'http://mt1.google.com/vt/lyrs=y&x={x}&y={y}&z={z}')
  function sortByProperty (property) {
    return function (a, b) {
      if (a[property] > b[property]) {
        return 1
      } else if (a[property] < b[property]) {
        return -1
      }
      return 0
    }
  }
  const CloseOtherModals = () => {
    setFieldsListShow(false)
    setNotesListShow(false)
    setPredictShow(false)
    setDataModalShow(false)
  }
  useEffect(async () => {
    const fieldsList = await getGeoJSON()
      .catch((e) => {
        if (String(e).includes('401')) {
          // Cookies.remove('token')
          window.location.href = '/map_main'
        }
      })
    if (fieldsList.response.success) {
      setJSON(fieldsList.response.fields.sort(sortByProperty('id')))
      try {
        if (updateKey === 0) {
          fitBounds(JSON.parse(fieldsList.response.fields[0].json))
        }
      } catch (e) {

      }
    }
    showLayerRef.current.clearLayers()
  }, [updateKey])
  useEffect(() => {
    datapoints.forEach(p => {
      const point = L.Marker(p.coordinates)
      point.addTo(mapRef.current)
    })
  }, [datapoints])
  useEffect(() => {
    const fetchData = async () => {
      setFoundFields([])
      searchGeometryRef.current.clearLayers()
      const p = await getFields().then(res => {
        const notes = res.response.fields.reverse()
        jsonToDisplay.forEach((f) => {
          //
          const note = notes.find(e => e.field_id === f.id)

          if (note && cropType && dic[cropType]?.includes(note.croptype)) {
            const mask = L.geoJSON(JSON.parse(f.json))
            const name = JSON.parse(f.json).properties.name

            if (JSON.parse(f.json).geometry?.type === 'Point') {
              mask.bindTooltip(() => name, { permanent: true })
              mask.addEventListener('mouseover', () => {
                mask.unbindTooltip()
                const time = new Date(f.createdAt)
                mask.bindTooltip(() => `Создано: ${time.toLocaleString('ru-RU')}`, { permanent: true })
              })
              mask.addEventListener('mouseout', () => {
                mask.unbindTooltip()
                mask.bindTooltip(() => name, { permanent: true })
              })
            } else {
              mask.bindTooltip(() => name)
              mask.addEventListener('click', () => { setFieldID(f.id); setfieldMaster(f.id); setfieldMasterOrig(f) })
              mask.addEventListener('dblclick', () => {
                fitBounds(JSON.parse(f.json))
              })
            }
            mask.addEventListener('mouseover', () => (mask.openTooltip()))
            searchGeometryRef.current.addLayer(mask)
            setFoundFields(foundFields => [...foundFields, f])
            // fitBounds(JSON.parse(f.json))
          }
        })
      })
    }
    cropType && fetchData()
  }, [cropType])
  useEffect(() => {

  }, [foundFields])
  const [FieldsListShow, setFieldsListShow] = useState(false)
  const [NotesListShow, setNotesListShow] = useState(false)
  const [DataModalShow, setDataModalShow] = useState(false)
  const [PredictShow, setPredictShow] = useState(true)

  const [fieldMaster, setfieldMaster] = useState()
  const [fieldMasterOrig, setfieldMasterOrig] = useState()
  const [fieldNameMaster, setfieldNameMaster] = useState()
  const { t, i18n } = useTranslation()
  // const { dataTiles, fetchDataTiles, activeTile, setActiveTile } = useContext(SatImgContext)
  // const { searchOptions, setSearchOptions, jsonLayers, newOrder, setNewOrder } = useContext(SearchOptionsContext)
  const [isLoading, setIsLoading] = useState(false)

  const MAX_AREA = 6313063483978.778// in ha
  const footprintOptions = {
    stroke: true,
    // color: '#4185f4',
    color: '#E3AF34',
    weight: 3,
    opacity: 0.6,
    fill: true,
    fillColor: null,
    fillOpacity: 0,
    clickable: true
  }

  const mapRef = useRef(null) // map
  const paneRef = useRef(null) // tiles order on top
  const drawnLayerRef = useRef(null) // drawn footrpints - paths (lines) = featureGroup
  const markerRef = useRef(null) // drawn footrpints - paths (lines) = featureGroup
  const showLayerRef = useRef(null) // drawn footrpints - paths (lines) = featureGroup
  const tileLayerRef = useRef(null) // tiles in tilelayer = featureGroup
  const searchGeometryRef = useRef(null) // search geometries on the map = featureGroup
  // const [maptype, changeMap] = useState('alidade_smooth_dark')
  let map

  function clearLayers () {
    if (mapRef?.current) {
      // if (mapRef.current.hasLayer(searchGeometryRef.current)) searchGeometryRef.current.clearLayers()
      if (mapRef.current.hasLayer(drawnLayerRef.current)) drawnLayerRef.current.clearLayers()
      if (mapRef.current.hasLayer(markerRef.current)) markerRef.current.clearLayers()
      if (mapRef.current.hasLayer(tileLayerRef.current)) tileLayerRef.current.clearLayers()
    }
  }

  /** map initialization */
  useEffect(() => {
    try {
      map = L.map('map', {
        center: [49.945963, 82.613922],
        zoom: 10,
        minZoom: 1,
        zoomControl: false,
        attributionControl: false
      })
      L.tileLayer(mapType, {
        subdomains: ['mt0', 'mt1', 'mt2', 'mt3'],
        maxZoom: 18,
        id: 'baselayer'
      }).addTo(map)
      L.control.attribution({
        position: 'bottomright'
      }).addTo(map)
      // map.fitBounds([[47.12995075666307, 52.064208984375], [51.1807, 71.461]])

      // Searched geometries
      searchGeometryRef.current = L.featureGroup().addTo(map)
      markerRef.current = L.featureGroup().addTo(map)
      showLayerRef.current = L.featureGroup().addTo(map)
      drawnLayerRef.current = L.featureGroup().addTo(map)
      tileLayerRef.current = L.featureGroup().addTo(map)
      mapRef.current = map
      // Pane for tiles topping on the map
      const topPane = mapRef?.current.createPane('leaflet-top-pane', mapRef.current.getPanes().mapPane)
      paneRef.current = topPane

      // Adding Controls
      MapControls(mapRef.current, t)
      mapRef.current.invalidateSize(9)
    } catch (e) {

    }
  }, [])
  useEffect(() => {
    // mapRef.current.removeLayer('baselayer')
    mapRef.current.attributionControl.setPrefix(Cookies.get('pref-attr'))
    L.tileLayer(mapType, {
      maxZoom: 18,
      id: 'baselayer'
    }).addTo(mapRef.current)
  }, [mapType])
  useEffect(() => {
    searchGeometryRef.current.clearLayers()
    jsonToDisplay.forEach((f) => {
      const mask = L.geoJSON(JSON.parse(f.json))
      const name = JSON.parse(f.json).properties.name

      if (JSON.parse(f.json).geometry?.type === 'Point') {
        mask.bindTooltip(() => name, { permanent: true })
        mask.addEventListener('mouseover', () => {
          mask.unbindTooltip()
          const time = new Date(f.createdAt)
          mask.bindTooltip(() => `Создано: ${time.toLocaleString('ru-RU')}`, { permanent: true })
        })
        mask.addEventListener('mouseout', () => {
          mask.unbindTooltip()
          mask.bindTooltip(() => name, { permanent: true })
        })
      } else {
        mask.bindTooltip(() => name)
        mask.addEventListener('click', () => { setFieldID(f.id); setfieldMaster(f.id); setfieldMasterOrig(f) })
        mask.addEventListener('dblclick', () => {
          fitBounds(JSON.parse(f.json))
        })
      }
      mask.addEventListener('mouseover', () => (mask.openTooltip()))
      searchGeometryRef.current.addLayer(mask)
    })
  }, [jsonToDisplay])
  /** Handling drawing on map */
  const handleDrawCreated = React.useCallback(e => {
    clearLayers()
    const layer = e.layer
    if (e.layerType === 'marker') {
      const gjson = layer.toGeoJSON()

      setCoords(gjson)
      // setMarkerJSON(JSON.stringify(gjson))
      drawnLayerRef.current.addLayer(layer)
    } else {
      const area = L.GeometryUtil.geodesicArea(layer.getLatLngs()[0])
      const gjson = layer.toGeoJSON()
      gjson.properties = {
        name: 'test',
        area: area
      }
      saveJSON(JSON.stringify(gjson))
      if (area <= MAX_AREA) {
        // setSearchLayer(() => layer.toGeoJSON())
      } else {
        Alert.info(t('errors.bigarea'), 5000)
      }
      drawnLayerRef.current.addLayer(layer)
    }
  }, [])
  useEffect(() => {
    // mapRef.current.on('draw:created', function (e) {
    //   // const type = e.layerType
    //   const layer = e.layer
    //   setSearchLayer(layer.toGeoJSON())
    //   searchGeometryRef.current.clearLayers()
    //   searchGeometryRef.current.addLayer(e.layer)
    // })
    mapRef.current.on('draw:created', handleDrawCreated)
  }, [handleDrawCreated])

  const fitBounds = (field) => {
    const mask = L.geoJSON(field)

    if (field.geometry?.type === 'Point') {
      const lat = field.geometry.coordinates[1]
      const lng = field.geometry.coordinates[0]
      mapRef.current.panTo([lat, lng], 10)
    } else {
      mapRef.current.fitBounds(mask.getLayers()[0].getLatLngs())
    }
  }
  return (
    <>
      <nav className='navbar-side-new'>
        {/* <button className='new-link-element'>
                <img src='/logo192.png' alt='' width='40px'/>
      </button> */}
        {/* <button className={`new-link-element ${FieldsListShow ? 'dropdown-chosen' : ''}`} onClick={() => { CloseOtherModals(); setFieldsListShow(!FieldsListShow) }} title={t('map.sidebar.fieldslist')}>
              <FontAwesomeIcon icon={FA.faMapMarkedAlt} />
      </button> */}
        <button className={`new-link-element ${PredictShow ? 'dropdown-chosen' : ''}`} onClick={() => { CloseOtherModals(); setPredictShow(!PredictShow) }} title='Пронозирование урожайности'>
          <FontAwesomeIcon icon={FA.faBook} />
          <h5>Пронозирование</h5>
        </button>
        <button className={`new-link-element ${DataModalShow ? 'dropdown-chosen' : ''}`} onClick={() => { CloseOtherModals(); setDataModalShow(!DataModalShow) }} title='Заметки'>
          <FontAwesomeIcon icon={FA.faList} />
          <h5>Заметки</h5>
        </button>
        <button className='new-link-element' onClick={() => i18n.changeLanguage(i18n.language === 'ru' ? 'kz' : i18n.language === 'kz' ? 'en' : 'ru')} title='Смена языка'>
          <FontAwesomeIcon icon={FA.faGlobe} />
          <h5>
            Выбор языка: {i18n.language.toUpperCase()}
          </h5>
        </button>
        {/* <button className='new-link-element' onClick={() => window.open('/fielddata.xlsx')} title='Данные анализа'>
          <FontAwesomeIcon icon={FA.faAddressBook} />
          <h5>
            Данные анализа
          </h5>
        </button>
        <button className='new-link-element' onClick={() => window.open('/weatherdata.xlsx')} title='Данные погоды'>
          <FontAwesomeIcon icon={FA.faCloud} />
          <h5>
            Данные погоды
          </h5>
        </button> */}
        <button onClick={() => { window.location.href = '/profile' }} className='new-link-element' title={t('common.return')}>
          <FontAwesomeIcon icon={FA.faBackward} />
          <h5>{t('common.return')}</h5>
        </button>
      </nav>
      <div className='navbar-side-wrapper'>
        <FieldsListMain layer={showLayerRef.current} setFieldToUpdate={setFieldToUpdate} hideFunc={setFieldsListShow} setFieldMaster={setfieldMaster} setFieldNameMaster={setfieldNameMaster} fitBounds={fitBounds} fields={jsonToDisplay} onHide={() => setFieldsListShow(false)} adClass={(!FieldsListShow && !NotesListShow) ? 'hidden' : ''} notesModal={NotesListShow} fieldsModal={FieldsListShow} chems setFieldID={setFieldID} />
        <YieldPrediction display={PredictShow} field={fieldMasterOrig} setCropType={setCropType} jsonToDisplay={jsonToDisplay} foundFields={foundFields} fitBounds={fitBounds} setfieldMasterOrig={setfieldMasterOrig} />
        <DataSave adClass={!DataModalShow ? 'hidden' : ''} slave fieldId_master={fieldMaster} hideFunc={setDataModalShow} />
      </div>
      <div id='map'>
        {isLoading && <Loader inverse size='md' center content='loading' className='z-600' />}
      </div>
    </>
  )
}

export default MapPred
