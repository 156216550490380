import React, { useState, useEffect } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import * as FA from '@fortawesome/free-solid-svg-icons'
import { getUsers, getKeys, generateRegKey, deactivateKey, getMessages, removeMessage } from '../http/API'
import { Input, Alert, Button, ControlLabel } from 'rsuite'
import Cookies from 'js-cookie'
import jwtDecode from 'jwt-decode'
import { useTranslation } from 'react-i18next'

const Docs = () => {
  const { t, i18n } = useTranslation()
  return (
    <div className='docs-wrapper'>
      <nav className='docs-nav'>
        <div className='header__logo' id='logo'>
          <div>
            <img className='header__img' style={{ height: '80px' }} src='./img/favicon.png' alt='' />
            <img className='header__little-img' src='./img/little_icon.svg' alt='' />
          </div>
          <div className='header__name'>
            <div className='header__name-agro'>Smart</div>
            <div className='header__name-bot'>Agronom</div>
          </div>
        </div>
        {/* <a className='docs-node' href='#cat-1'>Кадастровые номера полей</a> */}
        {/* <a className='docs-node' href='#cat-2'>Документация регулирующая полеты БПЛА</a> */}
        {/* <a className='docs-node' href='#cat-3'>Документы пилотов</a> */}
        {/* <a className='docs-node' href='#cat-4'>Состояние БПЛА </a> */}
        {/* <a className='docs-node' href='#cat-5'>Болезни</a> */}
        {/* <a className='docs-node' href='#cat-6'>Вредители</a> */}
        {/* <a className='docs-node' href='#cat-7'>Удобрения</a> */}
        {/* <a className='docs-node' href='#cat-8'>Нормы</a> */}
        {/* <a className='docs-node' href='#cat-9'>Лицензии</a> */}
        {/* <a className='docs-node' href='#cat-10'>Аккредитация лаборатории</a> */}
        {/* <a className='docs-node' href='#cat-11'>Пестициды</a>
        <a className='docs-node' href='#cat-12'>Гербициды</a>
        <a className='docs-node' href='#cat-13'>Десиканты</a>
        <a className='docs-node' href='#cat-14'>Инсектициды и акарициды сельскохозяйственные</a>
        <a className='docs-node' href='#cat-15'>Моллюскоциды</a>
        <a className='docs-node' href='#cat-16'>Нематициды</a>
        <a className='docs-node' href='#cat-17'>Репелленты</a>
        <a className='docs-node' href='#cat-18'>Родентициды</a>
        <a className='docs-node' href='#cat-19'>Феромоны</a>
        <a className='docs-node' href='#cat-20'>Фумиганты</a>
        <a className='docs-node' href='#cat-21'>Фунгициды</a>
        <a className='docs-node' href='#cat-22'>Регуляторы роста растений</a> */}
        <br />
        <a className='docs-node docs-active-node' href='/profile'>&lt;&lt;{t('common.back')}</a>
      </nav>
      <main className='docs-main'>
        {/* <h3 id='cat-1'>Кадастровые номера полей</h3>
      <h3 id='cat-2'>Документация регулирующая полеты БПЛА</h3>
      <h3 id='cat-3'>Документы пилотов</h3>
      <h3 id='cat-4'>Состояние БПЛА </h3>
      <h3 id='cat-5'>Болезни</h3>
      <h3 id='cat-6'>Вредители</h3>
      <h3 id='cat-7'>Удобрения</h3>
      <h3 id='cat-8'>Нормы</h3>
      <h3 id='cat-9'>Лицензии</h3>
      <h3 id='cat-10'>Аккредитация лаборатории</h3> */}
        {/* <h3 id='cat-11'>Пестициды</h3>
        <p className='docs-p'>
          Пестицид – это вещество или несколько смешанных вместе веществ химического либо биологического происхождения. Они предназначены для уничтожения вредителей или возбудителей болезней растений. Кроме этого они уничтожают различных паразитов, сорняки, вредителей зерна и зернопродуктов, древесины, изделий из хлопка, шерсти, кожи, эктопаразитов домашних животных, переносчиков опасных заболеваний человека и животных.
          <br />
          Пестициды относятся к отравителям ферментов. Под действием пестицидов часть биологических реакций перестаёт протекать в организме мишени, что позволяет побеждать болезни с помощью антибиотиков, увеличить срок хранения пищи консервантами, уничтожать насекомых инсектицидами и уничтожать сорняки гербицидами.
          <br />
          Пестициды, проникающие во все ткани живого организма или растения, называются системными пестицидами, а через еду — кишечными.
          <br />
          Использование пестицидов преследуется коммерческим интересом промышленного производства, которое требует такие показатели, как стойкость и величина урожая, его сохранность и устойчивость к перевозке. Но во вред качеству получаемой продукции, как микро элементный состав, полезность и безопасность для здоровья потребителей. Глобальной проблемой стало и разрушение биоценозов в районах применения пестицидов.</p>
        <h3 id='cat-12'>Гербициды</h3>
        <p className='docs-p'>
          Гербициды  - это химические вещества, которые применяют для уничтожения растительности и широко используются в сельском хозяйстве. Это отражено в самом слове где herba — трава и caedo — убиваю. По характеру действия на растения делятся на гербициды сплошного действия, убивающие все виды растений, и гербициды избирательного (селективного) действия, поражающие одни виды растений и не повреждающие другие.
          <br />
          Гербициды производят в виде смачивающихся порошков, концентратов эмульсий и концентратов суспензий.
        </p>
        <h3 id='cat-13'>Десиканты</h3>
        <p className='docs-p'>
          Десиканты - это химические, ядовитые препараты, вызывающие высыхание сельскохозяйственных культур перед уборкой урожая. Десикация (высушивание) применяется непосредственно перед уборкой для улучшения качества урожая и оптимизации сроков уборки.
        </p>
        <h3 id='cat-14'>Инсектициды и акарициды сельскохозяйственные</h3>
        <p className='docs-p'>
          Инсектициды - это вещества из группы пестицидов направленных на сокращение или уничтожение сельскохозяйственных насекомых-вредителей. Акарициды — это вещества, способные уничтожать клещей.
        </p>
        <h3 id='cat-15'>Моллюскоциды</h3>
        <p className='docs-p'>
          Моллюскоциды - это химические вещества для борьбы с моллюсками (от моллюски и лат. caedo — убиваю) и относятся к пестицидам. При по­мо­щи моллюскоцидов унич­то­жа­ют ули­ток и слиз­ней, яв­ляю­щих­ся вре­ди­те­ля­ми рас­те­ний.
        </p>
        <h3 id='cat-16'>Нематициды</h3>
        <p className='docs-p'>
          Нематоциды, нематициды — химические вещества, уничтожающие растительноядных нематод. Слово происходит от нематоды и латинского caedo — убиваю, уничтожаю. Иногда к нематоцидам также относят антигельминтные средства, уничтожающие нематодных паразитов животных.
          <br />
          У пораженных нематодами растений наблюдается гибель корней, порча корнеплодов и образование галлов. Стеблевые нематоды вызывают растрескивание стеблей, гофрировку листьев и так далее. Особенно большой вред нематоды наносят в защищенном грунте и посадках картофеля.
        </p>
        <h3 id='cat-17'>Репелленты</h3>
        <p className='docs-p'>
          Репеллент — природное или синтетическое химическое вещество, применяемое в бытовых целях для отпугивания членистоногих. Слово происходит от лат. repellens — отталкивающий, отвращающий. В отличие от инсектицидов репелленты предназначены не для уничтожения насекомых, а только для их отпугивания.
        </p>
        <h3 id='cat-18'>Родентициды</h3>
        <p className='docs-p'>
          Родентициды - это в основном химические вещества для сокращения или уничтожения популяций грызунов. Название этого вида пестицида состоит из двух слов: rodent – грызун и cide – сокращать (смысловой перевод – средства, сокращающие численность грызунов). В сельском хозяйстве родентициды используются для защиты растений, помещений для хранения сельскохозяйственной продукции.
        </p>
        <h3 id='cat-19'>Феромоны</h3>
        <p className='docs-p'>
          Феромоны – продукты внешней секреции, выделяемые некоторыми видами животных и обеспечивающих коммуникацию между особями одного вида. Также, феромоны синтезируются растениями.
          <br />
          В сельском хозяйстве, феромоны - это группа действующих веществ пестицидов, применяемых для регулирования численности вредных насекомых. В сочетании с ловушками разных типов феромоны, приманивающие насекомых, позволяют уничтожать значительные количества вредителей. Также распыление феромонов над охраняемыми сельскохозяйственными угодьями позволяет обмануть самцов вредителей и таким образом снизить популяцию вредных насекомых.
        </p>
        <h3 id='cat-20'>Фумиганты</h3>
        <p className='docs-p'>
          Фумигация  — уничтожение вредителей и возбудителей болезней растений путём отравления их ядовитыми парами или газами (фумигантами). Слово происходит от латинского fumigare — окуривать, дымить. Для фумигации используют приборы, называемые фумигаторами.
          <br />
          По современной терминологии фумигантом называется химикат, который при требуемой температуре и давлении может сохраняться в газообразной фазе в летальной концентрации для данного вредного организма. Это определение подразумевает, что фумигант воздействует в качестве газа. Это свойство позволяет им проникать в фумигируемые материалы и диффундировать на большие расстояния.
        </p>
        <h3 id='cat-21'>Фунгициды</h3>
        <p className='docs-p'>
          Фунгициды - (от лат. fungus «гриб» + лат. caedo «убиваю») — химические вещества для борьбы с грибковыми болезнями растений, а также для протравливания семян с целью освобождения их от спор паразитных грибов. Концентраты фунгицидов токсичны для человека и животных.
        </p>
        <h3 id='cat-22'>Регуляторы роста растений</h3>
        <p className='docs-p'>
          Регуляторы роста растений – это природные или синтетические химические вещества, применяющиеся для обработки растений, с целью изменить процессы их жизнедеятельности или структуру для улучшения их качества, увеличения урожайности или облегчения уборки.
          <br />
          К регуляторам роста растений также относят фитогормоны. Фитогормоны — это химические вещества, которые вырабатываются в растениях и координируют их рост и развитие.
        </p> */}
      </main>
    </div>
  )
}

export default Docs
