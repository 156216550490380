import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Link } from 'react-router-dom'
import * as FA from '@fortawesome/free-solid-svg-icons'

function AnalysisBorod () {
  return (
    <>
    <Link style={{ width: 'fit-content', float: 'right', fontSize: '20px', padding: '5px' }} className='newbutton nav-element side dropdown' to='/maps'>
    <FontAwesomeIcon icon={FA.faHome} />
    </Link>
    <img src='/img/borod.png' width='100%' style={{ background: 'white' }}/>
    </>
  )
}
export default AnalysisBorod
