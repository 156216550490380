import { useState, useEffect, useCallback } from 'react'
import { Alert, Input, Tooltip, Whisper } from 'rsuite'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import * as FA from '@fortawesome/free-solid-svg-icons'
import axios from 'axios'
import L from 'leaflet'
import { useTranslation } from 'react-i18next'
import Cookies from 'js-cookie'

function FieldsList ({ adClass, fields, fitBounds, deleteFields, showData, setFieldMaster, hideFunc, layer, setFieldToUpdate, openEditor, openCharts, setFieldNameMaster, fieldsModal, notesModal, chosenPlace, downloadImage, satLoading, setLoading, setField, setSatImagesShow }) {
  const { t } = useTranslation()
  const [dataApi, setDataApi] = useState({ isLoadingApi: false, errorApi: false, data: {} })
  const [updateKey, update] = useState(0)
  const fetchData = async (coord) => {
    try {
      const { data } = await axios.get(`https://api.openweathermap.org/data/2.5/weather?lat=${coord.lat}&lon=${coord.lng}&units=metric&appid=69729bf128d729135e944369a9a52555
      `)
      return ({ ...dataApi, isLoadingApi: false, errorApi: false, data })
    } catch (error) {
      return ({ ...dataApi, isLoadingApi: false, errorApi: error?.message })
    }
  }
  return (
    <div className='fields-list-parent-main'>
      <div className='fields-list' hidden={!notesModal}>
        <h4>Ваши заметки</h4>
        <div id='fields-list-child' style={{ maxHeight: '96%', overflowY: 'auto' }}>
          {
                fields &&
                fields.map((f, key) => {
                  const field = JSON.parse(f.json)
                  const d = false
                  if (field.geometry?.type === 'Point') {
                    return (
                      <div className='field-list' style={{ cursor: 'default' }} key={key}>
                        <h6> {field.geometry?.type === 'Point' ? 'Заметка ' + '"' + field.properties.name + '"' : t('sidemenu.field') + ' "' + field.properties.name + '"'} </h6><br />
                        <button id={`field_button_${key}`} style={{ width: '100%', height: '30px', display: 'flex', justifyContent: 'center', fontSize: '25px', alignItems: 'center' }} onClick={() => { document.getElementById(`field_${key}`).style.display = document.getElementById(`field_${key}`).style.display === 'block' ? 'none' : 'block'; document.getElementById(`field_button_${key}`).innerHTML = document.getElementById(`field_button_${key}`).innerHTML === '+' ? '-' : '+' }}>
                          +
                        </button>
                        <div id={`field_${key}`} style={{ display: f.d ? 'block' : 'none' }}>
                          <div className='fields-buttons'>
                            <Whisper
                              trigger='hover' placement={window.innerWidth > 840 ? 'bottom' : 'right'} speaker={
                                <Tooltip>Найти</Tooltip>
                              }
                            >
                              <button onClick={() => {
                                layer.clearLayers()
                                const polyToShow = L.geoJSON(field)
                                fitBounds(field)
                              }}
                              ><FontAwesomeIcon icon={FA.faArrowAltCircleRight} />
                              </button>
                            </Whisper>
                            <Whisper
                              trigger='hover' placement={window.innerWidth > 840 ? 'bottom' : 'right'} speaker={
                                <Tooltip>Удалить</Tooltip>
                              }
                            >
                              <button
                                style={{ backgroundColor: 'red' }} onClick={() => {
                                  deleteFields(f.id)
                                }}
                              ><FontAwesomeIcon icon={FA.faTrash} />
                              </button>
                            </Whisper>
                            <Whisper
                              trigger='hover' placement={window.innerWidth > 840 ? 'bottom' : 'right'} speaker={
                                <Tooltip>Обновить</Tooltip>
                              }
                            >
                              <button
                                onClick={() => {
                                  openCharts(false)
                                  showData(false)
                                  setFieldToUpdate(f)
                                  openEditor(true)
                                  hideFunc(false)
                                }}
                              ><FontAwesomeIcon icon={FA.faPen} />
                              </button>
                            </Whisper>
                          </div>
                        </div>
                      </div>
                    )
                  }
                }
                )
}
        </div>
      </div>
      <div className='fields-list-main' hidden={!fieldsModal}>
        {/* <h4>{Cookies.get('place')}</h4> */}
        {/* <a style={{ width: 'fit-content', float: 'right', fontSize: '20px', padding: '5px' }} className='newbutton nav-element side dropdown' onClick={() => hideFunc(false)}>
        <FontAwesomeIcon icon={FA.faTimes} />
      </a> */}
        <div id='fields-list-child' style={{ maxHeight: '98%', overflowY: 'auto' }}>
          {
                fields &&
                fields.map((f, key) => {
                  const field = JSON.parse(f.json)
                  const d = false
                  if (field.geometry?.type !== 'Point' && chosenPlace === f.place) {
                    return (
                      <div
                        className='field-list' style={{ cursor: 'pointer' }} key={key} onClick={() => {
                          fitBounds(JSON.parse(f.json))
                          setField(f)
                          setSatImagesShow(true)
                          hideFunc(false)
                        }}
                      >
                        <h6> {field.geometry?.type === 'Point' ? 'Заметка ' + '"' + field.properties.name + '"' : t('sidemenu.field') + ' "' + field.properties.name + '"'} </h6>
                        {field.properties.area && <p>{t('sidemenu.area')} {(field.properties.area / 10000).toFixed(3)} {t('sidemenu.ha')}</p>}

                        {/* <br /> */}
                        {/* <button id={`field_button_${key}`} style={{ width: '100%', height: '30px', display: 'flex', justifyContent: 'center', fontSize: '25px', alignItems: 'center' }} onClick={() => { document.getElementById(`field_${key}`).style.display = document.getElementById(`field_${key}`).style.display === 'block' ? 'none' : 'block'; document.getElementById(`field_button_${key}`).innerHTML = document.getElementById(`field_button_${key}`).innerHTML === '+' ? '-' : '+' }}>
                        +
                      </button> */}
                        <div id={`field_${key}`} style={{ display: f.d ? 'block' : 'none' }}>
                          <br />
                          {field.properties.cadaster && <p>{t('sidemenu.cadaster')} {field.properties.cadaster}</p>}
                          {field.properties.soiltype && <p>{t('sidemenu.soil')} {field.properties.soiltype}</p>}
                          <div className='fields-buttons'>
                            <Whisper
                              trigger='hover' placement={window.innerWidth > 840 ? 'bottom' : 'right'} speaker={
                                <Tooltip>Найти</Tooltip>
                              }
                            >
                              <button onClick={() => {
                                layer.clearLayers()
                                const polyToShow = L.geoJSON(field)
                                if (field.geometry?.type !== 'Point') {
                                  polyToShow.getLayers()[0].options.color = '#FF0000'
                                  polyToShow.addEventListener('click', () => (layer.clearLayers()))
                                  polyToShow.bringToBack()
                                  layer.addLayer(polyToShow)
                                }
                                fitBounds(field)
                              }}
                              ><FontAwesomeIcon icon={FA.faArrowAltCircleRight} />
                              </button>
                            </Whisper>
                            <Whisper
                              trigger='hover' placement={window.innerWidth > 840 ? 'bottom' : 'right'} speaker={
                                <Tooltip>Удалить</Tooltip>
                              }
                            >
                              <button
                                style={{ backgroundColor: 'red' }} onClick={() => {
                                  deleteFields(f.id)
                                }}
                              ><FontAwesomeIcon icon={FA.faTrash} />
                              </button>
                            </Whisper>
                            <Whisper
                              trigger='hover' placement={window.innerWidth > 840 ? 'bottom' : 'right'} speaker={
                                <Tooltip>Обновить</Tooltip>
                              }
                            >
                              <button
                                onClick={() => {
                                  openCharts(false)
                                  showData(false)
                                  setFieldToUpdate(f)
                                  openEditor(true)
                                  hideFunc(false)
                                }}
                              ><FontAwesomeIcon icon={FA.faPen} />
                              </button>
                            </Whisper>
                            <Whisper
                              trigger='hover' placement={window.innerWidth > 840 ? 'bottom' : 'right'} speaker={
                                <Tooltip>Загрузить снимок</Tooltip>
                              }
                            >
                              {!satLoading
                                ? <button
                                    onClick={() => {
                                      fitBounds(JSON.parse(f.json))
                                      setField(f)
                                      setSatImagesShow(true)
                                      hideFunc(false)
                                    }}
                                  ><FontAwesomeIcon icon={FA.faSatellite} />
                                </button>
                                : <button><FontAwesomeIcon icon={FA.faSpinner} spin />
                                </button>}
                            </Whisper>
                            {/* { field.geometry?.type !== 'Point' &&
                           <>
                          <br />

                          <Whisper
                            trigger='hover' placement={window.innerWidth > 840 ? 'bottom' : 'right'} speaker={
                              <Tooltip>Заметки</Tooltip>
                              }
                          >
                          <button onClick={() => {
                            if (window.innerWidth > 840) {
                              openCharts(false)
                              openEditor(false)
                              fitBounds(field)
                              showData(true)
                              setFieldMaster(f.id)
                            } else {
                              window.open(`/datasave/${f.id}`, '_blank')
                            }
                          }}
                          ><FontAwesomeIcon icon={FA.faList} />
                          </button></Whisper><br />

                          <Whisper
                            trigger='hover' placement={window.innerWidth > 840 ? 'bottom' : 'right'} speaker={
                              <Tooltip>Погода</Tooltip>
                              }
                          >
                          <button onClick={async () => {

                            field.features
                              ? f.weather = await fetchData({ lat: field.features[0].geometry.coordinates[0][0][1], lng: field.features[0].geometry.coordinates[0][0][0] })
                              : f.weather = await fetchData({ lat: field.geometry.coordinates[0][0][1], lng: field.geometry.coordinates[0][0][0] })

                            update(updateKey + 1)
                          }}
                          ><FontAwesomeIcon icon={FA.faSun} />
                          </button></Whisper><br />
                          <Whisper
                            trigger='hover' placement={window.innerWidth > 840 ? 'bottom' : 'right'} speaker={
                              <Tooltip>Графики</Tooltip>
                              }
                          >
                          <button onClick={() => {
                            if (window.innerWidth > 840) {
                              showData(false)
                              openEditor(false)
                              fitBounds(field)
                              openCharts(true)
                              setFieldNameMaster(field.properties.name)
                            } else {
                              window.open('/charts', '_blank')
                            }
                          }}
                          ><FontAwesomeIcon icon={FA.faChartArea} />
                          </button></Whisper><br /></>} */}
                          </div>
                          {(f.weather && !f.weather.errorApi) &&
                            <div className='weather-tile'>
                              <h6>{t('sidemenu.weather_region')} {f.weather.data.name}</h6>
                              <p>{t('sidemenu.temp')} {f.weather.data.main.temp}°C</p>
                              <p>{t('sidemenu.humidity')} {f.weather.data.main.humidity}%</p>
                              <p>{t('sidemenu.cloudiness')} {f.weather.data.clouds.all}%</p>
                              <p>{t('sidemenu.pressure')} {f.weather.data.main.pressure}{t('sidemenu.hpa')}</p>
                              <p>{t('sidemenu.wind')} {f.weather.data.wind.speed} {t('sidemenu.kph')}</p>
                            </div>}
                          {(f.weather && f.weather.errorApi) &&
                            <div className='weather-tile'>{f.weather.errorApi}</div>}
                        </div>
                      </div>
                    )
                  }
                })
              }
        </div>
      </div>
    </div>
  )
}

export default FieldsList
