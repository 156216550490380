import { useState, useEffect, useCallback } from 'react'
import { Alert, Input, Tooltip, Whisper } from 'rsuite'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import * as FA from '@fortawesome/free-solid-svg-icons'
import axios from 'axios'
import L from 'leaflet'
import { useTranslation } from 'react-i18next'
import DataSave from '../../pages/DataSave'

function FieldDataModal ({ adClass, hidden, chosenField, setFieldToUpdate, openEditor, closeOtherModals, deleteFields }) {
  const { t } = useTranslation()
  const [dataApi, setDataApi] = useState({ isLoadingApi: false, errorApi: false, data: {} })
  const [updateKey, update] = useState(0)
  const [weather, setWeather] = useState()
  const [noteCount, setCount] = useState(0)
  const fetchData = async (coord) => {
    try {
      const { data } = await axios.get(`https://api.openweathermap.org/data/2.5/weather?lat=${coord.lat}&lon=${coord.lng}&units=metric&appid=69729bf128d729135e944369a9a52555
      `)
      return ({ ...dataApi, isLoadingApi: false, errorApi: false, data })
    } catch (error) {
      return ({ ...dataApi, isLoadingApi: false, errorApi: error?.message })
    }
  }

  useEffect(() => {
    setWeather()
    // setCount(0)
  }, [chosenField])
  const getWeather = async () => {
    const f = JSON.parse(chosenField.json)
    let weather
    f.features
      ? weather = await fetchData({ lat: f.features[0].geometry.coordinates[0][0][1], lng: f.features[0].geometry.coordinates[0][0][0] })
      : weather = await fetchData({ lat: f.geometry.coordinates[0][0][1], lng: f.geometry.coordinates[0][0][0] })

    setWeather(weather)
    update(updateKey + 1)
  }

  return (
    <div className={`field-data-modal ${adClass}`} hidden={!hidden || !chosenField}>
      {chosenField && <>
        <div className='datamodal-panel-right'>
          <div className='datamodal-tile-right'>
            <h4>Поле: {JSON.parse(chosenField.json).properties.name}</h4>
            {JSON.parse(chosenField.json).properties.area && <p>{t('sidemenu.area')} {(JSON.parse(chosenField.json).properties.area / 10000).toFixed(3)} {t('sidemenu.ha')}</p>}
            {JSON.parse(chosenField.json).properties.cadaster && <p>{t('sidemenu.cadaster')} {JSON.parse(chosenField.json).properties.cadaster}</p>}
            {JSON.parse(chosenField.json).properties.soiltype && <p>{t('sidemenu.soil')} {JSON.parse(chosenField.json).properties.soiltype}</p>}
            {JSON.parse(chosenField.json).features && <p>Координаты: {JSON.parse(chosenField.json).features[0].geometry.coordinates[0][0].join(', ')}</p>}
          </div>
          <div className='datamodal-tile-right'>
            <h5>Действия</h5>
            <p
              onClick={() => {
                closeOtherModals(false)
                setFieldToUpdate(chosenField)
                openEditor(true)
              }} className='datamodal-update-button'
            >Редактировать данные поля
            </p>
            <p className='datamodal-update-button' onClick={getWeather}>Погода</p>
            <p className='datamodal-update-button' onClick={() => window.open(`/datasave/${chosenField.id}`)}>Заметки по севообороту</p>
            <p className='datamodal-update-button' style={{ color: 'red' }} onClick={() => deleteFields(chosenField.id)}>Удалить поле</p>
          </div>
        </div>
        {(weather) &&
          <div className='weather-tile'>
            <h4>{t('sidemenu.weather_region')} {weather.data.name}</h4>
            <br />
            <div className='datamodal-weather-data'>
              <p>{t('sidemenu.temp')}<br /> <b>{weather.data.main.temp}°C</b></p>
              <p>{t('sidemenu.humidity')}<br /> <b>{weather.data.main.humidity}%</b></p>
              <p>{t('sidemenu.cloudiness')}<br /> <b>{weather.data.clouds.all}%</b></p>
              <p>{t('sidemenu.pressure')}<br /> <b>{weather.data.main.pressure}{t('sidemenu.hpa')}</b></p>
              <p>{t('sidemenu.wind')}<br /> <b>{weather.data.wind.speed} {t('sidemenu.kph')}</b></p>
            </div>
          </div>}
        {/* <button onClick={getWeather}>Погода</button> */}
      </>}
    </div>
  )
}

export default FieldDataModal
