import { useState, useEffect, useCallback } from 'react'
import { Alert, AutoComplete, Button, Input, Panel, SelectPicker, Tooltip, Whisper } from 'rsuite'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import * as FA from '@fortawesome/free-solid-svg-icons'
import axios from 'axios'
import L from 'leaflet'
import { useTranslation } from 'react-i18next'
import { createDataPoint, getDataPoints, getDataPointsClasses } from '../../http/API'

function DataPointSelector ({ fieldID, layerRef, adClass, coordinates, mapRef, fieldsList, setFieldID, setDispPoints, heatmap, setHeatmap }) {
  const { t } = useTranslation()
  const [data, setData] = useState({})
  const [dispData, setDispData] = useState({})
  const [points, setPoints] = useState()
  const [classes, setClasses] = useState({})
  const [selName, setName] = useState()
  const chems = [
    'Азот',
    'Фосфор',
    'Калий',
    'Гумус'
  ]
  const handleSave = async () => {
    if (!data.coordinates) {
      Alert.error('Укажите точку на карте с помощью маркера!')
    } else if (!data.chem) {
      Alert.error('Выберите элемент!')
    } else if (!data.season) {
      Alert.error('Введите сезон!')
    } else if (!data.value) {
      Alert.error('Введите значение!')
    } else {
      const d = await createDataPoint(data).then(res => {
        if (res.response.success) {
          Alert.success(res.response.message)
        } else {
          Alert.error(res.response.message)
        }
      })
    }
  }

  useEffect(async () => {
    if (mapRef?.current?.hasLayer(layerRef.current)) layerRef.current.clearLayers()
    setData({ field_id: fieldID, chem: data.chem })
    const field = fieldsList.filter(e => e.id == fieldID)[0]
    setName(field && JSON.parse(field?.json).properties.name)
    setDispData({ fid: fieldID, chem: dispData.chem })
    const cl = await getDataPointsClasses(fieldID).then(e => {
      setClasses(e.response.classes)
    })
  }, [fieldID])

  const handleFind = async () => {
    layerRef.current.clearLayers()
    if (dispData.chem && dispData.season) {
      const points = await getDataPoints(dispData).then(res => {
        if (res.response.points.length > 0) {
          setPoints(res.response.points)
        } else {
          Alert.warning('Точек с выбранными данными нет!')
        }
      })
    } else {
      Alert.error('Пожалуйста, укажите необходимые данные!')
    }
  }

  useEffect(() => {
    if (points) {
      const parsedPoints = []
      // setDispPoints && setDispPoints(points)
      points.map(f => {
        const geoJSON = {
          type: 'Feature',
          properties: {},
          geometry: {
            type: 'Point',
            coordinates: JSON.parse(f.coordinates)
          }
        }
        const mask = L.geoJSON(geoJSON)
        mask.bindTooltip(() => String(f.value), { permanent: true })
        // mask.addEventListener('mouseover', () => {
        //   mask.unbindTooltip()
        //   mask.bindTooltip(() => String(f.season + ', ' + f.chem), { permanent: true })
        // })
        // mask.addEventListener('mouseout', () => {
        //   mask.unbindTooltip()
        //   mask.bindTooltip(() => String(f.value), { permanent: true })
        // })
        parsedPoints.push([JSON.parse(f.coordinates)[1], JSON.parse(f.coordinates)[0], `${f.value}`])
        layerRef.current.addLayer(mask)
      })
      setDispPoints(parsedPoints)
    }
  }, [points])

  useEffect(() => {
    coordinates && setData({ ...data, coordinates: coordinates.geometry.coordinates })
  }, [coordinates])

  return (
    <div className={`datapoints-list ${adClass}`}>
      <h4>Выбранное поле: <br />{selName || 'Поле не выбрано'}</h4>
      <Panel bordered>
        <h4>Сохранение точки</h4>
        <br />
        {/* <AutoComplete data={chems} onChange={(e) => setData({ ...data, chem: e })} placeholder='Элемент'/> */}
        <SelectPicker data={chems.map(e => ({ label: e, value: e }))} onChange={(e) => setData({ ...data, chem: e })} placeholder='Элемент' searchable={false} />
        <AutoComplete placeholder='Сезон' data={classes?.seasons} onChange={(e) => setData({ ...data, season: e })} />
        <Input type='number' placeholder='Значение' onChange={(e) => setData({ ...data, value: e })} />
        <h5>Координаты:
          {coordinates ? <><br /> {coordinates?.geometry?.coordinates[0]}<br /> {coordinates?.geometry?.coordinates[1]}</> : ' Точка не указана!'}
        </h5>
        <Button onClick={() => handleSave()}>Сохранить</Button>
      </Panel>
      <br />
      <Panel bordered>
        <h5>Отображение точек</h5>
        <SelectPicker disabled={!fieldID} data={chems.map(e => ({ label: e, value: e }))} onChange={(e) => setDispData({ ...dispData, chem: e })} placeholder='Элемент' searchable={false} />
        <br />
        <SelectPicker disabled={!fieldID} data={classes?.seasons?.map(e => ({ label: e, value: e }))} onChange={(e) => setDispData({ ...dispData, season: e })} placeholder='Сезон' />
        <br />
        {/* <AutoComplete placeholder='Сезон' data={classes?.seasons} onChange={(e) => setDispData({ ...dispData, season: e })}/> */}
        <Button onClick={() => handleFind()}>Найти</Button>
        <br />
        <br />
        <Button onClick={() => { setHeatmap(!heatmap); document.getElementById(heatmap ? 'heatmap' : 'map').scrollIntoView() }}>Изменить тип карты</Button>
      </Panel>
      <br />
      <Panel bordered>
        <h5>Выбор поля</h5>
        <div className='datapoint-fieldlist'>
          {
          fieldsList.map((e, key) => {
            const props = JSON.parse(e.json).properties
            //
            return (
              <button key={key} className='datapoint-fielditem' style={e.id === fieldID ? { color: 'lime' } : { color: '#FFF' }} onClick={() => { setFieldID(e.id); setName(props.name) }}>
                {props.name}
              </button>
            )
          })
        }
        </div>
      </Panel>
    </div>
  )
}

export default DataPointSelector
