import React, { useRef, useEffect, useState } from 'react'
import L from 'leaflet'
// import 'leaflet/dist/leaflet.css'
import 'leaflet-imageoverlay-rotated'
import 'leaflet-toolbar'
// import 'leaflet-distortableimage'
import MapControls from './MapControls'
import { Loader, Alert, Whisper, Tooltip, Button } from 'rsuite'
import * as turf from '@turf/turf'
import { useTranslation } from 'react-i18next'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import * as FA from '@fortawesome/free-solid-svg-icons'
import '../styles/sidenav.css'
import Cookies from 'js-cookie'
import { addGeoJSON, deleteGeoJSON, getGeoJSON, getVehicleList, getVehicleRoute, getVehicleTelemetry } from '../http/API'
import moment from 'moment'
import { decode } from '@googlemaps/polyline-codec'

const MapVeh = () => {
  const [jsonToDisplay, setJSON] = useState([])
  const [route, setRoutes] = useState([])
  const [selRoute, setSelRoute] = useState(0)
  const [vehicleToDisplay, setVehicle] = useState()
  const [updateKey, update] = useState(0)
  // const queryParams = new URLSearchParams(window.location.search)
  // const vehicle_id = queryParams.get('id')
  const [mapType, setMap] = useState(Cookies.get('pref-map') || 'http://mt1.google.com/vt/lyrs=y&x={x}&y={y}&z={z}')
  function sortByProperty (property) {
    return function (a, b) {
      if (a[property] > b[property]) {
        return 1
      } else if (a[property] < b[property]) {
        return -1
      }
      return 0
    }
  }
  useEffect(async () => {
    const fieldsList = await getGeoJSON()
      .catch((e) => {
        if (String(e).includes('401')) {
          // Cookies.remove('token')
          window.location.href = '/map_main'
        }
      })
    if (fieldsList.response.success) {
      setJSON(fieldsList.response.fields.sort(sortByProperty('id')))
      getTelemetry()
    }
    showLayerRef.current.clearLayers()
  }, [updateKey])

  const getTelemetry = async () => {
    setVehicles([])
    setVehicle()
    routeLayerRef.current.clearLayers()
    setRoutes([])
    const vehicle = await getVehicleTelemetry()

    if (vehicle.response.success) {
      setVehicles(vehicle.response.result)
      // setTimeout(async (e) => {
      //   await getTelemetry()
      // }, 5000)
    }
  }
  const getRoutes = async (id) => {
    setRoutes([])
    const route = await getVehicleRoute(id).then(e => {
      if (e.response.success) {
        setSelRoute(e.response.routes.length - 1)
        setRoutes(e.response.routes)
        drawLine(e.response.routes[e.response.routes.length - 1].route)
      }
    })
  }
  const changeIndex = (val) => {
    if (route[selRoute + val]) {
      drawLine(route[selRoute + val].route)
      setSelRoute(selRoute + val)
    }
  }
  const drawLine = (route) => {
    showLayerRef.current.clearLayers()
    const coordinates = decode(route)
    const polyline = L.polyline(
      coordinates,
      {
        color: 'blue',
        weight: 10,
        opacity: 0.7,
        dashArray: '20,15',
        lineJoin: 'round'
      }
    ).addTo(showLayerRef.current)
    const start = L.circleMarker(coordinates[0], {
      color: 'green',
      radius: 20,
      fill: 'green'
    }).addTo(showLayerRef.current)
    start.bindTooltip('Начало', { permanent: true })
    const end = L.circleMarker(coordinates[coordinates.length - 1], {
      color: 'red',
      radius: 20,
      fill: 'red'
    }).addTo(showLayerRef.current)
    end.bindTooltip('Конец', { permanent: true })
  }
  const { t, i18n } = useTranslation()
  // const { dataTiles, fetchDataTiles, activeTile, setActiveTile } = useContext(SatImgContext)
  // const { searchOptions, setSearchOptions, jsonLayers, newOrder, setNewOrder } = useContext(SearchOptionsContext)
  const [isLoading, setIsLoading] = useState(false)

  const MAX_AREA = 6313063483978.778// in ha
  const footprintOptions = {
    stroke: true,
    // color: '#4185f4',
    color: '#E3AF34',
    weight: 3,
    opacity: 0.6,
    fill: true,
    fillColor: null,
    fillOpacity: 0,
    clickable: true
  }

  const mapRef = useRef(null) // map
  const paneRef = useRef(null) // tiles order on top
  const routeLayerRef = useRef(null) // drawn footrpints - paths (lines) = featureGroup
  const markerRef = useRef(null) // drawn footrpints - paths (lines) = featureGroup
  const showLayerRef = useRef(null) // drawn footrpints - paths (lines) = featureGroup
  const tileLayerRef = useRef(null) // tiles in tilelayer = featureGroup
  const fieldsRef = useRef(null) // search geometries on the map = featureGroup
  // const [maptype, changeMap] = useState('alidade_smooth_dark')
  let map

  function clearLayers () {
    if (mapRef?.current) {
      // if (mapRef.current.hasLayer(searchGeometryRef.current)) searchGeometryRef.current.clearLayers()
      if (mapRef.current.hasLayer(routeLayerRef.current)) routeLayerRef.current.clearLayers()
      // if (mapRef.current.hasLayer(markerRef.current)) markerRef.current.clearLayers()
      if (mapRef.current.hasLayer(tileLayerRef.current)) tileLayerRef.current.clearLayers()
    }
  }
  const [vehicles, setVehicles] = useState([])
  /** map initialization */
  useEffect(() => {
    try {
      map = L.map('map', {
        center: [49.945963, 82.613922],
        zoom: 16,
        minZoom: 1,
        zoomControl: false,
        attributionControl: false
      })
      L.tileLayer(mapType, {
        subdomains: ['mt0', 'mt1', 'mt2', 'mt3'],
        maxZoom: 18,
        id: 'baselayer'
      }).addTo(map)
      L.control.attribution({
        position: 'bottomright'
      }).addTo(map)
      // map.fitBounds([[47.12995075666307, 52.064208984375], [51.1807, 71.461]])

      // Searched geometries
      fieldsRef.current = L.featureGroup().addTo(map)
      showLayerRef.current = L.featureGroup().addTo(map)
      routeLayerRef.current = L.featureGroup().addTo(map)
      tileLayerRef.current = L.featureGroup().addTo(map)
      markerRef.current = L.featureGroup().addTo(map)
      mapRef.current = map
      // Pane for tiles topping on the map
      const topPane = mapRef?.current.createPane('leaflet-top-pane', mapRef.current.getPanes().mapPane)
      paneRef.current = topPane

      // Adding Controls
      MapControls(mapRef.current, t)
      mapRef.current.invalidateSize(9)
    } catch (e) {

    }
  }, [])
  useEffect(() => {
    // mapRef.current.removeLayer('baselayer')
    mapRef.current.attributionControl.setPrefix(Cookies.get('pref-attr'))
    L.tileLayer(mapType, {
      maxZoom: 18,
      id: 'baselayer'
    }).addTo(mapRef.current)
  }, [mapType])
  useEffect(() => {
    fieldsRef.current.clearLayers()
    jsonToDisplay.forEach((f) => {
      const mask = L.geoJSON(JSON.parse(f.json))
      const name = JSON.parse(f.json).properties.name

      if (JSON.parse(f.json).geometry?.type === 'Point') {
        mask.bindTooltip(() => name, { permanent: true })
        mask.addEventListener('mouseover', () => {
          mask.unbindTooltip()
          const time = new Date(f.createdAt)
          mask.bindTooltip(() => `Создано: ${time.toLocaleString('ru-RU')}`, { permanent: true })
        })
        mask.addEventListener('mouseout', () => {
          mask.unbindTooltip()
          mask.bindTooltip(() => name, { permanent: true })
        })
      } else {
        mask.bindTooltip(() => name)
      }
      mask.addEventListener('mouseover', () => (mask.openTooltip()))
      fieldsRef.current.addLayer(mask)
    })
  }, [jsonToDisplay])
  useEffect(() => {
    if (vehicles) {
      markerRef.current.clearLayers()
      vehicles.map(v => {
        if (v.telemetry.ident.value === vehicleToDisplay?.telemetry?.ident?.value) {
          setVehicle(v)
        }
        const coordinates = [v.telemetry.position.value.longitude, v.telemetry.position.value.latitude]
        const json = { type: 'Feature', properties: { name: v.name }, geometry: { type: 'Point', coordinates } }
        const mask = L.geoJSON(json)
        mask.bindTooltip(() => v.name)
        // mask.addEventListener('click', () => (mask.isTooltipOpen ? mask.closeTooltip() : mask.openTooltip()))
        mask.addEventListener('mouseover', () => (mask.openTooltip()))
        mask.addEventListener('click', () => { setVehicle(v); routeLayerRef.current.clearLayers(); setRoutes([]) })
        markerRef.current.addLayer(mask)
      })
    }
  }, [vehicles])

  const fitBounds = (field) => {
    const mask = L.geoJSON(field)

    if (field.geometry?.type === 'Point') {
      const lat = field.geometry.coordinates[1]
      const lng = field.geometry.coordinates[0]
      mapRef.current.panTo([lat, lng], 10)
    } else {
      mapRef.current.fitBounds(mask.getLayers()[0].getLatLngs())
    }
  }
  return (
    <>
      <div className='vehicle-bar'>
        {vehicles.length > 0 && <Button onClick={() => getTelemetry()} id='refresh-vehicles'>Обновить (Всего транспорта: {vehicles.length})</Button>}
        <br />
        <br />
        {vehicles.length > 0
          ? vehicles?.map((v, key) => {
            return (
                <div className='vehicle-tile' key={key}>
                  IMEI: {v.telemetry.ident.value}<br />
                  {
                v.name && <>Название: <br />{v.name}<br /></>
              }
                  {
                v.telemetry &&
                  <>
                    Высота: {v.telemetry.position.value.altitude}<br />
                    Широта: {v.telemetry.position.value.latitude}<br />
                    Долгота: {v.telemetry.position.value.longitude}<br />
                    Скорость: {v.telemetry.position.value.speed}<br />
                    <br />
                    Доступные данные:<br />
                    {v.telemetry['vehicle.mileage'] && <>Километраж: {v.telemetry['vehicle.mileage'].value}<br /></>}
                    {v.telemetry['external.powersource.voltage'] && <>Внешний вольтаж: {v.telemetry['external.powersource.voltage'].value}<br /></>}
                    {v.telemetry['engine.motorhours'] && <>Мотор-часы: {v.telemetry['engine.motorhours'].value}<br /></>}
                    {v.telemetry['custom.pwr_akb'] && <>Мощность АКБ: {v.telemetry['custom.pwr_akb'].value}<br /></>}
                    {v.telemetry['custom.flex_fuel1'] && <>Топливо: {v.telemetry['custom.flex_fuel1'].value}<br /></>}
                    {v.telemetry['custom.fuel_temp101'] && <>Температура топлива: {v.telemetry['custom.fuel_temp101'].value}<br /></>}
                  </>
              }
                  <button className='telemetry-button' onClick={() => mapRef.current.panTo([v.telemetry.position.value.latitude, v.telemetry.position.value.longitude], 18)}>Найти на карте</button>
                </div>
            )
          })
          : <p>Загрузка...</p>}
      </div>
      {
        vehicleToDisplay &&
          <div className='vehicle-tile-left'>
            <h5>Выбранное устройство:</h5>
            <br />
            IMEI: {vehicleToDisplay.telemetry.ident.value}<br />
            {
                vehicleToDisplay.name && <>Название: <br />{vehicleToDisplay.name}<br /></>
              }
            {
                vehicleToDisplay.telemetry &&
                  <>
                    Высота: {vehicleToDisplay.telemetry.position.value.altitude}<br />
                    Широта: {vehicleToDisplay.telemetry.position.value.latitude}<br />
                    Долгота: {vehicleToDisplay.telemetry.position.value.longitude}<br />
                    Скорость: {vehicleToDisplay.telemetry.position.value.speed}<br />
                    <br />
                    Доступные данные:<br />
                    {vehicleToDisplay.telemetry['vehicle.mileage'] && <>Километраж: {vehicleToDisplay.telemetry['vehicle.mileage'].value}<br /></>}
                    {vehicleToDisplay.telemetry['external.powersource.voltage'] && <>Внешний вольтаж: {vehicleToDisplay.telemetry['external.powersource.voltage'].value}<br /></>}
                    {vehicleToDisplay.telemetry['engine.motorhours'] && <>Мотор-часы: {vehicleToDisplay.telemetry['engine.motorhours'].value}<br /></>}
                    {vehicleToDisplay.telemetry['custom.pwr_akb'] && <>Мощность АКБ: {vehicleToDisplay.telemetry['custom.pwr_akb'].value}<br /></>}
                    {vehicleToDisplay.telemetry['custom.flex_fuel1'] && <>Топливо: {vehicleToDisplay.telemetry['custom.flex_fuel1'].value}<br /></>}
                    {vehicleToDisplay.telemetry['custom.fuel_temp101'] && <>Температура топлива: {vehicleToDisplay.telemetry['custom.fuel_temp101'].value}<br /></>}
                  </>
              }
            {route.length === 0 && <Button onClick={() => getRoutes(vehicleToDisplay.id)}>Найти маршруты</Button>}
            {
                route.length > 0 &&
                  <div>
                    <hr />
                    Начало: {moment.unix(route[selRoute].begin).toDate().toLocaleString('ru-RU')}<br />
                    Конец: {moment.unix(route[selRoute].end).toDate().toLocaleString('ru-RU')}<br />
                    Расстояние: {route[selRoute].distance}<br />
                    Длительность: {route[selRoute].duration}<br />
                    Средняя скорость: {route[selRoute]['avg.speed']}<br />
                    Максимальная скорость: {route[selRoute]['max.speed']}<br />
                    <div style={{ display: 'flex', justifyContent: 'space-evenly' }}><Button onClick={() => changeIndex(-1)}>&lt;</Button>
                      <Button onClick={() => changeIndex(1)}>&gt;</Button>
                    </div>
                  </div>
              }
          </div>
      }
      <div id='map'>
        {isLoading && <Loader inverse size='md' center content='loading' className='z-600' />}
      </div>
    </>
  )
}

export default MapVeh
