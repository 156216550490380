import { useState, useEffect } from 'react'
import { Alert, Input, SelectPicker } from 'rsuite'
import L from 'leaflet'
import { useTranslation } from 'react-i18next'

function CreateMarker ({ adClass, sendFunc, fieldJSON, fitBounds, layer, drawLayer, updatekey }) {
  const { t } = useTranslation()
  const [name, rename] = useState('???')
  return (
    <div className={`dropdown-content ${adClass}`}>
      <h4>Сохранение заметки</h4><hr />
      <Input id='field-name' type='text' placeholder='Введите заметку' onChange={(e) => rename(e)} />
      <button
        className='new-button' onClick={() => {
          if (fieldJSON && JSON.parse(fieldJSON).type) {
            sendFunc(JSON.stringify({ ...JSON.parse(fieldJSON), properties: { ...JSON.parse(fieldJSON).properties, name } }))
          } else {
            Alert.error('Сначала выберите место!')
          }
        }}
      >{t('common.save')}
      </button>
    </div>
  )
}

export default CreateMarker
