import React, { useEffect, useState } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import * as FA from '@fortawesome/free-solid-svg-icons'
import { getFields, addField, removeField, getGeoJSON, getFieldProps } from '../http/API'
import { Input, Alert, Button, ControlLabel, InputGroup } from 'rsuite'
import Cookies from 'js-cookie'
import { useParams } from 'react-router'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'

function DataSave ({ slave, fieldId_master, adClass, hideFunc, onlyProps, setNoteCount, noteCount }) {
  const { t, i18n } = useTranslation()
  function sortByProperty (property) {
    return function (a, b) {
      if (a[property] > b[property]) {
        return 1
      } else if (a[property] < b[property]) {
        return -1
      }
      return 0
    }
  }
  const fieldID = useParams().id
  const [selField, selectField] = useState()
  const [selCrop, selectCrop] = useState()
  const [fieldsDict, setDict] = useState()
  const [chartID, setChartID] = useState()
  const [chartYear, setChartYear] = useState()
  const [fieldsToSend, setFieldsToSend] = useState()
  const [fieldsToDisplay, setFieldsToDisplay] = useState()
  const [cropList, setCropList] = useState()
  const [isUpdating, setUpdate] = useState(false)
  useEffect(async () => {
    if (slave && fieldId_master) {
      await getField(fieldId_master)
    } else if (slave) {
      await getField(1)
    } else {
      await getField(fieldID)
    }
  }, [fieldId_master])
  const getField = async (id) => {
    const resp = await getGeoJSON(true)
    setFieldsToDisplay(resp.response.fields)
    let name
    resp.response.fields.forEach(e => {
      if (e.id === Number(id)) {
        selectField(e)
        name = e.properties.name
        document.getElementById('field_id').value = name
        setData({ ...data, field_name: name, field_id: id })
      }
    })
    isUpdating && setUpdate(false)
    try {
      await getFieldProps(name).then(j => {
        if (j.response.properties && j.response.success) {
          const area = j.response.properties.area

          setData({ ...data, area: area, field_name: name, field_id: id })
          document.getElementById('area').value = (area / 10000).toFixed(3)
        } else {
          document.getElementById('area').value = ''
          setData({ ...data })
        }
      })
    } catch (e) {

    }
  }
  const [fieldsArray, setFields] = useState([])
  const [updateKey, update] = useState(0)
  useEffect(async () => {
    // onlyProps && setNoteCount(0)
    const fieldsList = await getFields()
    if (fieldsList.response.success) {
      setCropList(fieldsList.response.cultures)
      setFields(fieldsList.response.fields.sort(sortByProperty('season')))
    }
  }, [updateKey])
  const cropData = {
    'Пшеница яровая': {
      'Казахстанская-1': {
        additives: ['Нитроаммофоска', 'Диаммофоска'],
        classes: [1, 2, 3]
      },
      'Ульбинка-25': {
        additives: ['Аммиачная селитра', 'Двойной суперфосфат'],
        classes: [1, 2, 3]
      },
      'Саратовская-29': {
        additives: ['Диаммофоска'],
        classes: [1, 2, 3]
      },
      'Саратовская-36': {
        additives: ['Диаммофоска'],
        classes: [1, 2, 3]
      }
    },
    'Пшеница озимая': {
      'Безостая-100': {
        additives: ['Нитроаммофоска', 'Диаммофоска'],
        classes: [1, 2, 3]
      },
      Граф: {
        additives: ['Аммиачная селитра', 'Двойной суперфосфат'],
        classes: [1, 2, 3]
      },
      Собербаш: {
        additives: ['Диаммофоска'],
        classes: [1, 2, 3]
      }
    },
    'Ячмень яровой': {
      'Омский-90': {
        additives: ['Нитроаммофоска', 'Суперфосфат'],
        classes: [1, 2, 3]
      },
      'Целинный-5': {
        additives: ['Нитроаммофоска', 'Суперфосфат'],
        classes: [1, 2, 3]
      },
      'Астана-17': {
        additives: ['Нитроаммофоска', 'Суперфосфат'],
        classes: [1, 2, 3]
      }
    },
    Подсолнечник: {
      Заря: {
        additives: ['Нитроаммофоска', 'Суперфосфат'],
        classes: [1, 2, 3]
      },
      'Казахстан-25': {
        additives: ['Нитроаммофоска', 'Суперфосфат'],
        classes: [1, 2, 3]
      },
      Астана: {
        additives: ['Нитроаммофоска', 'Суперфосфат'],
        classes: [1, 2, 3]
      }
    },
    'Горох посевной': {
      'Аксайский усатый 55': {
        additives: ['Аммофос', 'Суперфосфат'],
        classes: [1, 2, 3]
      },
      Ямал: {
        additives: ['Аммофос', 'Суперфосфат'],
        classes: [1, 2, 3]
      },
      Губернатор: {
        additives: ['Аммофос', 'Суперфосфат'],
        classes: [1, 2, 3]
      }
    },
    'Кукуруза на силос': {
      'Казахстанская-45': {
        additives: ['Аммиачная селитра', 'Сульфат аммония'],
        classes: [1, 2, 3]
      },
      Днепропетровский: {
        additives: ['Аммиачная селитра', 'Сульфат аммония'],
        classes: [1, 2, 3]
      },
      Кабардинка: {
        additives: ['Аммиачная селитра', 'Сульфат аммония'],
        classes: [1, 2, 3]
      }
    }
  }
  const [data, setData] = useState({
    season: '',
    date_b: '',
    date_e: '',
    croptype: '',
    class: '',
    sort: '',
    area: '',
    yield: '',
    processing: '',
    misc: '',
    field_id: ''
  })
  useEffect(() => {
    if (fieldsToDisplay) {
      const dict = {}

      fieldsToDisplay.forEach(e => {
        dict[e.id] = e.properties.name
      })
      setDict(dict)
    }
  }, [fieldsToDisplay])
  const submitField = async () => {
    if (document.getElementById('season').value !== '' && document.getElementById('croptype').value !== '') {
      try {
        const resp = await addField(data).then((res) => {
          const response = res.response
          if (response.success) {
            Alert.success(response.message)
            update(() => updateKey + 1)
          } else {
            Alert.error(response.message)
          }
        })
      } catch (e) {
        Alert.error('Ошибка соединения с сервером!')
      }
    } else {
      Alert.error('Введите тип посева и сезон!')
    }
  }
  const [checker, sureness] = useState(false)
  const deleteField = async (id) => {
    if (!checker) {
      Alert.warning('Нажмите еще раз для подтверждения!')
      sureness(true)
    } else {
      sureness(false)
      try {
        const resp = await removeField(id).then((res) => {
          const response = res.response
          if (response.success) {
            Alert.success(response.message)
            update(() => updateKey + 1)
          } else {
            Alert.error(response.message)
          }
        })
      } catch (e) {
        Alert.error('Ошибка соединения с сервером!')
      }
    }
  }
  return (
    <div className={slave ? `dropdown-content datasave-slave ${adClass}` : 'datamodal-wrapper'} style={slave && { overflowY: 'auto', maxHeight: '21s%' }}>
      <datalist id='classes'>
        {Object.keys(cropData).includes(data.croptype) && Object.keys(cropData[data.croptype]).includes(data.sort)
          ? cropData[data.croptype][data.sort].classes.map((a, key) => {
            return (
                <option key={key + 400} value={a}>{a}</option>
            )
          })
          : <>
            <option value='1'>1</option>
            <option value='2'>2</option>
            <option value='3'>3</option>
          </>}
      </datalist>
      <datalist id='additives'>
        {Object.keys(cropData).includes(data.croptype) && Object.keys(cropData[data.croptype]).includes(data.sort) &&
          cropData[data.croptype][data.sort].additives.map((a, key) => {
            return (
              <option key={key + 300} value={a}>{a}</option>
            )
          })}
      </datalist>
      <datalist id='sorts'>
        {Object.keys(cropData).includes(data.croptype)
          ? Object.keys(cropData[data.croptype]).map((a, key) => {
            return (
                <option key={key + 200} value={a}>{a}</option>
            )
          })
          : ''}
      </datalist>
      <datalist id='cultures'>
        {cropList && cropList.map((a, key) => {
          return (
            <option key={key + 100} value={a}>{a}</option>
          )
        })}
      </datalist>
      <datalist id='fields'>
        {fieldsToDisplay?.map((a, key) => {
          return (
            <option key={key + 400} value={a.properties.name}>{a.properties.name}</option>
          )
        })}
      </datalist>
      <div className='calcmodal-body data datasave-modal' key={updateKey} style={slave && { width: '100%', margin: 0, height: 'fit-content' }}>
        {
          slave
            ? <></>
            // <a style={{ width: 'fit-content', float: 'right', fontSize: '20px', padding: '5px' }} className='newbutton nav-element side dropdown' onClick={() => hideFunc(false)}>
            //   <FontAwesomeIcon icon={FA.faTimes} />
            // </a>
            : <>
              <Link style={{ width: 'fit-content', float: 'right', fontSize: '20px', padding: '5px' }} className='newbutton nav-element side dropdown' to='/profile'>
                <FontAwesomeIcon icon={FA.faHome} />
              </Link>
              <a style={{ width: 'fit-content', float: 'right', fontSize: '20px', padding: '5px' }} className='newbutton nav-element side dropdown' onClick={() => i18n.changeLanguage(i18n.language === 'ru' ? 'kz' : i18n.language === 'kz' ? 'en' : 'ru')}>
                <h5 style={{ margin: 'auto' }}>{i18n.language.toUpperCase()}</h5>
              </a>
            </>
        }
        <h3 style={slave && { fontSize: '20px' }}>{t('datasave.rotation')} {(slave || fieldID !== 'all') && selField ? `(поле ${selField.properties.name})` : ''}</h3>
        <br />
        {
          fieldsArray.length === 0
            ? 'У вашего хозяйства пока нет заметок!'
            : fieldsArray.map((f, key) => {
              //
              const count = 0
              if ((((fieldId_master || fieldID !== 'all') && data.field_id == f.field_id) || fieldID === 'all') && (!selCrop || selCrop === f.croptype)) {
                // onlyProps && setNoteCount(noteCount + 1)
                return (
                  <div key={key} style={{ width: 'fit-content' }}>
                    <div
                      className='note-div'
                      onClick={() => {
                        f.display = !f.display
                        const cl = document.getElementById(`note-div-${f.id}`).className
                        if (cl === 'hidden') {
                          document.getElementById(`note-div-${f.id}`).className = 'note-div-child'
                        } else {
                          document.getElementById(`note-div-${f.id}`).className = 'hidden'
                        }
                      }}
                    >

                      <p style={{ width: 'fit-content' }}>{f.season}: {fieldID === 'all' && `${t('sidemenu.field')} ${fieldsDict && fieldsDict[f.field_id]} -`} {f.croptype} &nbsp;</p>
                      {/* (обновлено:  {f.updatedAt.replace('T', ' ').slice(0, -8)}) */}
                      <Button
                        style={{ width: 'fit-content', fontSize: '10px', padding: '2px', float: 'right', margin: 0 }} id='delete' onClick={() => {
                          deleteField(f.id)
                        }}
                      ><FontAwesomeIcon icon={FA.faTrashAlt} />
                      </Button>
                    </div>
                    <div id={`note-div-${f.id}`} className='hidden'>
                      <p>{t('datasave.date_b')}: {f.date_b}</p>
                      <p>{t('datasave.date_e')}: {f.date_e}</p>
                      <p>{t('datasave.class')}: {f.class}</p>
                      <p>{t('datasave.sort')}: {f.sort}</p>
                      <p>{t('datasave.area')}: {(f.area / 10000).toFixed(3)} га</p>
                      <p>{t('datasave.yield')}: {f.yield} {t('sidemenu.cha')}</p>
                      <p>{t('datasave.processing')} {f.processing}</p>
                      <p>{t('profile.notes')} {f.misc}</p>
                      <p>{t('datasave.humtop')} {f.humtop}%</p>
                      <p>{t('datasave.humbottom')}: {f.humbottom}%</p>
                      <p>{t('sidemenu.wind')} {f.wind} {t('sidemenu.mps')}</p>
                      <p>{t('datasave.humid')} {f.humid}%</p>
                      <p>{t('datasave.precip')}: {f.precip} мм</p>
                      <br />
                      <button
                        style={{ background: 'var(--theme-text)', color: 'var(--theme-contrast)' }} onClick={() => {
                          setUpdate(true)
                          setChartID(f.field_id)
                          setChartYear(f.season)
                          setFieldsToSend(fieldsArray.filter((e) => e.field_id === f.field_id))
                          setData(() => ({ season: f.season, date_b: f.date_b, date_e: f.date_e, croptype: f.croptype, class: f.class, sort: f.sort, area: f.area, yield: f.yield, processing: f.processing, misc: f.misc, humtop: f.humtop, humbottom: f.humbottom, wind: f.wind, humid: f.humid, precip: f.precip, field_id: f.field_id }))
                          document.getElementById('season').value = f.season
                          document.getElementById('date_b').value = f.date_b
                          document.getElementById('date_e').value = f.date_e
                          document.getElementById('croptype').value = f.croptype
                          document.getElementById('class').value = f.class
                          document.getElementById('sort').value = f.sort
                          document.getElementById('area').value = (f.area / 10000).toFixed(3)
                          document.getElementById('yield').value = f.yield
                          document.getElementById('processing').value = f.processing
                          document.getElementById('misc').value = f.misc
                          document.getElementById('humtop').value = f.humtop
                          document.getElementById('humbottom').value = f.humbottom
                          document.getElementById('wind').value = f.wind
                          document.getElementById('humid').value = f.humid
                          document.getElementById('precip').value = f.precip
                          document.getElementById('field_id').value = f.field_id
                        }}
                      >Редактировать
                      </button>
                    </div>
                  </div>
                )
              }
            })
        }
      </div>
      <div className='calcmodal-body data'>
        <h3>Выбор поля</h3>
        <a className='note-div' href='/datasave/all'>
          Все
        </a>
        {
          fieldsToDisplay?.map((f, key) => {
            return (
              <a key={key * 13} className='note-div' href={'/datasave/' + f.id}>
                {f.properties.name}
              </a>
            )
          }
          )
        }
      </div>
      <div className='calcmodal-body data'>
        <h3>Выбор культуры</h3>
        <button className='note-div' onClick={() => selectCrop(undefined)}>
          Все
        </button>
        {
          cropList?.map((f, key) => {
            return (
              <button key={key * 17} className='note-div' onClick={() => selectCrop(f)}>
                {f}
              </button>
            )
          }
          )
        }
      </div>
      <div id='datasave' className='calcmodal-body data' style={onlyProps && { display: 'none' }}>
        <h3>{isUpdating ? `${t('datasave.update_ttle')} ${data.field_id}` : t('datasave.title_create')}</h3>
        {/* <p>
          Используя внесенные данные, мы улучшаем программу на основе машинного
          обучения
        </p> */}
        <br /> <Input
          id='field_id' list='fields' type='text' placeholder={t('datasave.name')} onChange={
          async (e) => {
            isUpdating && setUpdate(false)
            try {
              await getFieldProps(e).then(j => {
                if (j.response.properties && j.response.success) {
                  const area = j.response.properties.area
                  setData({ ...data, area: area, field_name: e })
                  document.getElementById('area').value = (area / 10000).toFixed(3)
                } else {
                  document.getElementById('area').value = ''
                  setData({ ...data, field_name: e })
                }
              })
            } catch (e) {
              setData({ ...data, field_name: e })
            }
          }
        }
               /><br />
        <Input id='season' type='number' placeholder={t('datasave.season')} onChange={e => { setData({ ...data, season: e }); isUpdating && setUpdate(false) }} /><br />
        <Input id='croptype' list='cultures' type='text' placeholder={t('datasave.croptype')} onChange={e => { setData({ ...data, croptype: e }); isUpdating && setUpdate(false) }} />
        <br />
        <button
          id='save' onClick={() => {
            submitField()
          }}
        >
          {isUpdating ? t('datasave.update') : t('datasave.submit')}
        </button>
        <hr />
        {t('datasave.date_b')} <Input id='date_b' type='date' onChange={e => setData({ ...data, date_b: e })} /><br />
        {t('datasave.date_e')} <Input id='date_e' type='date' onChange={e => setData({ ...data, date_e: e })} /><br />
        <Input id='class' type='text' placeholder={t('datasave.class')} list='classes' onChange={e => setData({ ...data, class: e })} /><br />
        <Input id='sort' type='text' placeholder={t('datasave.sort')} list='sorts' onChange={e => setData({ ...data, sort: e })} /><br />
        <Input id='sort' type='text' placeholder={t('datasave.humtop')} onChange={e => setData({ ...data, humtop: e })} /><br />
        <Input id='sort' type='text' placeholder={t('datasave.humbottom')} onChange={e => setData({ ...data, humbottom: e })} /><br />
        <Input id='sort' type='text' placeholder={t('datasave.wind')} onChange={e => setData({ ...data, wind: e })} /><br />
        <Input id='sort' type='text' placeholder={t('datasave.humid')} onChange={e => setData({ ...data, humid: e })} /><br />
        <Input id='sort' type='text' placeholder={t('datasave.precip')} onChange={e => setData({ ...data, precip: e })} /><br />
        <InputGroup>
          <Input id='area' type='text' placeholder={t('datasave.area')} onChange={e => setData({ ...data, area: e })} />
          <InputGroup.Addon>{t('sidemenu.ha')}</InputGroup.Addon>
        </InputGroup><br />
        <InputGroup>
          <Input id='yield' type='text' placeholder={t('datasave.yield')} onChange={e => setData({ ...data, yield: e })} />
          <InputGroup.Addon>{t('sidemenu.cha')}</InputGroup.Addon>
        </InputGroup><br />
        <Input id='processing' type='text' placeholder={t('datasave.processing')} list='additives' onChange={e => setData({ ...data, processing: e })} /><br />
        <Input id='misc' type='text' placeholder={t('profile.notes')} onChange={e => setData({ ...data, misc: e })} />
      </div>
    </div>
  )
}

export default DataSave
