import React, { useEffect, useState } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import * as FA from '@fortawesome/free-solid-svg-icons'
import Cookies from 'js-cookie'
import { SelectPicker } from 'rsuite'
import { useTranslation } from 'react-i18next'

const InfoPest = () => {
  const { t, i18n } = useTranslation()
  return (
    <div className='data calcmodal-body'>
      <a style={{ width: 'fit-content', float: 'right', fontSize: '20px', padding: '5px' }} className='newbutton nav-element side dropdown' href='/docslist'>
        <FontAwesomeIcon icon={FA.faHome} />
      </a>
      <h3>Справочник по пестицидам</h3>
      <hr />
      <h3 id='cat-11'>Пестициды</h3>
      <p className='docs-p'>
        Пестицид – это вещество или несколько смешанных вместе веществ химического либо биологического происхождения. Они предназначены для уничтожения вредителей или возбудителей болезней растений. Кроме этого они уничтожают различных паразитов, сорняки, вредителей зерна и зернопродуктов, древесины, изделий из хлопка, шерсти, кожи, эктопаразитов домашних животных, переносчиков опасных заболеваний человека и животных.
        <br />
        Пестициды относятся к отравителям ферментов. Под действием пестицидов часть биологических реакций перестаёт протекать в организме мишени, что позволяет побеждать болезни с помощью антибиотиков, увеличить срок хранения пищи консервантами, уничтожать насекомых инсектицидами и уничтожать сорняки гербицидами.
        <br />
        Пестициды, проникающие во все ткани живого организма или растения, называются системными пестицидами, а через еду — кишечными.
        <br />
        Использование пестицидов преследуется коммерческим интересом промышленного производства, которое требует такие показатели, как стойкость и величина урожая, его сохранность и устойчивость к перевозке. Но во вред качеству получаемой продукции, как микро элементный состав, полезность и безопасность для здоровья потребителей. Глобальной проблемой стало и разрушение биоценозов в районах применения пестицидов.
      </p>
      <h3 id='cat-12'>Гербициды</h3>
      <p className='docs-p'>
        Гербициды  - это химические вещества, которые применяют для уничтожения растительности и широко используются в сельском хозяйстве. Это отражено в самом слове где herba — трава и caedo — убиваю. По характеру действия на растения делятся на гербициды сплошного действия, убивающие все виды растений, и гербициды избирательного (селективного) действия, поражающие одни виды растений и не повреждающие другие.
        <br />
        Гербициды производят в виде смачивающихся порошков, концентратов эмульсий и концентратов суспензий.
      </p>
      <iframe src='https://bossagro.kz/glossary/category/herbicides' className='frame-pest' />
      <h3 id='cat-13'>Десиканты</h3>
      <p className='docs-p'>
        Десиканты - это химические, ядовитые препараты, вызывающие высыхание сельскохозяйственных культур перед уборкой урожая. Десикация (высушивание) применяется непосредственно перед уборкой для улучшения качества урожая и оптимизации сроков уборки.
      </p>
      <iframe src='https://bossagro.kz/glossary/category/desiccants' className='frame-pest' />
      <h3 id='cat-14'>Инсектициды и акарициды сельскохозяйственные</h3>
      <p className='docs-p'>
        Инсектициды - это вещества из группы пестицидов направленных на сокращение или уничтожение сельскохозяйственных насекомых-вредителей. Акарициды — это вещества, способные уничтожать клещей.
      </p>
      <iframe src='https://bossagro.kz/glossary/category/insecticides' className='frame-pest' />
      <h3 id='cat-15'>Моллюскоциды</h3>
      <p className='docs-p'>
        Моллюскоциды - это химические вещества для борьбы с моллюсками (от моллюски и лат. caedo — убиваю) и относятся к пестицидам. При по­мо­щи моллюскоцидов унич­то­жа­ют ули­ток и слиз­ней, яв­ляю­щих­ся вре­ди­те­ля­ми рас­те­ний.
      </p>
      <iframe src='https://bossagro.kz/glossary/category/molluscicides' className='frame-pest' />
      <h3 id='cat-16'>Нематициды</h3>
      <p className='docs-p'>
        Нематоциды, нематициды — химические вещества, уничтожающие растительноядных нематод. Слово происходит от нематоды и латинского caedo — убиваю, уничтожаю. Иногда к нематоцидам также относят антигельминтные средства, уничтожающие нематодных паразитов животных.
        <br />
        У пораженных нематодами растений наблюдается гибель корней, порча корнеплодов и образование галлов. Стеблевые нематоды вызывают растрескивание стеблей, гофрировку листьев и так далее. Особенно большой вред нематоды наносят в защищенном грунте и посадках картофеля.
      </p>
      <iframe src='https://bossagro.kz/glossary/category/nematicides' className='frame-pest' />
      <h3 id='cat-17'>Репелленты</h3>
      <p className='docs-p'>
        Репеллент — природное или синтетическое химическое вещество, применяемое в бытовых целях для отпугивания членистоногих. Слово происходит от лат. repellens — отталкивающий, отвращающий. В отличие от инсектицидов репелленты предназначены не для уничтожения насекомых, а только для их отпугивания.
      </p>
      <iframe src='https://bossagro.kz/glossary/category/repellents' className='frame-pest' />
      <h3 id='cat-18'>Родентициды</h3>
      <p className='docs-p'>
        Родентициды - это в основном химические вещества для сокращения или уничтожения популяций грызунов. Название этого вида пестицида состоит из двух слов: rodent – грызун и cide – сокращать (смысловой перевод – средства, сокращающие численность грызунов). В сельском хозяйстве родентициды используются для защиты растений, помещений для хранения сельскохозяйственной продукции.
      </p>
      <iframe src='https://bossagro.kz/glossary/category/rodenticides' className='frame-pest' />
      <h3 id='cat-19'>Феромоны</h3>
      <p className='docs-p'>
        Феромоны – продукты внешней секреции, выделяемые некоторыми видами животных и обеспечивающих коммуникацию между особями одного вида. Также, феромоны синтезируются растениями.
        <br />
        В сельском хозяйстве, феромоны - это группа действующих веществ пестицидов, применяемых для регулирования численности вредных насекомых. В сочетании с ловушками разных типов феромоны, приманивающие насекомых, позволяют уничтожать значительные количества вредителей. Также распыление феромонов над охраняемыми сельскохозяйственными угодьями позволяет обмануть самцов вредителей и таким образом снизить популяцию вредных насекомых.
      </p>
      <iframe src='https://bossagro.kz/glossary/category/pheromones' className='frame-pest' />
      <h3 id='cat-20'>Фумиганты</h3>
      <p className='docs-p'>
        Фумигация  — уничтожение вредителей и возбудителей болезней растений путём отравления их ядовитыми парами или газами (фумигантами). Слово происходит от латинского fumigare — окуривать, дымить. Для фумигации используют приборы, называемые фумигаторами.
        <br />
        По современной терминологии фумигантом называется химикат, который при требуемой температуре и давлении может сохраняться в газообразной фазе в летальной концентрации для данного вредного организма. Это определение подразумевает, что фумигант воздействует в качестве газа. Это свойство позволяет им проникать в фумигируемые материалы и диффундировать на большие расстояния.
      </p>
      <iframe src='https://bossagro.kz/glossary/category/fumigants' className='frame-pest' />
      <h3 id='cat-21'>Фунгициды</h3>
      <p className='docs-p'>
        Фунгициды - (от лат. fungus «гриб» + лат. caedo «убиваю») — химические вещества для борьбы с грибковыми болезнями растений, а также для протравливания семян с целью освобождения их от спор паразитных грибов. Концентраты фунгицидов токсичны для человека и животных.
      </p>
      <iframe src='https://bossagro.kz/glossary/category/fungicides' className='frame-pest' />
      <h3 id='cat-22'>Регуляторы роста растений</h3>
      <p className='docs-p'>
        Регуляторы роста растений – это природные или синтетические химические вещества, применяющиеся для обработки растений, с целью изменить процессы их жизнедеятельности или структуру для улучшения их качества, увеличения урожайности или облегчения уборки.
        <br />
        К регуляторам роста растений также относят фитогормоны. Фитогормоны — это химические вещества, которые вырабатываются в растениях и координируют их рост и развитие.
      </p>
      <iframe src='https://bossagro.kz/glossary/category/regulyatory-rosta-rastenij' className='frame-pest' />
    </div>
  )
}

export default InfoPest
