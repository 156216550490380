import React, { useContext, useRef, useEffect, useState } from 'react'
import L from 'leaflet'
// import 'leaflet/dist/leaflet.css'
import 'leaflet-imageoverlay-rotated'
import 'leaflet-toolbar'
// import 'leaflet-distortableimage'
import MapControls from './MapControls'
import { Loader, Alert, Whisper, Tooltip } from 'rsuite'
import * as turf from '@turf/turf'
import { useTranslation } from 'react-i18next'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import * as FA from '@fortawesome/free-solid-svg-icons'
import '../styles/sidenav.css'
import Cookies from 'js-cookie'
import { addGeoJSON, deleteGeoJSON, getDataPoints, getGeoJSON } from '../http/API'
import FieldsListMain from '../sidecomponents/sidewindows/FieldsListMain'
import { Heatmap, HeatmapLayer } from 'react-leaflet-heatmap-layer-v3'
import { useMap, Marker, Popup, TileLayer, MapContainer } from 'react-leaflet'
import kriging from '../kriging/kriging'
import MacroElements from '../sidecomponents/sidewindows/MacroElements'
import MacroSaved from '../sidecomponents/sidewindows/MacroSaved'

const MapMacro = () => {
  document.documentElement.style.overflow = 'hidden'
  const [jsonToSave, saveJSON] = useState('')
  const [heatmap, setType] = useState(!false)
  const [MapHeat, setHeatmap] = useState(null)
  const [chosenPoints, setChosenPoints] = useState([])
  const [markerJSON, setMarkerJSON] = useState('')
  const [jsonToDisplay, setJSON] = useState([])
  const [datapoints, setPoints] = useState([])
  const [fieldToUpdate, setFieldToUpdate] = useState()
  const [updateKey, update] = useState(0)
  const [fieldID, setFieldID] = useState()
  const [coords, setCoords] = useState()
  const [checker, sureness] = useState(false)
  const [krigingDone, setKrigingDone] = useState(false)
  const [mapType, setMap] = useState(Cookies.get('pref-map') || 'http://mt1.google.com/vt/lyrs=y&x={x}&y={y}&z={z}')
  function sortByProperty (property) {
    return function (a, b) {
      if (a[property] > b[property]) {
        return 1
      } else if (a[property] < b[property]) {
        return -1
      }
      return 0
    }
  }
  const CloseOtherModals = () => {
    setFieldsListShow(false)
    setNotesListShow(false)
    setDataPointSelectorShow(false)
    setMacroSaveShow(false)
  }
  useEffect(async () => {
    const fieldsList = await getGeoJSON()
      .catch((e) => {
        if (String(e).includes('401')) {
          // Cookies.remove('token')
          window.location.href = '/map_main'
        }
      })
    if (fieldsList.response.success) {
      setJSON(fieldsList.response.fields.sort(sortByProperty('id')))
      try {
        if (updateKey === 0) {
          fitBounds(JSON.parse(fieldsList.response.fields[0].json))
        }
      } catch (e) {

      }
    }
    showLayerRef.current.clearLayers()
  }, [updateKey])
  const loadDataPoints = async (field_id, season, type) => {
    const data = await getDataPoints(field_id, season, type).then(res => {
      setPoints(res.response.data)
    })
  }
  useEffect(() => {
    datapoints.forEach(p => {
      const point = L.Marker(p.coordinates)
      point.addTo(mapRef.current)
    })
  }, [datapoints])
  const addField = async (field, notClear) => {
    try {
      let response
      const resp = await addGeoJSON(field).then((res) => {
        response = res.response
        if (response.success) {
          Alert.success(response.message)
          !notClear && clearLayers()
          !notClear && update(updateKey + 1)
        } else {
          Alert.error(response.message)
        }
      })
    } catch (e) {
      Alert.error(t('error.noconnection'))
    }
  }
  const [FieldsListShow, setFieldsListShow] = useState(false)
  const [NotesListShow, setNotesListShow] = useState(false)
  const [DataPointSelectorShow, setDataPointSelectorShow] = useState(true)
  const [MacroSaveShow, setMacroSaveShow] = useState(false)

  const [fieldMaster, setfieldMaster] = useState()
  const [fieldNameMaster, setfieldNameMaster] = useState()
  const { t, i18n } = useTranslation()
  // const { dataTiles, fetchDataTiles, activeTile, setActiveTile } = useContext(SatImgContext)
  // const { searchOptions, setSearchOptions, jsonLayers, newOrder, setNewOrder } = useContext(SearchOptionsContext)
  const [isLoading, setIsLoading] = useState(false)

  const MAX_AREA = 6313063483978.778// in ha
  const footprintOptions = {
    stroke: true,
    // color: '#4185f4',
    color: '#E3AF34',
    weight: 3,
    opacity: 0.6,
    fill: true,
    fillColor: null,
    fillOpacity: 0,
    clickable: true
  }

  const mapRef = useRef(null) // map
  const paneRef = useRef(null) // tiles order on top
  const drawnLayerRef = useRef(null) // drawn footrpints - paths (lines) = featureGroup
  const markerRef = useRef(null) // drawn footrpints - paths (lines) = featureGroup
  const showLayerRef = useRef(null) // drawn footrpints - paths (lines) = featureGroup
  const tileLayerRef = useRef(null) // tiles in tilelayer = featureGroup
  const searchGeometryRef = useRef(null) // search geometries on the map = featureGroup
  // const searchGeometryRef2 = useRef(null) // search geometries on the map = featureGroup
  // const [maptype, changeMap] = useState('alidade_smooth_dark')
  let map

  // TODO: remove if not used in production = map cleaning - alternative method
  // function removeLayers () {
  //   if (mapRef?.current) {
  //     if (mapRef.current.hasLayer(drawnLayerRef.current)) {
  //       mapRef.current.removeLayer(drawnLayerRef.current)
  //     }
  //     if (mapRef.current.hasLayer(tileLayerRef.current)) {
  //       mapRef.current.removeLayer(tileLayerRef.current)
  //     }
  //   }
  // }

  function clearLayers () {
    if (mapRef?.current) {
      // if (mapRef.current.hasLayer(searchGeometryRef.current)) searchGeometryRef.current.clearLayers()
      if (mapRef.current.hasLayer(drawnLayerRef.current)) drawnLayerRef.current.clearLayers()
      if (mapRef.current.hasLayer(markerRef.current)) markerRef.current.clearLayers()
      if (mapRef.current.hasLayer(tileLayerRef.current)) tileLayerRef.current.clearLayers()
    }
  }

  /** map initialization */
  useEffect(() => {
    try {
      map = L.map('map', {
        center: [49.945963, 82.613922],
        zoom: 10,
        minZoom: 1,
        zoomControl: false,
        attributionControl: false
      })
      L.tileLayer(mapType, {
        subdomains: ['mt0', 'mt1', 'mt2', 'mt3'],
        maxZoom: 18,
        id: 'baselayer'
      }).addTo(map)
      L.control.attribution({
        position: 'bottomright'
      }).addTo(map)
      // map.fitBounds([[47.12995075666307, 52.064208984375], [51.1807, 71.461]])

      // Searched geometries
      searchGeometryRef.current = L.featureGroup().addTo(map)
      markerRef.current = L.featureGroup().addTo(map)
      showLayerRef.current = L.featureGroup().addTo(map)
      drawnLayerRef.current = L.featureGroup().addTo(map)
      tileLayerRef.current = L.featureGroup().addTo(map)
      mapRef.current = map
      // Pane for tiles topping on the map
      const topPane = mapRef?.current.createPane('leaflet-top-pane', mapRef.current.getPanes().mapPane)
      paneRef.current = topPane

      // Adding Controls
      MapControls(mapRef.current, t)
      mapRef.current.invalidateSize(9)
    } catch (e) {

    }
  }, [])
  // useEffect(() => {
  //   searchGeometryRef2.current = L.featureGroup().addTo(MapHeat)
  // }, [MapHeat])
  useEffect(() => {
    // mapRef.current.removeLayer('baselayer')
    mapRef.current.attributionControl.setPrefix(Cookies.get('pref-attr'))
    L.tileLayer(mapType, {
      maxZoom: 18,
      id: 'baselayer'
    }).addTo(mapRef.current)
  }, [mapType])
  useEffect(() => {
    searchGeometryRef.current.clearLayers()
    // searchGeometryRef2.current.clearLayers()
    jsonToDisplay.forEach((f) => {
      const mask = L.geoJSON(JSON.parse(f.json))
      const name = JSON.parse(f.json).properties.name

      if (JSON.parse(f.json).geometry?.type === 'Point') {
        mask.bindTooltip(() => name, { permanent: true })
        mask.addEventListener('mouseover', () => {
          mask.unbindTooltip()
          const time = new Date(f.createdAt)
          mask.bindTooltip(() => `Создано: ${time.toLocaleString('ru-RU')}`, { permanent: true })
        })
        mask.addEventListener('mouseout', () => {
          mask.unbindTooltip()
          mask.bindTooltip(() => name, { permanent: true })
        })
      } else {
        mask.bindTooltip(() => name)
        mask.addEventListener('click', () => { setFieldID(f.id); setDataPointSelectorShow(true) })
        mask.addEventListener('dblclick', () => {
          fitBounds(JSON.parse(f.json))
        })
      }
      mask.addEventListener('mouseover', () => (mask.openTooltip()))
      searchGeometryRef.current.addLayer(mask)
      // searchGeometryRef2.current.addLayer(mask)
    })
  }, [jsonToDisplay])
  /** Handling drawing on map */
  const handleDrawCreated = React.useCallback(e => {
    clearLayers()
    const layer = e.layer
    if (e.layerType === 'marker') {
      const gjson = layer.toGeoJSON()

      setCoords(gjson)
      // setMarkerJSON(JSON.stringify(gjson))
      drawnLayerRef.current.addLayer(layer)
    } else {
      const area = L.GeometryUtil.geodesicArea(layer.getLatLngs()[0])
      const gjson = layer.toGeoJSON()
      gjson.properties = {
        name: 'test',
        area: area
      }
      saveJSON(JSON.stringify(gjson))
      if (area <= MAX_AREA) {
        // setSearchLayer(() => layer.toGeoJSON())
      } else {
        Alert.info(t('errors.bigarea'), 5000)
      }
      drawnLayerRef.current.addLayer(layer)
    }
  }, [])
  useEffect(() => {
    // mapRef.current.on('draw:created', function (e) {
    //   // const type = e.layerType
    //   const layer = e.layer
    //   setSearchLayer(layer.toGeoJSON())
    //   searchGeometryRef.current.clearLayers()
    //   searchGeometryRef.current.addLayer(e.layer)
    // })
    mapRef.current.on('draw:created', handleDrawCreated)
  }, [handleDrawCreated])

  const fitBounds = (field) => {
    const mask = L.geoJSON(field)

    if (field.geometry?.type === 'Point') {
      const lat = field.geometry.coordinates[1]
      const lng = field.geometry.coordinates[0]
      mapRef.current.panTo([lat, lng], 10)
    } else {
      mapRef.current.fitBounds(mask.getLayers()[0].getLatLngs())
    }
  }

  useEffect(() => {
    if (mapRef.current && mapRef.current !== null && MapHeat && MapHeat !== null) {
      if (!heatmap) {
        const coords = mapRef.current.getCenter()
        MapHeat?.setView(coords, mapRef.current.getZoom())
      } else {
        const coords = MapHeat.getCenter()
        mapRef.current?.setView(coords, MapHeat.getZoom())
      }
    }
  }, [heatmap])

  useEffect(() => {
    function interpolateArray (data, fitCount) {
      const linearInterpolate = function (before, after, atPoint) {
        return before + (after - before) * atPoint
      }

      const newData = new Array()
      const springFactor = new Number((data.length - 1) / (fitCount - 1))
      newData[0] = data[0] // for new allocation
      for (let i = 1; i < fitCount - 1; i++) {
        const tmp = i * springFactor
        const before = new Number(Math.floor(tmp)).toFixed()
        const after = new Number(Math.ceil(tmp)).toFixed()
        const atPoint = tmp - before
        newData[i] = linearInterpolate(data[before], data[after], atPoint)
      }
      newData[fitCount - 1] = data[data.length - 1] // for new allocation
      return newData
    };
    if (chosenPoints.length > 1 && !krigingDone) {
      const Arraymean = function (array) {
        let i, sum
        for (i = 0, sum = 0; i < array.length; i++) { sum += array[i] }
        return sum / array.length
      }
      const t = []
      const x = []
      const y = []
      chosenPoints.forEach(p => {
        t.push(p[2])
        x.push(p[0])
        y.push(p[1])
      })
      const model = 'exponential'
      const sigma2 = 0
      const alpha = 100
      const variogram = kriging.train(t, x, y, model, sigma2, alpha)
      const bounds = [Math.max(...x), Math.max(...y), Math.min(...x), Math.min(...y)]
      const xlist = interpolateArray([bounds[2], bounds[0]], 10)
      const ylist = interpolateArray([bounds[3], bounds[1]], 10)
      const arr = []

      xlist.forEach(xnew => {
        ylist.forEach(ynew => {
          const tpred = kriging.predict(xnew, ynew, variogram)
          arr.push([xnew, ynew, tpred])
        })
      })

      setKrigingDone(true)
      setChosenPoints([...chosenPoints, ...arr])
    }
  }, [chosenPoints])
  // useEffect(() => {
  //   if (mapRef.current !== null && mapRef.current) {
  //
  //     mapRef.current && MapHeat?.setView([mapRef.current.center, mapRef.current.zoom])
  //   }
  // }, [MapHeat])
  return (
    <>
      <nav className='navbar-side-new'>
        <button className='new-link-element'>
          <img src='/logo192.png' alt='' width='40px' />
        </button>
        {/* <button className={`new-link-element ${FieldsListShow ? 'dropdown-chosen' : ''}`} onClick={() => { CloseOtherModals(); setFieldsListShow(!FieldsListShow) }} title={t('map.sidebar.fieldslist')}>
              <FontAwesomeIcon icon={FA.faMapMarkedAlt} />
      </button> */}
        <button className={`new-link-element ${DataPointSelectorShow ? 'dropdown-chosen' : ''}`} onClick={() => { CloseOtherModals(); setDataPointSelectorShow(!DataPointSelectorShow) }} title={t('mc.title')}>
          <FontAwesomeIcon icon={FA.faPrescriptionBottle} />
          <h5>{t('mc.title')}</h5>
        </button>
        <button className={`new-link-element ${MacroSaveShow ? 'dropdown-chosen' : ''}`} onClick={() => { CloseOtherModals(); setMacroSaveShow(!MacroSaveShow) }} title={t('mc.title')}>
          <FontAwesomeIcon icon={FA.faDrawPolygon} />
          <h5>{t('mc.maps')}</h5>
        </button>
        <button className='new-link-element' onClick={() => i18n.changeLanguage(i18n.language === 'ru' ? 'kz' : i18n.language === 'kz' ? 'en' : 'ru')} title={t('common.lang')}>
          <FontAwesomeIcon icon={FA.faGlobe} />
          <h5>
            {t('map.sidebar.lang')}{i18n.language.toUpperCase()}
          </h5>
        </button>
        <button onClick={() => { window.location.href = '/profile' }} className='new-link-element' title={t('common.return')}>
          <FontAwesomeIcon icon={FA.faBackward} />
          <h5>{t('common.return')}</h5>
        </button>
      </nav>
      <div className='navbar-side-wrapper'>
        <MacroElements fieldID={fieldID} onHide={() => setDataPointSelectorShow(false)} adClass={!DataPointSelectorShow ? 'hidden' : ''} coordinates={coords} layerRef={markerRef} mapRef={mapRef} fieldsList={jsonToDisplay} setFieldID={setFieldID} setDispPoints={setChosenPoints} setHeatmap={setType} heatmap={heatmap} setKrigingDone={setKrigingDone} ukey={updateKey} update={update} goto = {() => { CloseOtherModals(); setMacroSaveShow(true) }}/>
        <MacroSaved fieldID={fieldID} onHide={() => setMacroSaveShow(false)} adClass={!MacroSaveShow ? 'hidden' : ''} ukey={updateKey} update = {update} fieldsList = {jsonToDisplay}/>
      </div>
      <div className={heatmap ? 'hidden-map' : 'vis-map'} id='heatmap'>
        <MapContainer center={[51.505, -0.09]} zoom={13} ref={setHeatmap}>
          <HeatmapLayer
            radius={100}
            blur={100}
            fitBoundsOnLoad
            fitBoundsOnUpdate
            points={chosenPoints}
            longitudeExtractor={m => m[1]}
            latitudeExtractor={m => m[0]}
            intensityExtractor={m => parseFloat(m[2])}
          />
          <TileLayer
            url='http://mt1.google.com/vt/lyrs=y&x={x}&y={y}&z={z}'
          />
        </MapContainer>
      </div>
      <div id='map' className={!heatmap ? 'hidden-map' : 'vis-map'}>
        {isLoading && <Loader inverse size='md' center content='loading' className='z-600' />}
      </div>
    </>
  )
}

export default MapMacro
