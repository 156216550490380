import 'rsuite/dist/styles/rsuite-dark.css'
import MapSat from '../map/MapSat'

function mapSatWrap () {
  // const { setNavItemId } = useContext(NavigationContextProvider)
  // TODO: add logic for router order path

  return (
    <div className='App'>
      <MapSat />
    </div>
  )
}

export default mapSatWrap
