import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import * as FA from '@fortawesome/free-solid-svg-icons'
import Cookies from 'js-cookie'

const APIref = () => {
  const apikey = Cookies.get('apikey')
  return (
    <div className='dark calcmodal-body'>
      <h3>SmartAgronom OpenAPI</h3>
      {/* <h4>Пользовательский API</h4> */}
      <div className='apiroute'>
      <h5>Регистрация</h5>
      <br />
        <p>Путь: https://smartagriculture.ektu.kz{process.env.REACT_APP_API_URL}user/registration</p>
        <p>Метод: POST</p>
        <p>Пример запроса:</p>
        <code>
          {'{'}
            <br />
            &emsp;&quot;first_name&quot;: &quot;Name&quot;,
            <br />
            &emsp;&quot;last_name&quot;: &quot;Surname&quot;,
            <br />
            &emsp;&quot;email&quot;: &quot;test@smartagriculture.ektu.kz&quot;,
            <br />
            &emsp;&quot;password&quot;: &quot;p@ssw0rd&quot;,
            <br />
            &emsp;&quot;place&quot;: &quot;EKTU&quot;,
            <br />
            &emsp;&quot;rkey&quot;: &quot;rkeyUSER12345&quot;
            <br />
          {'}'}
        </code>
        <p>Пример ответа:</p>
        <code>
          {'{'}
            <br />
            &emsp;&quot;token&quot;: &quot;eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJzdWIiOiIxMjM0NTY3ODkwIiwibmFtZSI6IkpvaG4gRG9lIiwiYWRtaW4iOnRydWV9.dyt0CoTl4WoVjAHI9Q_CwSKhl6d_9rhM3NrXuJttkao&quot;,
            <br />
            &emsp;&quot;success&quot;: true,
            <br />
            &emsp;&quot;message&quot;: &quot;Аккаунт успешно создан!&quot;
            <br />
          {'}'}
        </code>
        <p>Возможные ошибки:</p>
        <code>
          {'{'}
            <br />
            &emsp;&quot;success&quot;: false,
            <br />
            &emsp;&quot;message&quot;: &quot;Пользователь с таким адресом уже существует&quot;
            <br />
          {'}'}
        </code>
        <br />
        <code>
          {'{'}
            <br />
            &emsp;&quot;success&quot;: false,
            <br />
            &emsp;&quot;message&quot;: &quot;Одноразовый ключ регистрации не найден&quot;
            <br />
          {'}'}
        </code>
        <br />
        <code>
          {'{'}
            <br />
            &emsp;&quot;success&quot;: false,
            <br />
            &emsp;&quot;message&quot;: &quot;Одноразовый ключ регистрации использован!&quot;
            <br />
          {'}'}
        </code>
      </div>
      <div className='apiroute'>
      <h5>Вход в аккаунт</h5>
      <br />
        <p>Путь: https://smartagriculture.ektu.kz{process.env.REACT_APP_API_URL}user/login</p>
        <p>Метод: POST</p>
        <p>Пример запроса:</p>
        <code>
          {'{'}
            <br />
            &emsp;&quot;email&quot;: &quot;test@smartagriculture.ektu.kz&quot;,
            <br />
            &emsp;&quot;password&quot;: &quot;p@ssw0rd&quot;,
            <br />
          {'}'}
        </code>
        <p>Пример ответа:</p>
        <code>
          {'{'}
            <br />
            &emsp;&quot;token&quot;: &quot;eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJzdWIiOiIxMjM0NTY3ODkwIiwibmFtZSI6IkpvaG4gRG9lIiwiYWRtaW4iOnRydWV9.dyt0CoTl4WoVjAHI9Q_CwSKhl6d_9rhM3NrXuJttkao&quot;,
            <br />
            &emsp;&quot;user&quot;:&emsp;{'{'}
            <br />
            &emsp;&emsp;&quot;id&quot;: 1,
            <br />
            &emsp;&emsp;&quot;first_name&quot;: &quot;Name&quot;,
            <br />
            &emsp;&emsp;&quot;last_name&quot;: &quot;Surname&quot;,
            <br />
            &emsp;&emsp;&quot;email&quot;: &quot;test@smartagriculture.ektu.kz&quot;,
            <br />
            &emsp;&emsp;&quot;password&quot;: &quot;0f359740bd1cda994f8b55330c86d845&quot;,
            <br />
            &emsp;&emsp;&quot;place&quot;: &quot;EKTU&quot;,
            <br />
            &emsp;&emsp;&quot;role&quot;: &quot;USER&quot;,
            <br />
            &emsp;&emsp;&quot;apikey&quot;: &quot;4f9d5fe0-a964-4f11-af99-6c40de98af77&quot;,
            <br />
            &emsp;&emsp;&quot;rkey&quot;: &quot;rkeyUSER12345&quot;
            <br />
            &emsp;&emsp;&quot;createdAt&quot;: &quot;2022-06-16T13:46:37.707Z&quot;,
            <br />
            &emsp;&emsp;&quot;updatedAt&quot;: &quot;2022-06-16T13:46:37.707Z&quot;
            <br />
            &emsp;{'}'}
            <br />
            &emsp;&quot;success&quot;: true,
            <br />
            &emsp;&quot;message&quot;: &quot;Добро пожаловать, Bot!&quot;
            <br />
          {'}'}
        </code>
        <p>Возможные ошибки:</p>
        <code>
          {'{'}
            <br />
            &emsp;&quot;success&quot;: false,
            <br />
            &emsp;&quot;message&quot;: &quot;Пользователь не найден!&quot;
            <br />
          {'}'}
        </code>
        <br />
        <code>
          {'{'}
            <br />
            &emsp;&quot;success&quot;: false,
            <br />
            &emsp;&quot;message&quot;: &quot;Неверный пароль!&quot;
            <br />
          {'}'}
        </code>
      </div>
      <div className='apiroute'>
      <h5>Отправка сообщения администрации</h5>
      <br />
        <p>Путь: https://smartagriculture.ektu.kz{process.env.REACT_APP_API_URL}user/contact</p>
        <p>Метод: POST</p>
        <p>Пример запроса:</p>
        <code>
          {'{'}
            <br />
            &emsp;&quot;name&quot;: &quot;AgroUser&quot;,
            <br />
            &emsp;&quot;message&quot;: &quot;Lorem ipsum dolor sit amet, consectetur adipiscing elit.&quot;,
            <br />
            &emsp;&quot;email&quot;: &quot;test@smartagriculture.kz&quot;,
            <br />
            &emsp;&quot;phone&quot;: &quot;+7 (777) 777-77-77&quot;
            <br />
          {'}'}
        </code>
        <p>Пример ответа:</p>
        <code>
          {'{'}
            <br />
            &emsp;&quot;success&quot;: true,
            <br />
            &emsp;&quot;message&quot;: &quot;AgroUser, Ваше сообщение было успешно отправлено!&quot;
            <br />
          {'}'}
        </code>
      </div>
      <div className='apiroute'>
      <h5>Создание поля</h5>
      <br />
        <p>Путь: https://smartagriculture.ektu.kz{process.env.REACT_APP_API_URL}map/add?apikey={apikey}</p>
        <p>Метод: POST</p>
        <p>Пример запроса:</p>
        <code>
          {'{'}
            <br />
            &emsp;&quot;json&quot;: &quot;
            {'{\\"'}type{'\\"'}:{'\\"'}Feature{'\\"'},
            {'\\"'}properties{'\\"'}:
            {'{\\"'}name{'\\"'}:{'\\"'}TestField{'\\"'},{'\\"'}area{'\\"'}:19.814,{'\\"'}cadaster{'\\"'}:{'\\"'}N12341234{'\\"'},{'\\"'}soiltype{'\\"'}:{'\\"'}Чернозем южный, малогумусный{'\\"'}{'}'},
            {'\\"'}geometry{'\\"'}:
            {'{\\"'}type{'\\"'}:{'\\"'}Polygon{'\\"'},{'\\"'}coordinates{'\\"'}:[[[82.72594,50.092706],[82.72594,50.092758],[82.725989,50.092758],[82.725989,50.092706],[82.72594,50.092706]]]{'}}'}
            &quot;, //поле GeoJSON, в формате строки (JSON.stringify)
            <br />
          {'}'}
        </code>
        <p>Пример поля:</p>
        <code>
          {'{'}
            <br />
            &emsp;&quot;type&quot;: &quot;Feature&quot;,
            <br />
            &emsp;&quot;properties&quot;: {'{'}
            <br />
            &emsp;&emsp;&quot;name&quot;: &quot;TestField&quot;, //имя поля (должно быть уникальным для пользователя)
            <br />
            &emsp;&emsp;&quot;area&quot;: &quot;19.814&quot;, //площадь поля в гектарах
            <br />
            &emsp;&emsp;&quot;cadaster&quot;: &quot;N12341234&quot;, //кадастровый номер
            <br />
            &emsp;&emsp;&quot;soiltype&quot;: &quot;Чернозем южный, малогумусный&quot; //тип почвы
            <br />
            &emsp;{'}'},
            <br />
            &emsp;&quot;geometry&quot;: {'{'}
            <br />
            &emsp;&emsp;&quot;type&quot;: &quot;Polygon&quot;,
            <br />
            &emsp;&emsp;&quot;coordinates&quot;: &quot;[[[82.72594,50.092706],[82.72594,50.092758],[82.725989,50.092758],[82.725989,50.092706],[82.72594,50.092706]]]&quot;
            <br />
            &emsp;{'}'}
            <br />
          {'}'}
        </code>
        <p>Пример ответа:</p>
        <code>
          {'{'}
          <br />
            &emsp;&quot;success&quot;: true,
            <br />
            &emsp;&quot;message&quot;: &quot;Поле TestField успешно сохранено!&quot;
            <br />
          {'}'}
        </code>
        <p>Возможные ошибки:</p>
        <code>
          {'{'}
            <br />
            &emsp;&quot;success&quot;: false,
            <br />
            &emsp;&quot;message&quot;: &quot;Поле с таким именем уже существует!&quot;
            <br />
          {'}'}
        </code>
      </div>
      <div className='apiroute'>
      <h5>Получение списка полей с полигонами</h5>
      <br />
        <p>Путь: https://smartagriculture.ektu.kz{process.env.REACT_APP_API_URL}map/get?apikey={apikey}</p>
        <p>Метод: GET</p>
        <p>Пример ответа:</p>
        <code>
          {'{'}
            <br />
            &emsp;&quot;fields&quot;: [
              <br />
              &emsp;&emsp;{'{'}
              <br />
              &emsp;&emsp;&emsp;&quot;id&quot;: 1,
              <br />
              &emsp;&emsp;&emsp;&quot;json&quot;: &quot;.....&quot;, //GeoJSON поля в формате строки
              <br />
              &emsp;&emsp;&emsp;&quot;place&quot;: &quot;EKTU&quot;,
              <br />
              &emsp;&emsp;&emsp;&quot;userid&quot;: 1
            <br />
            &emsp;&emsp;{'}'},
              <br />
              &emsp;&emsp;{'{'}
              <br />
              &emsp;&emsp;&emsp;&quot;id&quot;: 2,
              <br />
              &emsp;&emsp;&emsp;&quot;json&quot;: &quot;.....&quot;, //GeoJSON поля в формате строки
              <br />
              &emsp;&emsp;&emsp;&quot;place&quot;: &quot;EKTU&quot;,
              <br />
              &emsp;&emsp;&emsp;&quot;userid&quot;: 1
            <br />
            &emsp;&emsp;{'}'},
            <br />
            ...
            <br />
            &emsp;]
            <br />
          {'}'}
        </code>
      </div>
      <div className='apiroute'>
      <h5>Получение списка свойств полей (без полигонов)</h5>
      <br />
        <p>Путь: https://smartagriculture.ektu.kz{process.env.REACT_APP_API_URL}map/get?apikey={apikey}&onlyProps=true</p>
        <p>Метод: GET</p>
        <p>Пример ответа:</p>
        <code>
          {'{'}
            <br />
            &emsp;&quot;fields&quot;: [
              <br />
              &emsp;&emsp;{'{'}
              <br />
              &emsp;&emsp;&emsp;&quot;id&quot;: 1,
              <br />
              &emsp;&emsp;&emsp;&quot;json&quot;: &quot;.....&quot;, //GeoJSON поля в формате строки
              {/* <br />
              &emsp;&emsp;&emsp;&quot;place&quot;: &quot;EKTU&quot;, */}
              <br />
              &emsp;&emsp;&emsp;&quot;properties&quot;: {'{'}
              <br />
              &emsp;&emsp;&emsp;&emsp;&quot;name&quot;: &quot;.....&quot;,
              <br />
              &emsp;&emsp;&emsp;&emsp;&quot;cadaster&quot;: &quot;.....&quot;,
              <br />
              &emsp;&emsp;&emsp;&emsp;&quot;area&quot;: &quot;.....&quot;,
              <br />
              &emsp;&emsp;&emsp;&emsp;&quot;soiltype&quot;: &quot;.....&quot;,
              <br />
              &emsp;&emsp;&emsp;{'}'},
              <br />
              &emsp;&emsp;&emsp;&quot;userid&quot;: 1
            <br />
            &emsp;&emsp;{'}'},
            <br />
            ...
            <br />
            &emsp;]
            <br />
          {'}'}
        </code>
      </div>
      <div className='apiroute'>
      <h5>Обновление данных поля</h5>
      <br />
        <p>Путь: https://smartagriculture.ektu.kz{process.env.REACT_APP_API_URL}map/update?apikey={apikey}</p>
        <p>Метод: PUT</p>
        <p>Пример запроса:</p>
        <code>
          {'{'}
            <br />
            &emsp;&quot;id&quot;: 1,
            <br />
            &emsp;&quot;newname&quot;: &quot;NewField&quot;,
            <br />
            &emsp;&quot;cadaster&quot;: &quot;N14321234&quot;,
            <br />
            &emsp;&quot;soil&quot;: &quot;Type 2&quot;
            <br />
          {'}'}
        </code>
        <p>Пример ответа:</p>
        <code>
          {'{'}
          <br />
            &emsp;&quot;success&quot;: true,
            <br />
            &emsp;&quot;message&quot;: &quot;Данные о поле обновлены!&quot;
            <br />
          {'}'}
        </code>
        <p>Возможные ошибки:</p>
        <code>
          {'{'}
            <br />
            &emsp;&quot;success&quot;: false,
            <br />
            &emsp;&quot;message&quot;: &quot;Поле NewField уже существует!&quot;
            <br />
          {'}'}
        </code>
      </div>
      <div className='apiroute'>
      <h5>Удаление поля</h5>
      <br />
        <p>Путь: https://smartagriculture.ektu.kz{process.env.REACT_APP_API_URL}map/delete?apikey={apikey}</p>
        <p>Метод: POST</p>
        <p>Пример запроса:</p>
        <code>
          {'{'}
            <br />
            &emsp;&quot;id&quot;: 1,
            <br />
          {'}'}
        </code>
        <p>Пример ответа:</p>
        <code>
          {'{'}
          <br />
            &emsp;&quot;success&quot;: true,
            <br />
            &emsp;&quot;message&quot;: &quot;Поле удалено!&quot;
            <br />
          {'}'}
        </code>
        <p>Возможные ошибки:</p>
        <code>
          {'{'}
            <br />
            &emsp;&quot;success&quot;: false,
            <br />
            &emsp;&quot;message&quot;: &quot;Поле не обнаружено!&quot;
            <br />
          {'}'}
        </code>
      </div>
      <div className='apiroute'>
      <h5>Прогноз макроэлементов и индексов</h5>
      <br />
        <p>Путь: https://smartagriculture.ektu.kz{process.env.REACT_APP_API_URL}map/indexes?apikey={apikey}</p>
        <p>Метод: POST</p>
        <p>Пример запроса:</p>
        <code>
          {'{'}
            <br />
            &emsp;&quot;id&quot;: 1, // Поле
            <br />
            &emsp;&quot;daterange&quot;: [&quot;2023-07-31T18:00:00.000Z&quot;, &quot;2023-08-30T18:00:00.000Z&quot;],
            <br />
            &emsp;&quot;cropType&quot;: &quot;maslo&quot;, // Тип культуры
            <br />
          {'}'}
        </code>
        <p>Типы культур:</p>
        <p>maslo: &quot;Масличные&quot;,</p>
        <p>zbob: &quot;Зернобобовые&quot;,</p>
        <p>zerno: &quot;Зерновые&quot;,</p>
        <p>kormo: &quot;Кормовые&quot;</p>
        <p>Пример ответа:</p>
        <code>
          {'{'}
          <br />
            &emsp;&quot;success&quot;: true,
            <br />
            &emsp;&quot;chems&quot;: [4.6840563, 250.72028, 45.703373, 7.177973] // гумус, N, K20, P2O5
            <br />
            &emsp;&quot;ndvi&quot;: 0.20402442169738513
            <br />
            &emsp;&quot;msavi&quot;: 0.09563572058911378
            <br />
          {'}'}
        </code>
        <p>Возможные ошибки:</p>
        <code>
          {'{'}
            <br />
            &emsp;&quot;success&quot;: false,
            <br />
            &emsp;&quot;message&quot;: &quot;Поле не обнаружено!&quot;
            <br />
          {'}'}
        </code>
        <br />
        <code>
          {'{'}
            <br />
            &emsp;&quot;success&quot;: false,
            <br />
            &emsp;&quot;message&quot;: &quot;Поле не принадлежит вашему аккаунту!&quot;
            <br />
          {'}'}
        </code>
      </div>
      <div className='apiroute'>
      <h5>Создание картограммы макроэлементов</h5>
      <br />
        <p>Путь: https://smartagriculture.ektu.kz{process.env.REACT_APP_API_URL}map/element_map?apikey={apikey}</p>
        <p>Метод: POST</p>
        <p>Пример запроса:</p>
        <code>
          {'{'}
            <br />
            &emsp;&quot;id&quot;: 1, // Поле
            <br />
            &emsp;&quot;chem&quot;: &quot;N&quot;, // N, K20, P2O5, humus
            <br />
            &emsp;&quot;value&quot;: 250.72028,
            <br />
          {'}'}
        </code>
        <p>Пример ответа:</p>
        <code>
          {'{'}
          <br />
            &emsp;&quot;success&quot;: true,
            <br />
            &emsp;&quot;image&quot;: {'{'}
            <br />
            &emsp;&emsp;&emsp;&quot;type&quot;: &quot;Buffer&quot;,
            <br />
            &emsp;&emsp;&emsp;&quot;data&quot;: [137, 80, 78, 71...66, 96, 130]
            <br />
            &emsp;{'}'}
            <br />
          {'}'}
        </code>
        <p>Возможные ошибки:</p>
        <code>
          {'{'}
            <br />
            &emsp;&quot;success&quot;: false,
            <br />
            &emsp;&quot;message&quot;: &quot;Поле не обнаружено!&quot;
            <br />
          {'}'}
        </code>
        <br />
        <code>
          {'{'}
            <br />
            &emsp;&quot;success&quot;: false,
            <br />
            &emsp;&quot;message&quot;: &quot;Поле не принадлежит вашему аккаунту!&quot;
            <br />
          {'}'}
        </code>
      </div>
      <div className='apiroute'>
      <h5>Идентификация культур на снимке</h5>
      <br />
        <p>Путь: https://smartagriculture.ektu.kz{process.env.REACT_APP_API_URL}map/identify_crop?apikey={apikey}</p>
        <p>Метод: POST</p>
        <p>Пример запроса:</p>
        <code>
          {'{'}
            <br />
            &emsp;&quot;image&quot;: (binary), // Изображение для идентификации
            <br />
          {'}'}
        </code>
        <p>Заголовки:</p>
        <code>
          {'{'}
          &apos;Content-Type&apos;: &apos;multipart/form-data&apos;
          {'}'}
        </code>
        <p>Пример ответа:</p>
        <code>
          {'{'}
          <br />
            &emsp;&quot;success&quot;: true,
            <br />
            &emsp;&quot;crop&quot;: &quot;Winter Wheat&quot;
            <br />
          {'}'}
        </code>
      </div>
      <div className='apiroute'>
      <h5>Запрос картограммы C3IS</h5>
      <br />
        <p>Путь: https://smartagriculture.ektu.kz{process.env.REACT_APP_API_URL}map/gee?apikey={apikey}</p>
        <p>Метод: POST</p>
        <p>Пример запроса:</p>
        <code>
          {'{'}
            <br />
            &emsp;&quot;admin0&quot;: &quot;Kazakhstan&quot;,
            <br />
            &emsp;&quot;admin1&quot;: &quot;East Kazakhstan Region&quot;,
            <br />
            &emsp;&quot;admin2&quot;: &quot;Бородулихинский район&quot;,
            <br />
            &emsp;&quot;type&quot;: &quot;EXPOSURE | Land Use Cover&quot;,
            <br />
            &emsp;&quot;resolution&quot;: 200
            <br />
          {'}'}
        </code>
        <p>Пример ответа:</p>
        <code>
          {'{'}
          <br />
            &emsp;&quot;success&quot;: true,
            <br />
            &emsp;&quot;image&quot;: &quot;https://earthengine.googleapis.com/v1/projects/earthengine-legacy/thumbnails/b853b325d04c45eff1094d74f0cc85b7-dce552fcc5a0157408051e017dbe5649:getPixels&quot;
            <br />
          {'}'}
        </code>
      </div>
    </div>

  )
}

export default APIref
