import L from 'leaflet'
import React, { useEffect, useRef, useState } from 'react'
// import 'leaflet/dist/leaflet.css'
import 'leaflet-imageoverlay-rotated'
import 'leaflet-toolbar'
// import 'leaflet-distortableimage'
import MapControls from './MapControls'
// import { SatImgContext } from '../contexts/DataProviderContext'
// import { SearchOptionsContext } from '../contexts/SearchDetailsContext'
import * as FA from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { GeoTIFF } from 'geotiff'
import { makeFileReaderSource } from 'geotiff/dist-module/source/filereader.js'
import Cookies from 'js-cookie'
import { useTranslation } from 'react-i18next'
import { Alert, Button, Form, Input, Loader, Panel, SelectPicker, Uploader } from 'rsuite'
import { deleteGeoJSON, deleteSatImage, downloadSatImage, getFieldMap, getGeoJSON, getImageEnhance, getIndexEnhance, getSatImages } from '../http/API'
import FieldsList from '../sidecomponents/sidewindows/FieldsList'
import SatImages from '../sidecomponents/sidewindows/SatImages'
import '../styles/sidenav.css'
import SatDisplay from '../sidecomponents/sidewindows/SatDisplay'
import MapTypes from '../sidecomponents/sidewindows/MapTypes'
import jwt_decode from 'jwt-decode'

const MapSat = () => {
  const [redImage, setRed] = useState()
  const [nirImage, setNir] = useState()
  const [origImage, setOrig] = useState()
  const [imageName, setName] = useState()
  const [imageName2, setName2] = useState()
  const [cropType, setCrop] = useState()
  const [recData, setData] = useState({})
  const [userRole, setUserRole] = useState()
  const [satImages, setSatImages] = useState([])
  const [imageLoading, setImLoading] = useState(false)
  function arrayBufferToBase64 (buffer) {
    let binary = ''
    const bytes = new Uint8Array(buffer)
    const len = bytes.byteLength
    for (let i = 0; i < len; i++) {
      binary += String.fromCharCode(bytes[i])
    }
    return window.btoa(binary)
  }
  useEffect(() => {
    const token = Cookies.get('token')
    console.log(token)
    try {
      setUserRole(token ? jwt_decode(token).role : undefined)
    } catch {
      Cookies.remove('token')
      window.location.reload()
    }
  }, [])
  const downloadIndexImage = async () => {
    //
    // mapRef.current.fitBounds([[bbox[0], bbox[1]], [bbox[2], bbox[3]]])
    if (imageName && nirImage && redImage && cropType) {
      const file = await GeoTIFF.fromSource(makeFileReaderSource(redImage))
      const image = await file.getImage()
      const bbox = image.getBoundingBox()
      const form = new FormData()
      const mul = 100000

      // mapRef.current.panTo([bbox[0], bbox[1]], 15)
      form.append('imageName', imageName)
      form.append('cropType', cropType)
      form.append('nirImage', nirImage, 'NIR.tiff')
      form.append('redImage', redImage, 'RED.tiff')
      form.append('bounds', bbox)
      setLoading(true)
      const resp = await getIndexEnhance(form).then(r => {
        setLoading(false)
        if (r.response.success) {
          const buffer = arrayBufferToBase64(r.response.image_ndvi.data)
          const src = 'data:image/png;base64,' + buffer
          const b = r.response.bounds
          // const bounds = [[bbox[1] / mul, bbox[0] / mul], [bbox[3] / mul, bbox[2] / mul]]
          const bounds = [[50.4340111804878, 81.4888164487805], [50.4340111804878 + (-bbox[1] + bbox[3]) / mul, 81.4888164487805 + (-bbox[0] + bbox[2]) / mul]]
          // const bounds = [[b.yMin, b.xMin], [b.yMax, b.xMax]]
          setData({
            NDVI_before: r.response.NDVI_before,
            NDVI_after: r.response.NDVI_after,
            MSAVI_before: r.response.MSAVI_before,
            MSAVI_after: r.response.MSAVI_after
          })
          drawImage(src, bounds)
          update(updateKey + 1)
        } else {
          Alert.error(r.response.message)
        }
      })
    } else {
      Alert.error('Убедитесь, что все поля были заполнены!')
    }
  }
  const downloadEnhancedImage = async () => {
    if (imageName2 && origImage) {
      const form = new FormData()
      form.append('imageName', imageName2)
      form.append('original', origImage, 'ORIGINAL.tiff')
      setLoading(true)
      const resp = await getImageEnhance(form).then(r => {
        setLoading(false)
        if (r.response.success) {
          Alert.success('Снимок успешно улучшен! Оригинальный и улучшенный снимок можно скачать из списка снимков (ORIGINAL и ENHANCED)')
          update(updateKey + 1)
        } else {
          Alert.error(r.response.message)
        }
      })
    } else {
      Alert.error('Убедитесь, что все поля были заполнены!')
    }
  }
  const drawImage = (image, bounds) => {
    setImage(image)
    setImageOnMap({ src: image, bounds })
    mapRef.current.fitBounds(bounds)
  }
  const downloadENC = (image, name) => {
    const url = image

    const link = document.createElement('a')
    link.href = url
    link.setAttribute('download', name)
    document.body.appendChild(link)
    link.click()
  }

  useEffect(async () => {
    console.log(userRole)
    const sat = userRole && await getSatImages().then(res => {
      if (res.response.success) {
        const images = res.response.images
        const imageslist = {}
        images.map(e => {
          if (!imageslist[e.name]) {
            imageslist[e.name] = {
              id: e.id,
              name: e.name,
              ndvi_1: e.ndvi_1,
              ndvi_2: e.ndvi_2,
              ndwi_1: e.ndwi_1,
              ndwi_2: e.ndwi_2,
              msavi_1: e.msavi_1,
              msavi_2: e.msavi_2,
              specters: [e.specter],
              croptype: e.croptype,
              res_orig: e.resolution
            }
            e.ndvi_2 && imageslist[e.name].specters.push('NDVI')
            e.ndwi_2 && imageslist[e.name].specters.push('NDWI')
            e.msavi_2 && imageslist[e.name].specters.push('MSAVI')
            false && imageslist[e.name].specters.push('ENHANCED')
          } else {
            imageslist[e.name].specters.push(e.specter)
            imageslist[e.name].res_enc = e.resolution
          }
        })

        setSatImages(imageslist)
      }
    })
  }, [updateKey, userRole])

  const downloadSpecter = async (name, type) => {
    setImLoading(true)
    const req = await downloadSatImage(name, type).then(res => {
      setImLoading(false)
      const ext = ['RED', 'NIR', 'GREEN', 'ORIGINAL', 'ENHANCED', 'ENHANCED_RED', 'ENHANCED_NIR'].includes(type) ? 'tiff' : 'png'
      const pre = ['RED', 'NIR', 'GREEN', 'ORIGINAL', 'ENHANCED', 'ENHANCED_RED', 'ENHANCED_NIR'].includes(type) ? 'data:image/tiff;base64,' : 'data:image/png;base64,'
      downloadENC(pre + res, `${name}_${type}.${ext}`)
    })
  }

  const deleteImage = async (name) => {
    if (!checker) {
      Alert.warning('Нажмите еще раз для подтверждения')
      sureness(true)
    } else {
      sureness(false)
      const req = await deleteSatImage(name).then(res => {
        if (res.response.success) {
          Alert.success(res.response.message)
          update(updateKey + 1)
        } else {
          Alert.error(res.response.message)
        }
      })
    }
  }

  const redUpload = async (e) => {
    if (e && e.length > 0) {
      const f = e[0].blobFile
      // setRed(Buffer.from(await f.arrayBuffer()))
      // const file = new GeoTIFF()
      // form.append('bounds', bbox)
      setRed(f)
    }
  }

  const nirUpload = async (e) => {
    if (e && e.length > 0) {
      const f = e[0].blobFile
      setNir(f)
    }
  }

  const origUpload = async (e) => {
    if (e && e.length > 0) {
      const f = e[0].blobFile
      setOrig(f)
    }
  }

  const crops = [
    {
      label: 'Масличные',
      value: 'maslo'
    },
    {
      label: 'Зернобобовые',
      value: 'zbob'
    },
    {
      label: 'Зерновые',
      value: 'zerno'
    },
    {
      label: 'Кормовые',
      value: 'kormo'
    }
  ]
  const dic = {
    maslo: 'Масличные',
    zbob: 'Зернобобовые',
    zerno: 'Зерновые',
    kormo: 'Кормовые'
  }
  const [curTheme, setTheme] = useState(Cookies.get('theme') === 'black' ? 'black' : 'white')
  const [jsonToSave, saveJSON] = useState('')
  const [markerJSON, setMarkerJSON] = useState('')
  const [jsonToDisplay, setJSON] = useState([])
  const [fieldToUpdate, setFieldToUpdate] = useState()
  const [placeList, setPlaces] = useState([])
  const [updateKey, update] = useState(0)
  const [chosenPlace, setCPlace] = useState()
  const [checker, sureness] = useState(false)
  const [satLoading, setLoading] = useState(false)
  const [image, setImage] = useState()
  const [selField, setField] = useState()
  const [mapType, setMap] = useState(Cookies.get('pref-map') || 'http://mt1.google.com/vt/lyrs=y&x={x}&y={y}&z={z}')
  function sortByProperty (property) {
    return function (a, b) {
      if (a[property] > b[property]) {
        return 1
      } else if (a[property] < b[property]) {
        return -1
      }
      return 0
    }
  }
  const [PlaceListShow, setPlaceListShow] = useState(!!Cookies.get('token'))
  const [ImageListShow, setImageListShow] = useState(false)
  const [SatListShow, setSatListShow] = useState(false)
  const [ImageUploadShow, setImageUploadShow] = useState(false)
  const [ImageENCListShow, setImageENCListShow] = useState(false)
  const [SatDisplayShow, setSatDisplayShow] = useState(!Cookies.get('token'))
  const [MapTypesShow, setMapTypesShow] = useState(false)

  const CloseOtherModals = () => {
    setPlaceListShow(false)
    setImageListShow(false)
    setSatListShow(false)
    setSatImagesShow(false)
    setImageENCListShow(false)
    setImageUploadShow(false)
    setSatDisplayShow(false)
    setMapTypesShow(false)
  }
  useEffect(async () => {
    const fieldsList = userRole && await getGeoJSON()
      .catch((e) => {
        if (String(e).includes('401')) {
          // Cookies.remove('token')
          window.location.href = '/map_main'
        }
      })
    if (userRole && fieldsList?.response?.success) {
      const f = fieldsList.response.fields.sort(sortByProperty('id'))
      setJSON(f)
      const p = new Set()
      f.forEach(e => {
        e.place && p.add(e?.place)
      })
      setPlaces([...p])
      try {
        if (updateKey === 0) {
          fitBounds(JSON.parse(fieldsList.response.fields[0].json))
        }
      } catch (e) {

      }
    }
    // showLayerRef.current.clearLayers()
  }, [updateKey, userRole])

  const deleteFields = async (field) => {
    if (!checker) {
      Alert.warning(t('warning.clickagain'))
      sureness(true)
    } else {
      sureness(false)
      try {
        let response
        const resp = await deleteGeoJSON(field).then((res) => {
          response = res.response
          if (response.success) {
            Alert.success(response.message)
            clearLayers()
            update(updateKey + 1)
          } else {
            Alert.error(response.message)
          }
        })
      } catch (e) {
        Alert.error(t('error.noconnection'))
      }
    }
  }
  const [FieldsListShow, setFieldsListShow] = useState(true)
  const [DataModalShow, setDataModalShow] = useState(false)
  const [updateModalShow, setUpdateModalShow] = useState(false)
  const [chartModalShow, setChartModalShow] = useState(false)
  const [SatImagesShow, setSatImagesShow] = useState(false)

  const [fieldMaster, setfieldMaster] = useState()
  const [fieldNameMaster, setfieldNameMaster] = useState()
  const [ImageOnMap, setImageOnMap] = useState()

  const { t, i18n } = useTranslation()
  // const { dataTiles, fetchDataTiles, activeTile, setActiveTile } = useContext(SatImgContext)
  // const { searchOptions, setSearchOptions, jsonLayers, newOrder, setNewOrder } = useContext(SearchOptionsContext)
  // const { navItemId, setNavItemId } = useContext(NavigationContext)
  const [isLoading, setIsLoading] = useState(false)

  const MAX_AREA = 6313063483978.778// in ha
  const footprintOptions = {
    stroke: true,
    // color: '#4185f4',
    color: '#E3AF34',
    weight: 3,
    opacity: 0.6,
    fill: true,
    fillColor: null,
    fillOpacity: 0,
    clickable: true
  }

  const mapRef = useRef(null) // map
  const paneRef = useRef(null) // tiles order on top
  const drawnLayerRef = useRef(null) // drawn footrpints - paths (lines) = featureGroup
  const markerRef = useRef(null) // drawn footrpints - paths (lines) = featureGroup
  const showLayerRef = useRef(null) // drawn footrpints - paths (lines) = featureGroup
  const imgLayerRef = useRef(null)
  const tileLayerRef = useRef(null) // tiles in tilelayer = featureGroup
  const searchGeometryRef = useRef(null) // search geometries on the map = featureGroup
  // const [maptype, changeMap] = useState('alidade_smooth_dark')
  let map

  function clearLayers () {
    if (mapRef?.current) {
      // if (mapRef.current.hasLayer(searchGeometryRef.current)) searchGeometryRef.current.clearLayers()
      if (mapRef.current.hasLayer(drawnLayerRef.current)) drawnLayerRef.current.clearLayers()
      // if (mapRef.current.hasLayer(markerRef.current)) markerRef.current.clearLayers()
      if (mapRef.current.hasLayer(tileLayerRef.current)) tileLayerRef.current.clearLayers()
    }
  }

  /** map initialization */
  useEffect(() => {
    try {
      map = L.map('map', {
        center: [49.945963, 82.613922],
        zoom: 10,
        minZoom: 1,
        zoomControl: false,
        attributionControl: false
      })
      L.tileLayer(mapType, {
        subdomains: ['mt0', 'mt1', 'mt2', 'mt3'],
        maxZoom: 18,
        id: 'baselayer'
      }).addTo(map)
      L.control.attribution({
        position: 'bottomright'
      }).addTo(map)
      // map.fitBounds([[47.12995075666307, 52.064208984375], [51.1807, 71.461]])

      // Searched geometries
      searchGeometryRef.current = L.featureGroup().addTo(map)
      showLayerRef.current = L.featureGroup().addTo(map)
      drawnLayerRef.current = L.featureGroup().addTo(map)
      tileLayerRef.current = L.featureGroup().addTo(map)
      imgLayerRef.current = L.featureGroup().addTo(map)
      mapRef.current = map
      // Pane for tiles topping on the map
      const topPane = mapRef?.current.createPane('leaflet-top-pane', mapRef.current.getPanes().mapPane)
      paneRef.current = topPane

      // Adding Controls
      MapControls(mapRef.current, t)
      mapRef.current.invalidateSize(9)
    } catch (e) {

    }
  }, [])
  useEffect(() => {
    // mapRef.current.removeLayer('baselayer')
    mapRef.current.attributionControl.setPrefix(Cookies.get('pref-attr'))
    L.tileLayer(mapType, {
      maxZoom: 18,
      id: 'baselayer'
    }).addTo(mapRef.current)
  }, [mapType])
  useEffect(() => {
    searchGeometryRef.current.clearLayers()
    const myStyle = {
      fillOpacity: 0
    }
    jsonToDisplay.forEach((f) => {
      const mask = L.geoJSON(JSON.parse(f.json), myStyle)
      const name = JSON.parse(f.json).properties.name
      const area = JSON.parse(f.json).properties.area

      if (JSON.parse(f.json).geometry?.type === 'Point') {
        mask.bindTooltip(() => name, { permanent: true })
        mask.addEventListener('mouseover', () => {
          mask.unbindTooltip()
          const time = new Date(f.createdAt)
          mask.bindTooltip(() => `Создано: ${time.toLocaleString('ru-RU')}`, { permanent: true })
        })
        mask.addEventListener('mouseout', () => {
          mask.unbindTooltip()
          mask.bindTooltip(() => name, { permanent: true })
        })
      } else {
        mask.bindTooltip(() => name + ` (${(area / 10000).toFixed(3)} га)`)
        mask.addEventListener('click', () => (window.innerWidth > 840 && setfieldMaster(f.id)))
        // mask.addEventListener('dblclick', () => {
        //   if (window.innerWidth > 840) {
        //     fitBounds(JSON.parse(f.json))
        //     CloseOtherModals()
        //     setDataModalShow(true)
        //     setFieldsListShow(true)
        //     setfieldMaster(f.id)
        //   } else {
        //     window.open(`/datasave/${f.id}`, '_blank')
        //   }
        // })
      }
      mask.addEventListener('mouseover', () => (mask.openTooltip()))
      searchGeometryRef.current.addLayer(mask)
    })
  }, [jsonToDisplay])
  /** Handling drawing on map */
  const handleDrawCreated = React.useCallback(e => {
    clearLayers()
    const layer = e.layer

    if (e.layerType === 'marker') {
      CloseOtherModals()
      const gjson = layer.toGeoJSON()
      gjson.properties.name = '???'
      setMarkerJSON(JSON.stringify(gjson))
      drawnLayerRef.current.addLayer(layer)
    } else {
      const area = L.GeometryUtil.geodesicArea(layer.getLatLngs()[0])
      const gjson = layer.toGeoJSON()
      gjson.properties = {
        name: 'test',
        area: area
      }
      saveJSON(JSON.stringify(gjson))
      if (area <= MAX_AREA) {
        // setSearchLayer(() => layer.toGeoJSON())
      } else {
        Alert.info(t('errors.bigarea'), 5000)
      }
      drawnLayerRef.current.addLayer(layer)
    }
  }, [])
  useEffect(() => {
    // mapRef.current.on('draw:created', function (e) {
    //   // const type = e.layerType
    //   const layer = e.layer
    //   setSearchLayer(layer.toGeoJSON())
    //   searchGeometryRef.current.clearLayers()
    //   searchGeometryRef.current.addLayer(e.layer)
    // })
    mapRef.current.on('draw:created', handleDrawCreated)
  }, [handleDrawCreated])

  useEffect(() => {
    if (ImageOnMap) {
      // searchGeometryRef.current.clearLayers()
      const myStyle = {
        fillOpacity: 0
      }

      // const jsonDisp = L.geoJSON(JSON.parse(ImageOnMap.json), myStyle)
      // jsonDisp.bindTooltip('NDVI = 0.32', { sticky: true })
      // jsonDisp.addEventListener('mouseover', () => (im.openTooltip()))
      // searchGeometryRef.current.addLayer(jsonDisp)
      const im = L.imageOverlay(ImageOnMap.src, ImageOnMap.bounds).addTo(mapRef.current)
      // im.bindTooltip('NDVI = 0.46', { sticky: true })
      // im.addEventListener('mouseover', () => (im.openTooltip()))
    }
  }, [ImageOnMap])

  function arrayBufferToBase64 (buffer) {
    let binary = ''
    const bytes = new Uint8Array(buffer)
    const len = bytes.byteLength
    for (let i = 0; i < len; i++) {
      binary += String.fromCharCode(bytes[i])
    }
    return window.btoa(binary)
  }
  const downloadImage = async (id, json, daterange, cloudiness) => {
    setLoading(true)
    const resp = await getFieldMap({ id, daterange, cloudiness }).then(r => {
      if (r.response.success) {
        const buffer = arrayBufferToBase64(r.response.buffer.data)
        const src = 'data:image/png;base64,' + buffer
        setImage(src)
        const b = r.response.bounds
        const bounds = [[b.yMin, b.xMin], [b.yMax, b.xMax]]

        setLoading(false)
        setImageOnMap({ src, bounds, json })
        mapRef.current.fitBounds(bounds)
      }
    })
  }
  const download = () => {
    if (image) {
      const url = image
      const link = document.createElement('a')
      link.href = url
      link.setAttribute('download', 'NDVI.png') // or any other extension
      document.body.appendChild(link)
      link.click()
    } else {
      Alert.error('Сначала загрузите картограмму NDVI для необходимого поля!')
    }
  }

  const fitBounds = (field) => {
    const mask = L.geoJSON(field)

    if (field.geometry?.type === 'Point') {
      const lat = field.geometry.coordinates[1]
      const lng = field.geometry.coordinates[0]
      mapRef.current.panTo([lat, lng], 10)
    } else {
      mapRef.current.fitBounds(mask.getLayers()[0].getLatLngs())
    }
  }
  return (
    <>
      <div className='navbar-side-wrapper'>
        <nav className='navbar-side-new'>
          <button className='new-link-element'>
            <img src='/logo192.png' alt='' width='40px' />
          </button>
          { userRole && <>
          <button className={`new-link-element ${PlaceListShow ? 'dropdown-chosen' : ''}`} onClick={() => { CloseOtherModals(); setPlaceListShow(!PlaceListShow) }} title={t('map.sidebar.places')}>
            <FontAwesomeIcon icon={FA.faListAlt} />
            <h5>{t('map.sidebar.places')}</h5>
          </button>
          <button className={`new-link-element ${ImageListShow ? 'dropdown-chosen' : ''}`} onClick={() => { CloseOtherModals(); setImageListShow(!ImageListShow) }} title={t('map.sidebar.images')}>
            <FontAwesomeIcon icon={FA.faLayerGroup} />
            <h5>{t('map.sidebar.images')}</h5>
          </button>
          </>}
          <button className={`new-link-element ${SatListShow ? 'dropdown-chosen' : ''}`} onClick={() => { CloseOtherModals(); setSatListShow(!SatListShow) }} title={t('map.sidebar.sources')}>
            <FontAwesomeIcon icon={FA.faSatellite} />
            <h5>{t('map.sidebar.sources')}</h5>
          </button>
          <button className={`new-link-element ${SatDisplayShow ? 'dropdown-chosen' : ''}`} onClick={() => { CloseOtherModals(); setSatDisplayShow(!SatDisplayShow) }} title='Актуализация косимческих снимков'>
            <FontAwesomeIcon icon={FA.faMapMarkedAlt} />
            <h5>Актуализация косимческих снимков</h5>
          </button>
          <button
          className={`new-link-element ${MapTypesShow ? 'dropdown-chosen' : ''}`} onClick={() => {
            CloseOtherModals()
            setMapTypesShow(!MapTypesShow)
          }} title={t('map.sidebar.tile')}
        >
          <FontAwesomeIcon icon={FA.faLayerGroup} />
          <h5>{t('map.sidebar.tile')}</h5>
        </button>
        { userRole && <>
          <button className={`new-link-element ${SatImagesShow ? 'dropdown-chosen' : ''}`} onClick={() => { CloseOtherModals(); setSatImagesShow(!SatImagesShow) }} title={t('map.sidebar.search')}>
            <FontAwesomeIcon icon={FA.faImages} />
            <h5>{t('map.sidebar.search')}</h5>
          </button>
          <button className={`new-link-element ${ImageUploadShow ? 'dropdown-chosen' : ''}`} onClick={() => { CloseOtherModals(); setImageUploadShow(!ImageUploadShow) }} title={t('map.sidebar.upload_image')}>
            <FontAwesomeIcon icon={FA.faSatellite} />
            <h5>{t('map.sidebar.upload_image')}</h5>
          </button>
          <button className={`new-link-element ${ImageENCListShow ? 'dropdown-chosen' : ''}`} onClick={() => { CloseOtherModals(); setImageENCListShow(!ImageENCListShow) }} title={t('map.sidebar.uploaded_images')}>
            <FontAwesomeIcon icon={FA.faHistory} />
            <h5>{t('map.sidebar.uploaded_images')}</h5>
          </button>
        </>}
          <button className='new-link-element' onClick={() => i18n.changeLanguage(i18n.language === 'ru' ? 'kz' : i18n.language === 'kz' ? 'en' : 'ru')} title='Смена языка'>
            <FontAwesomeIcon icon={FA.faGlobe} />
            <h5>
              {t('map.sidebar.lang')} {i18n.language.toUpperCase()}
            </h5>
          </button>
          <button onClick={() => { window.location.href = '/profile' }} className='new-link-element' title={t('common.return')}>
            <FontAwesomeIcon icon={FA.faBackward} />
            <h5>{t('common.return')}</h5>
          </button>
        </nav>
        <nav className='dropdown-content new-side' hidden={!PlaceListShow}>
          <h3>{t('map.sidebar.places')}:</h3>
          <br />
          {
            placeList && placeList.map((e, key) => (
              <h4 style={{ width: '90%', marginLeft: '10px', textAlign: 'center' }} key={key} className='field-list' onClick={() => { setCPlace(e); CloseOtherModals(); setImageListShow(true) }}>{e}</h4>
            ))
          }
          {/* <a href='/profile'><button id='loadbutton'>В меню профиля</button></a> */}
        </nav>
        <nav className='dropdown-content' hidden={!SatListShow}>
          <h5>{t('map.sidebar.sources')}:</h5>
          <br />
          <ul>
            <li>Sentinel-2</li>
            <li>LandSat-8</li>
            <li>KazEOSat-1</li>
            <li>KazEOSat-2</li>
          </ul>
        </nav>
        <div hidden={!ImageListShow}>
          {userRole && <FieldsList layer={showLayerRef.current} openEditor={setUpdateModalShow} openCharts={setChartModalShow} setFieldToUpdate={setFieldToUpdate} hideFunc={setImageListShow} showData={setDataModalShow} setFieldMaster={setfieldMaster} setFieldNameMaster={setfieldNameMaster} deleteFields={deleteFields} fitBounds={fitBounds} fields={jsonToDisplay} onHide={() => setFieldsListShow(false)} adClass={(!FieldsListShow) ? 'hidden' : ''} notesModal={false} fieldsModal={FieldsListShow} chosenPlace={chosenPlace} downloadImage={downloadImage} satLoading={satLoading} setLoading={setLoading} setField={setField} setSatImagesShow={setSatImagesShow} />}
          <button id='downloadbutton' onClick={download}>{t('map.sidebar.download_image')}</button>
        </div>
        <div hidden={!SatImagesShow}>
          {userRole && <SatImages selField={selField} downloadImage={downloadImage} fieldName={fieldNameMaster} image={image} download={download} loading={satLoading} /> }
        </div>
        <MapTypes adClass={!MapTypesShow ? 'hidden' : ''} slave setMap={setMap} hideFunc={setMapTypesShow} />
        <div hidden={!SatDisplayShow}>
          <SatDisplay selField={selField} layerRef={imgLayerRef} mapRef = {mapRef}/>
        </div>
        <div className='dropdown-content new-side' hidden={!ImageUploadShow}>
          <Panel bordered>
            <h3>{t('map.sidebar.upload_image_alt')}</h3>
            <br />
            <h5>{t('map.sidebar.enhance.title')}</h5>
            <br />
            <Form id='image-form'>
              <Uploader accept='.tif, .tiff' type='file' onChange={redUpload} autoUpload={false} toggleComponentClass={Button}><Button>Снимок RED</Button></Uploader>
              <br />
              <Uploader accept='.tif, .tiff' type='file' onChange={nirUpload} autoUpload={false} toggleComponentClass={Button}><Button>Снимок NIR</Button></Uploader>
              <br />
              <Input onChange={(e) => setName(e)} placeholder={t('map.sidebar.enhance.name')} />
              <br />
              <SelectPicker onChange={(e) => setCrop(e)} data={crops} placeholder={t('map.sidebar.enhance.crop')} />
            </Form>
            <br />
            <Button onClick={downloadIndexImage} loading={satLoading}>{t('map.sidebar.enhance.upload')}</Button>
          </Panel>
          <br />
          {/* <Panel bordered>
        <h5>Улучшение снимка</h5>
        <br />
        <Input onChange={(e) => setName2(e)} placeholder='Название снимка'/>
        <br />
        <Uploader accept='.tif, .tiff' type='file' onChange={origUpload} autoUpload={false} toggleComponentClass={Button}><Button>Снимок для улучшения</Button></Uploader>
        <br />
        <Button onClick={downloadEnhancedImage} loading={satLoading}>Загрузить снимок</Button>
        </Panel> */}
        </div>
        <nav className='dropdown-content new-side-right-bottom-new' hidden={!ImageENCListShow}>
          <Panel bordered>
            <h3 style={{ textAlign: 'center' }}>{t('map.sidebar.images')}</h3>
            <div id='sat-list'>
              {
          satImages && Object.keys(satImages).map((e, key) => {
            const s = satImages[e]
            return (
              <div key={key} className='sat-tile'>
                <h4>{s.name}</h4>
                <p>{t('map.sidebar.enhanced.list')} {s.specters.join(', ')}</p>
                {s.ndvi_2 && <><p>{t('map.sidebar.enhanced.ndvi')} {t('map.sidebar.enhanced.before')} {s.ndvi_1}</p>
                  <p>{t('map.sidebar.enhanced.ndvi')} {} {t('map.sidebar.enhanced.after')} {s.ndvi_2}</p>
                </>}
                {s.ndwi_2 && <><p>{t('map.sidebar.enhanced.ndwi')} {} {t('map.sidebar.enhanced.before')} {s.ndwi_1}</p>
                  <p>{t('map.sidebar.enhanced.ndwi')} {} {t('map.sidebar.enhanced.after')} {s.ndwi_2}</p>
                </>}
                {s.msavi_2 && <><p>{t('map.sidebar.enhanced.msavi')} {} {t('map.sidebar.enhanced.before')} {s.msavi_1}</p>
                  <p>{t('map.sidebar.enhanced.msavi')} {} {t('map.sidebar.enhanced.after')} {s.msavi_2}</p>
                </>}
                {s.croptype && <p>{t('map.sidebar.enhance.crop')}: {dic[s.croptype]}</p>}
                {s.res_orig && <p>{t('map.sidebar.enhanced.res_before')} {s.res_orig}</p>}
                {s.res_enc && <p>{t('map.sidebar.enhanced.res_after')} {s.res_enc}</p>}
                <p>{t('common.download')} </p>
                <div className='mapenc-download-buttons'>
                  {
              s.specters.map((j, key) => <Button key={key + 200} loading={imageLoading} onClick={() => downloadSpecter(e, j)}>{j}</Button>)
            }
                </div>
                <br />
                <br />
                <Button color='red' onClick={() => deleteImage(s.name)}>{t('sidemenu.delete')}</Button>
              </div>
            )
          })
        }
            </div>
          </Panel>
        </nav>
      </div>
      <div id='map'>
        {isLoading && <Loader inverse size='md' center content='loading' className='z-600' />}
      </div>
    </>
  )
}

export default MapSat
