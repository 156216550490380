import { useState, useEffect } from 'react'
import { Alert, Button, DateRangePicker, Input, Panel, Slider, Tooltip, Whisper } from 'rsuite'
import L from 'leaflet'
import { useTranslation } from 'react-i18next'
import { fromUrl } from 'geotiff'

function SatDisplay ({ adClass, layerRef, mapRef }) {
  const { t } = useTranslation()
  const satData = [
    {
      sat: 'Harmonized Landsat Sentinel (HLS)',
      name: 'hls',
      imgs: [
        '2023-05-07',
        '2023-06-01',
        '2023-07-18',
        '2023-08-20',
        '2023-10-06'
      ]
    },
    {
      sat: 'Landsat 8-9 L2',
      name: 'landsat',
      imgs: [
        '2023-05-31',
        '2023-06-08',
        '2023-07-02',
        '2023-08-19',
        '2023-10-06'
      ]
    },
    {
      sat: 'MODIS Terra',
      name: 'modis',
      imgs: [
        '2023-05-17',
        '2023-06-17',
        '2023-07-17',
        '2023-08-17',
        '2023-09-17'
      ]
    },
    {
      sat: 'Sentinel-2 L2A',
      name: 'sentinel',
      imgs: [
        '2023-05-22',
        '2023-06-06',
        '2023-07-26',
        '2023-08-20',
        '2023-09-09'
      ]
    }
  ]
  const ESPGConvert = (lat, lon) => {
    const e = 2.7182818284
    const X = 20037508.34
    const long4326 = (lon * 180) / X
    let lat4326 = lat / (X / 180)
    const exponent = (Math.PI / 180) * lat4326
    lat4326 = Math.atan(e ** exponent)
    lat4326 = lat4326 / (Math.PI / 360)
    lat4326 = lat4326 - 90
    return [lat4326, long4326]
  }

  const showImage = async (sat, date) => {
    layerRef.current.clearLayers()
    const link = `/sat/${sat}/${date}`
    const test = 'https://stuartmatthews.github.io/leaflet-geotiff/tif/wind_direction.tif'
    const ffs = await fromUrl(link + '.tiff').then(async (tiff) => {
      const image = await tiff.getImage()
      const bbox = image.getBoundingBox()
      const bounds = [ESPGConvert(bbox[1], bbox[0]), ESPGConvert(bbox[3], bbox[2])]
      mapRef.current.fitBounds(bounds)
      const layer = L.imageOverlay(link + '.png', bounds).addTo(layerRef.current)
    })
  }

  return (
    <div className={`dropdown-content ${adClass}`}>
      <Panel bordered>
        <h3>Доступные снимки:</h3>
        {
          satData.map((e, key) => <div key={key * 11 + 1}>
            <h5>{e.sat}</h5>
            <ul>
              {e.imgs.map((k, key2) => <li style={{ cursor: 'pointer', color: 'blue' }} onClick={() => showImage(e.name, k)} key={key2 * 13 + key}><h6>{k}</h6></li>)}
            </ul>
          </div>)
        }
        {/* <Button onClick={console.log()}>Скачать снимок</Button> */}
      </Panel>
    </div>
  )
}

export default SatDisplay
