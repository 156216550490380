import React, { useState, useEffect } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import * as FA from '@fortawesome/free-solid-svg-icons'
import { getUsers, getKeys, generateRegKey, deactivateKey, getMessages, removeMessage } from '../http/API'
import { Input, Alert, Button, ControlLabel } from 'rsuite'
import Cookies from 'js-cookie'
import jwtDecode from 'jwt-decode'
import { useTranslation } from 'react-i18next'

const AdminSettings = () => {
  const { t, i18n } = useTranslation()
  const [data, setData] = useState({
    role: '',
    userid: jwtDecode(Cookies.get('token')).id,
    key: ''
  })
  const [updateKey, update] = useState(0)
  const [rkeys, setRKeys] = useState([])
  const [users, setUserlist] = useState([])
  const [messages, setMessageslist] = useState([])
  useEffect(async () => {
    const rkeyslist = await getKeys()
    if (rkeyslist.response.success) {
      setRKeys(rkeyslist.response.fields)
    }
    const usersList = await getUsers()
    if (usersList.response.success) {
      setUserlist(usersList.response.users)
    }
    const messagesList = await getMessages()

    if (messagesList.response.success) {
      setMessageslist(messagesList.response.messages)
    }
  }, [updateKey])
  const newKey = async () => {
    try {
      if (document.getElementById('key').value !== '' && document.getElementById('role').value !== '') {
        const resp = await generateRegKey(data).then((res) => {
          const response = res.response
          if (response.success) {
            Alert.success(response.message)
            update(updateKey + 1)
          } else {
            Alert.error(response.message)
          }
        })
      } else {
        Alert.error(t('error.adminkey'))
      }
    } catch (e) {
      Alert.error(t('error.connectionerror'))
    }
  }
  const deleteMessage = async (id) => {
    try {
      const resp = await removeMessage(id).then((res) => {
        const response = res.response
        if (response.success) {
          Alert.success(response.message)
          update(updateKey + 1)
        } else {
          Alert.error(response.message)
        }
      })
    } catch (e) {
      Alert.error(t('error.connectionerror'))
    }
  }
  const deactivate = async (e) => {
    try {
      const resp = await deactivateKey(e).then((res) => {
        const response = res.response
        if (response.success) {
          Alert.success(response.message)
          update(updateKey + 1)
        } else {
          Alert.error(response.message)
        }
      })
    } catch (e) {
      Alert.error(t('error.connectionerror'))
    }
  }
  return (
    <div className='profile-wrapper'>
      <h3>{t('adminpanel.title')}</h3>
      <hr />
      <h2>{t('adminpanel.regkey.title')}</h2>
      <br />
      <div style={{ display: 'flex', justifyContent: 'space-around' }}>
        <Input id='key' style={{ width: '30%', float: 'left' }} placeholder={t('adminpanel.regkey.placeholder')} onChange={e => setData({ ...data, key: e })} />
        <button className='button-subscribe' id='btn-confirm' style={{ width: '20%', float: 'center' }} onClick={newKey}>{t('adminpanel.regkey.button')}</button>
        <Input
          id='role' list='roles' style={{ width: '30%' }} placeholder={t('adminpanel.role.placeholder')} onChange={e => {
            setData({ ...data, role: e })
          }}
        />
        <datalist id='roles'>
          <option value='ADMIN'>{t('common.usertype.admin')}</option>
          <option value='USER'>{t('common.usertype.user')}</option>
        </datalist>
      </div>
      <br />
      <hr />
      <h2>{t('adminpanel.regkey.list')} </h2>
      <br />
      <div id='regkeys-list'>
        {rkeys.length === 0
          ? t('adminpanel.nokeys')
          : rkeys.map((f, key) => {
            return (
              <div
                key={key} style={{ cursor: 'default', background: f.used ? 'rgb(255, 163, 163)' : 'rgb(186, 255, 173)' }} onClick={() => {
                  navigator.clipboard.writeText(f.key)
                  Alert.success(`Ключ ${f.key} скопирован!`)
                }}
              >
                <h5>{t('common.key')} ({f.keytype === 'ADMIN' ? t('common.usertype.admin_adj') : t('common.usertype.user_adj')})</h5>
                <h5>&quot;{f.key}&quot;</h5>
                <h5>({f.used ? 'Неактивен' : 'Активен'})</h5>
                <h5>{t('adminpanel.regkey.createdby')} {f.email} ({f.name}),</h5>
                <h5>{f.updatedAt.replace('T', ' ').slice(0, -8)}</h5>
                {!f.used &&
                  <Button style={{ width: 'fit-content', float: 'right', fontSize: '5px', padding: '1px' }} onClick={() => deactivate(f.key)}><FontAwesomeIcon icon={FA.faMinus} /></Button>}
              </div>
            )
          })}
      </div>
      <hr />
      <h2>{t('adminpanel.users.title')}</h2>
      {users?.length === 0
        ? t('adminpanel.users.notfound')
        : users?.map((f, key) => {
          return (
            <div id='user' key={key} style={{ width: '40%', margin: '5px' }}>
              <h4>Пользователь {f.first_name} {f.last_name} </h4>
              <br />
              <br />
              <div className='user-tile'>
                <img src='img/favicon.png' height={100} />
                <div>
                  <h5>ID: {f.id}</h5>
                  <h5>Имя: {f.first_name}</h5>
                  <h5>Фамилия: {f.last_name}</h5>
                  <h5>{t('adminpanel.role.placeholder')}: {f.role}</h5>
                  <h5>{t('common.email')}: {f.email}</h5>
                  <h5>Хозяйство: {f.place}</h5>
                  <h5>Зарегистрирован: {new Date(f.createdAt).toLocaleDateString('ru-RU')}</h5>
                  <br />
                </div>
              </div>
            </div>
          )
        })}
      <hr />
      <h2>Сообщения обратной связи: </h2>
      <div id='messages-list'>
        {messages?.length === 0
          ? 'Сообщений пока нет!'
          : messages?.map((f, key) => {
            return (
              <div className='message' key={key} style={{ margin: '5px' }}>
                <h5>{f.name} пишет:</h5>
                <br />
                <p>{f.message}</p>
                <hr />
                <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                  <h6>Связаться: <a href={'tel:' + f.phone}>{f.phone}</a> / <a href={'mailto:' + f.email}>{f.email}</a></h6>
                  <button onClick={() => deleteMessage(f.id)}>Удалить сообщение</button>
                </div>
              </div>
            )
          })}
      </div>
    </div>
  )
}

export default AdminSettings
