import React, { useEffect, useState } from 'react'
import jwt_decode from 'jwt-decode'
import Cookies from 'js-cookie'
import { FlexboxGrid, Modal, Form, Tooltip, Input, Button, Whisper, Alert } from 'rsuite'
import { activate } from '../http/API'
import { useTranslation } from 'react-i18next'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import * as FA from '@fortawesome/free-solid-svg-icons'
import { Link } from 'react-router-dom'

const InfoList = () => {
  const { t, i18n } = useTranslation()
  return (
    <>
      <div className='profile-wrapper'>
        <div className='header__logo' id='logo'>
          <div>
            <img className='header__img' style={{ height: '80px' }} src='./img/favicon.png' alt='' />
            <img className='header__little-img' src='./img/little_icon.svg' alt='' />
          </div>
          <div className='header__name'>
            <div className='header__name-agro'>Smart</div>
            <div className='header__name-bot'>Agronom</div>
          </div>
        </div>
        <div className='catalog-profile'>
          <Link className='product-profile' to='/profile'>
            <div style={{ display: 'flex' }}>
              {/* <div className="product__icon-profile product__icon_seeds"></div> */}
              <FontAwesomeIcon icon={FA.faBackward} className='fa-icon-profile' />
              <div className='product__info-profile'>
                <div className='product__title'>Назад</div>
                <div className='product__description-profile' />
              </div>
            </div>
            <img src='./img/arrow.svg' alt='arrow' />
          </Link>
          <Link className='product-profile' to='/info'>
            <div style={{ display: 'flex' }}>
              {/* <div className="product__icon-profile product__icon_branch"></div> */}
              <FontAwesomeIcon icon={FA.faMap} className='fa-icon-profile' />
              <div className='product__info-profile'>
                <div className='product__title'>Справочник по вредителям и болезням</div>
                <div className='product__description-profile'>{t('profile.subtext')}</div>
              </div>
            </div>
            <img src='./img/arrow.svg' alt='arrow' />
          </Link>
          <Link className='product-profile' to='/infospecial'>
            <div style={{ display: 'flex' }}>
              {/* <div className="product__icon-profile product__icon_lightning"></div> */}
              <FontAwesomeIcon icon={FA.faSatellite} className='fa-icon-profile' />
              <div className='product__info-profile'>
                <div className='product__title'>Справочник по фазам роста ячменя</div>
                <div className='product__description-profile'>{t('profile.subtext')}</div>
              </div>
            </div>
            <img src='./img/arrow.svg' alt='arrow' />
          </Link>
          <Link className='product-profile' to='/info_pest'>
            <div style={{ display: 'flex' }}>
              {/* <div className="product__icon-profile product__icon_tractor"></div> */}
              <FontAwesomeIcon icon={FA.faTractor} className='fa-icon-profile' />
              <div className='product__info-profile'>
                <div className='product__title'>Справочник по пестицидам</div>
                <div className='product__description-profile'>{t('profile.subtext')}</div>
              </div>
            </div>
            <img src='./img/arrow.svg' alt='arrow' />
          </Link>
        </div>
      </div>
    </>
  )
}

export default InfoList
