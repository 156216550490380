function AttributeList ({ adClass }) {
  return (
    <div className={`dropdown-content ${adClass}`}>
      <menu className='dropdown-lister'>
        <div>
          <div><input id='streets-v11' type='radio' value='streets' name='rtoggle' /><label htmlFor='streets-v11'>&nbsp;Спутник 1</label></div>
          <div><input id='light-v10' type='radio' name='rtoggle' value='light' /><label htmlFor='light-v10'>&nbsp;Белый</label></div>
          <div><input id='dark-v10' defaultChecked type='radio' name='rtoggle' value='dark' /><label htmlFor='dark-v10'>&nbsp;Черный</label></div>
          <div><input id='outdoors-v11' type='radio' name='rtoggle' value='outdoors' /><label htmlFor='outdoors-v11'>&nbsp;Улицы</label></div>
          <div><input id='satellite-v9' type='radio' name='rtoggle' value='satellite' /><label htmlFor='satellite-v9'>&nbsp;Спутник 2</label></div>
        </div>
      </menu>
    </div>
  )
}

export default AttributeList
