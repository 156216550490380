import { useState, useEffect, useCallback } from 'react'
import { Alert, AutoComplete, Button, DateRangePicker, Input, Panel, SelectPicker, Slider, Tooltip, Whisper } from 'rsuite'
import { useTranslation } from 'react-i18next'
import { getElementData, getElementsMap } from '../../http/API'
import Cookies from 'js-cookie'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import * as FA from '@fortawesome/free-solid-svg-icons'

function MacroSaved ({ adClass, ukey, update, fieldsList }) {
  const { t } = useTranslation()
  const [data, setData] = useState({})
  const [loading, setLoading] = useState(false)

  const {
    afterToday
  } = DateRangePicker
  const chems = {
    humus: t('common.humus'),
    N: t('common.nitro'),
    K2O: t('common.potass'),
    P2O5: t('common.phos')
  }

  useEffect(async () => {
    await getElementData().then(res => {
      if (res.response.success) {
        setData(res.response.data.reverse())
      } else {
        Alert.error(res.response.message)
      }
    })
  }, [ukey])

  function arrayBufferToBase64 (buffer) {
    let binary = ''
    const bytes = new Uint8Array(buffer)
    const len = bytes.byteLength
    for (let i = 0; i < len; i++) {
      binary += String.fromCharCode(bytes[i])
    }
    return window.btoa(binary)
  }
  const downloadIndexImage = async (id, element) => {
    Alert.warning(t('mc.timewarn'))
    setLoading(true)
    const resp = await getElementsMap({ orID: id, chem: element }).then(r => {
      setLoading(false)
      if (r.response.success) {
        update(ukey + 1)
        const buffer = arrayBufferToBase64(r.response.image.data)
        const src = 'data:image/png;base64,' + buffer
        download(src, `${r.response.name}-${Cookies.get('place')}-${chems[element]}-${r.response.date}.png`)
      } else {
        Alert.error(r.response.message)
      }
    })
  }
  const download = (image, name) => {
    const url = image

    const link = document.createElement('a')
    link.href = url
    link.setAttribute('download', name)
    document.body.appendChild(link)
    link.click()
  }
  return (
    <div className={`datapoints-list ${adClass}`}>
      <Panel>
        <h4>{t('mc.maps')}</h4>
        <div>
        {
          data && data.length > 0 && fieldsList?.length > 0 && data?.map((e, key) => {
            const fld = fieldsList.filter(f => e.field_id === f.id)[0]
            if (!fld) return
            const fieldName = JSON.parse(fld.json).properties.name
            return (
              <Panel bordered key = {(key + 2) * 13}>
                <p>{t('mc.mprop.field')} {fieldName || t('mc.prop.unknown')}</p>
                <p>{t('mc.mprop.date')} {e.date}</p>
                <p>{t('mc.prop.createdat')} {new Date(e.createdAt).toLocaleString('ru')}</p>
                <p>NDVI: {e.NDVI}</p>
                <p>MSAVI: {e.MSAVI}</p>
                <p>{t('mc.prop.humus')} {e.humus}</p>
                <p>N: {e.nitrogen}</p>
                <p>K2O: {e.potassium}</p>
                <p>P2O5: {e.phosphorus}</p>
                <p>{t('profile.map')} {} {t('mc.forhumus')} {!loading ? <Button color={e.mapHumus ? 'green' : 'yellow'} onClick={() => downloadIndexImage(e.id, 'humus')}>{e.mapHumus ? t('common.download') : t('adminpanel.regkey.button')}</Button> : <Button><FontAwesomeIcon icon={FA.faSpinner} spin /></Button>}</p>
                <p>{t('profile.map')} {} N: {!loading ? <Button color={e.mapNitrogen ? 'green' : 'yellow'} onClick={() => downloadIndexImage(e.id, 'N')}>{e.mapNitrogen ? t('common.download') : t('adminpanel.regkey.button')}</Button> : <Button><FontAwesomeIcon icon={FA.faSpinner} spin /></Button>}</p>
                <p>{t('profile.map')} {} K2O: {!loading ? <Button color={e.mapPotassium ? 'green' : 'yellow'} onClick={() => downloadIndexImage(e.id, 'K2O')}>{e.mapPotassium ? t('common.download') : t('adminpanel.regkey.button')}</Button> : <Button><FontAwesomeIcon icon={FA.faSpinner} spin /></Button>}</p>
                <p>{t('profile.map')} {} P2O5: {!loading ? <Button color={e.mapPhosphorus ? 'green' : 'yellow'} onClick={() => downloadIndexImage(e.id, 'P2O5')}>{e.mapPhosphorus ? t('common.download') : t('adminpanel.regkey.button')}</Button> : <Button><FontAwesomeIcon icon={FA.faSpinner} spin /></Button>}</p>
                </Panel>
            )
          })
        }
        </div>
      </Panel>

    </div>
  )
}

export default MacroSaved
