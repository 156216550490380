import React, { useEffect, useState } from 'react'
import jwt_decode from 'jwt-decode'
import Cookies from 'js-cookie'
import { FlexboxGrid, Modal, Form, Tooltip, Input, Button, Whisper, Alert } from 'rsuite'
import { activate } from '../http/API'
import { useTranslation } from 'react-i18next'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import * as FA from '@fortawesome/free-solid-svg-icons'
import { Link } from 'react-router-dom'

const Profile = () => {
  const { t, i18n } = useTranslation()
  const [regkey, setRKey] = React.useState()
  // const [modalShow, setModalShow] = React.useState(!userRole)
  const [regmodalShow, setRegModalShow] = React.useState(false)
  // const [quickData, setData] = useState()
  // const [updateKey, update] = useState(0)
  const [loading, setLoading] = useState(false)
  const handleLogOut = () => {
    Cookies.remove('token')
    window.location.href = '/'
  }
  const token = Cookies.get('token')
  // if (!token) {
  //   window.location.href = '/'
  // }
  const userRole = token ? jwt_decode(token).role : undefined
  const decyph = token && jwt_decode(token)
  const submit = async () => {
    let response
    const resp = await activate(regkey)
      .then((res) => {
        response = res.response
        if (response.success) {
          Alert.success(response.message)
          setRegModalShow(false)
          window.location.href = window.location
        } else {
          Alert.error(response.message)
        }
      })
  }
  return (
    <>
      <div className={loading ? 'backdrop-loading' : 'hidden'}>
        <FontAwesomeIcon icon={FA.faSpinner} spin size='10x' />
      </div>
      <Modal className='welcome-modals' show={regmodalShow} backdrop keyboard={false} onHide={() => setRegModalShow(false)}>
        <Modal.Header closeButton>
          <Modal.Title className='text-center'>
            {t('profile.activate')}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <FlexboxGrid>
              <FlexboxGrid.Item colspan={24} className='p-15'>
                <Whisper
                  trigger='active' speaker={
                    <Tooltip>{t('profile.activatetooltip')}<a href='https://forms.gle/ZVNpMFMhtxgMbpFK9' target='_blank' rel='noreferrer'>{t('profile.activatelink')}</a></Tooltip>
                }
                >
                  <Input
                    placeholder={t('common.regkey')} csize='md' onChange={e => setRKey(e)}
                  />
                </Whisper>
              </FlexboxGrid.Item>
            </FlexboxGrid>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button
            appearance='primary' size='sm' onClick={submit} className='m-b-15 m-r-15'
          >
            {t('profile.activatebutton')}
          </Button>
        </Modal.Footer>
      </Modal>
      <div className={loading ? 'hidden' : 'profile-wrapper'}>
        <div className='header__logo' id='logo'>
          <div>
            <img className='header__img' style={{ height: '80px' }} src='./img/favicon.png' alt='' />
            <img className='header__little-img' src='./img/little_icon.svg' alt='' />
          </div>
          <div className='header__name'>
            <div className='header__name-agro'>Smart</div>
            <div className='header__name-bot'>Agronom</div>
          </div>
        </div>
        {decyph &&
          <div id='user'>
            <h4>{t('common.usertype.user')} {Cookies.get('first_name')} {Cookies.get('last_name')}</h4>
            <br />
            <br />
            <div className='user-tile'>
              <img src='img/favicon.png' style={{ height: '200px' }} />
              <div>
                <h5>{t('common.name')}: {Cookies.get('first_name')}</h5>
                <h5>{t('common.surname')}: {Cookies.get('last_name')}</h5>
                <h5>{t('common.accounttype')}: {decyph.role}</h5>
                <h5>{t('common.email')}: {decyph.email}</h5>
                <h5>{t('common.place')}: {Cookies.get('place')}</h5>
                <br />
                <Button
                  appearance='primary' size='sm' onClick={handleLogOut} loading={loading} className='m-r-15 m-b-15 inc'
                >
                  {t('common.logout')}
                </Button>
                {userRole === 'DEMO' &&
                  <Button
                    appearance='primary' size='sm' onClick={() => setRegModalShow(true)} loading={loading} className='m-r-15 m-b-15 inc'
                  >
                    {t('profile.activate')}
                  </Button>}
              </div>
            </div>
          </div>}
        <div className='catalog-profile'>
          <Link className='product-profile' to='/map_main'>
            <div style={{ display: 'flex' }}>
              <FontAwesomeIcon icon={FA.faDrawPolygon} className='fa-icon-profile' />
              <div className='product__info-profile'>
                <div className='product__title'>{t('profile.pages.maplist')}</div>
                <div className='product__description-profile'>{t('profile.subtext')}</div>
              </div>
            </div>
            <img src='./img/arrow.svg' alt='arrow' />
          </Link>
          <div className="flex-break"/>
          <Link className='product-profile' to='/docs_list'>
            <div style={{ display: 'flex' }}>
              {/* <div className="product__icon-profile product__icon_seeds"></div> */}
              <FontAwesomeIcon icon={FA.faStamp} className='fa-icon-profile' />
              <div className='product__info-profile'>
                <div className='product__title'>{t('profile.pages.docs')}</div>
                <div className='product__description-profile'>{t('profile.subtext')}</div>
              </div>
            </div>
            <img src='./img/arrow.svg' alt='arrow' />
          </Link>
          <Link className='product-profile' to='/soil'>
            <div style={{ display: 'flex' }}>
              {/* <div className="product__icon-profile product__icon_seeds"></div> */}
              <FontAwesomeIcon icon={FA.faSeedling} className='fa-icon-profile' />
              <div className='product__info-profile'>
                <div className='product__title'>{t('profile.pages.soil')}</div>
                <div className='product__description-profile'>{t('profile.subtext')}</div>
              </div>
            </div>
            <img src='./img/arrow.svg' alt='arrow' />
          </Link>
          <Link className='product-profile' to='/maps'>
            <div style={{ display: 'flex' }}>
              {/* <div className="product__icon-profile product__icon_branch"></div> */}
              <FontAwesomeIcon icon={FA.faMap} className='fa-icon-profile' />
              <div className='product__info-profile'>
                <div className='product__title'>{t('profile.pages.mapmain')}</div>
                <div className='product__description-profile'>{t('profile.subtext')}</div>
              </div>
            </div>
            <Link className='' to='/map_main'><img src='./img/arrow.svg' alt='arrow' /></Link>
          </Link>
          <Link className='product-profile' to='/map_sat'>
            <div style={{ display: 'flex' }}>
              {/* <div className="product__icon-profile product__icon_lightning"></div> */}
              <FontAwesomeIcon icon={FA.faSatellite} className='fa-icon-profile' />
              <div className='product__info-profile'>
                <div className='product__title'>{t('profile.pages.mapsat')}</div>
                <div className='product__description-profile'>{t('profile.subtext')}</div>
              </div>
            </div>
            <img src='./img/arrow.svg' alt='arrow' />
          </Link>
          <Link className='product-profile' to='/map_crop' onClick={() => !decyph && Alert.info('Данная вкладка недоступна неавторизированным пользователям!')}>
            <div style={{ display: 'flex' }}>
              {/* <div className="product__icon-profile product__icon_magnifier"></div> */}
              <FontAwesomeIcon icon={FA.faBinoculars} className='fa-icon-profile' />
              <div className='product__info-profile'>
                <div className='product__title'>{t('title.funcs.ident')}</div>
                <div className='product__description-profile'>{t('profile.subtext')}</div>
              </div>
            </div>
            <img src='./img/arrow.svg' alt='arrow' />
          </Link>
          <Link className='product-profile' to='/vehiclemap' onClick={() => !decyph && Alert.info('Данная вкладка недоступна неавторизированным пользователям!')}>
            <div style={{ display: 'flex' }}>
              {/* <div className="product__icon-profile product__icon_tractor"></div> */}
              <FontAwesomeIcon icon={FA.faTractor} className='fa-icon-profile' />
              <div className='product__info-profile'>
                <div className='product__title'>{t('profile.pages.telemetry')}</div>
                <div className='product__description-profile'>{t('profile.subtext')}</div>
              </div>
            </div>
            <img src='./img/arrow.svg' alt='arrow' />
          </Link>
          <Link className='product-profile' to='/datasave/all' onClick={() => !decyph && Alert.info('Данная вкладка недоступна неавторизированным пользователям!')}>
            <div style={{ display: 'flex' }}>
              {/* <div className="product__icon-profile product__icon_tractor"></div> */}
              <FontAwesomeIcon icon={FA.faAtlas} className='fa-icon-profile' />
              <div className='product__info-profile'>
                <div className='product__title'>{t('profile.pages.datasave')}</div>
                <div className='product__description-profile'>{t('profile.subtext')}</div>
              </div>
            </div>
            <img src='./img/arrow.svg' alt='arrow' />
          </Link>
          {/* <Link className="product-profile" to="/map_enc">
                <div style={{ display: 'flex' }}>
                    <FontAwesomeIcon icon={FA.faExpand} className='fa-icon-profile' />
                    <div className="product__info-profile">
                        <div className="product__title">Улучшение спутниковых снимков</div>
                        <div className="product__description-profile">Подробнее...</div>
                    </div>
                </div>
                    <img src="./img/arrow.svg" alt="arrow"/>
                </Link> */}
          {/* <Link className="product-profile" to='/map_enc'>
                <div style={{ display: 'flex' }}>
                    <FontAwesomeIcon icon={FA.faCamera} className='fa-icon-profile' />
                    <div className="product__info-profile">
                        <div className="product__title">{t('profile.pages.mapenc')}</div>
                        <div className="product__description-profile">{t('profile.subtext')}</div>
                    </div>
                </div>
                    <img src="./img/arrow.svg" alt="arrow"/>
                </Link> */}
          <Link className='product-profile' to='/yield_prediction' onClick={() => !decyph && Alert.info('Данная вкладка недоступна неавторизированным пользователям!')}>
            <div style={{ display: 'flex' }}>
              <FontAwesomeIcon icon={FA.faCloudShowersHeavy} className='fa-icon-profile' />
              <div className='product__info-profile'>
                <div className='product__title'>{t('title.funcs.yield')}</div>
                <div className='product__description-profile'>{t('profile.subtext')}</div>
              </div>
            </div>
            <img src='./img/arrow.svg' alt='arrow' />
          </Link>
          {userRole === 'ADMIN' && <Link className='product-profile' to='/admin'>
            <div style={{ display: 'flex' }}>
              {/* <div className="product__icon-profile product__icon_check"></div> */}
              <FontAwesomeIcon icon={FA.faCheckCircle} className='fa-icon-profile' />
              <div className='product__info-profile'>
                <div className='product__title'>{t('profile.pages.adminpanel')}</div>
                <div className='product__description-profile'>{t('profile.subtext')}</div>
              </div>
            </div>
            <img src='./img/arrow.svg' alt='arrow' />
          </Link>}
          <a className='product-profile' href='/GISPortal.apk' target='_blank' rel='noreferrer'>
            <div style={{ display: 'flex' }}>
              <FontAwesomeIcon icon={FA.faMobile} className='fa-icon-profile' />
              <div className='product__info-profile'>
                <div className='product__title'>{t('profile.pages.apk')}</div>
                <div className='product__description-profile'>{t('profile.subtext')}</div>
              </div>
            </div>
            <img src='./img/arrow.svg' alt='arrow' />
          </a>
          {/* <Link className="product-profile" to="/infolist">
                <div style={{ display: 'flex' }}>
                    <FontAwesomeIcon icon={FA.faBook} className='fa-icon-profile' />
                    <div className="product__info-profile">
                        <div className="product__title">{t('profile.pages.books')}</div>
                        <div className="product__description-profile">{t('profile.subtext')}</div>
                    </div>
                </div>
                    <img src="./img/arrow.svg" alt="arrow"/>
                </Link> */}
          {/* <Link className="product-profile" to="/infospecial">
                <div style={{ display: 'flex' }}>
                    <FontAwesomeIcon icon={FA.faAtlas} className='fa-icon-profile' />
                    <div className="product__info-profile">
                        <div className="product__title">{t('profile.pages.specialinfo')}</div>
                        <div className="product__description-profile">{t('profile.subtext')}</div>
                    </div>
                </div>
                    <img src="./img/arrow.svg" alt="arrow"/>
                </Link> */}
        </div>
      </div>
    </>
  )
}

export default Profile
