import L from 'leaflet'
import React, { useEffect, useRef, useState } from 'react'
// import 'leaflet-distortableimage'
import 'leaflet-imageoverlay-rotated'
import 'leaflet-toolbar'
import MapControls from './MapControls'
import * as FA from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import Cookies from 'js-cookie'
import { useTranslation } from 'react-i18next'
import { Alert, Button, Form, Loader, Panel, Uploader } from 'rsuite'
import { cropIdentification, getGeoJSON } from '../http/API'
import '../styles/sidenav.css'

const MapCrops = () => {
  const [image, setImage] = useState()
  const [imageLoading, setImLoading] = useState(false)
  const dic = {
    Corn: 'Куруруза',
    Cotton: 'Хлопок',
    Soy: 'Соя',
    'Spring Wheat': 'Пшеница яровая',
    'Winter Wheat': 'Пшеница озимая',
    Barley: 'Ячмень'
  }
  const imgUpload = async (e) => {
    if (e && e.length > 0) {
      const f = e[0].blobFile
      setImage(f)
    }
  }

  const [jsonToDisplay, setJSON] = useState([])
  const [cropType, setCropType] = useState()
  const [updateKey, update] = useState(0)
  const [satLoading, setLoading] = useState(false)
  const [selField, setField] = useState()
  const [mapType, setMap] = useState(Cookies.get('pref-map') || 'http://mt1.google.com/vt/lyrs=y&x={x}&y={y}&z={z}')
  function sortByProperty (property) {
    return function (a, b) {
      if (a[property] > b[property]) {
        return 1
      } else if (a[property] < b[property]) {
        return -1
      }
      return 0
    }
  }
  const [SatListShow, setSatListShow] = useState(false)
  const [ImageUploadShow, setImageUploadShow] = useState(true)

  const CloseOtherModals = () => {
    setSatListShow(false)
    setImageUploadShow(false)
  }
  useEffect(async () => {
    const fieldsList = await getGeoJSON()
      .catch((e) => {
        if (String(e).includes('401')) {
          // Cookies.remove('token')
          window.location.href = '/map_main'
        }
      })
    if (fieldsList.response.success) {
      const f = fieldsList.response.fields.sort(sortByProperty('id'))
      setJSON(f)
      try {
        if (updateKey === 0) {
          fitBounds(JSON.parse(fieldsList.response.fields[0].json))
        }
      } catch (e) {

      }
    }
    showLayerRef.current.clearLayers()
  }, [updateKey])

  const [fieldMaster, setfieldMaster] = useState()
  const [fieldNameMaster, setfieldNameMaster] = useState()
  const [ImageOnMap, setImageOnMap] = useState()

  const { t, i18n } = useTranslation()
  // const { dataTiles, fetchDataTiles, activeTile, setActiveTile } = useContext(SatImgContext)
  // const { searchOptions, setSearchOptions, jsonLayers, newOrder, setNewOrder } = useContext(SearchOptionsContext)
  // const { navItemId, setNavItemId } = useContext(NavigationContext)
  const [isLoading, setIsLoading] = useState(false)

  const mapRef = useRef(null) // map
  const paneRef = useRef(null) // tiles order on top
  const drawnLayerRef = useRef(null) // drawn footrpints - paths (lines) = featureGroup
  const showLayerRef = useRef(null) // drawn footrpints - paths (lines) = featureGroup
  const tileLayerRef = useRef(null) // tiles in tilelayer = featureGroup
  const searchGeometryRef = useRef(null) // search geometries on the map = featureGroup
  // const [maptype, changeMap] = useState('alidade_smooth_dark')
  let map

  /** map initialization */
  useEffect(() => {
    try {
      map = L.map('map', {
        center: [49.945963, 82.613922],
        zoom: 10,
        minZoom: 1,
        zoomControl: false,
        attributionControl: false
      })
      L.tileLayer(mapType, {
        subdomains: ['mt0', 'mt1', 'mt2', 'mt3'],
        maxZoom: 18,
        id: 'baselayer'
      }).addTo(map)
      L.control.attribution({
        position: 'bottomright'
      }).addTo(map)
      // map.fitBounds([[47.12995075666307, 52.064208984375], [51.1807, 71.461]])

      // Searched geometries
      searchGeometryRef.current = L.featureGroup().addTo(map)
      showLayerRef.current = L.featureGroup().addTo(map)
      drawnLayerRef.current = L.featureGroup().addTo(map)
      tileLayerRef.current = L.featureGroup().addTo(map)
      mapRef.current = map
      // Pane for tiles topping on the map
      const topPane = mapRef?.current.createPane('leaflet-top-pane', mapRef.current.getPanes().mapPane)
      paneRef.current = topPane

      // Adding Controls
      MapControls(mapRef.current, t)
      mapRef.current.invalidateSize(9)
    } catch (e) {

    }
  }, [])
  useEffect(() => {
    // mapRef.current.removeLayer('baselayer')
    mapRef.current.attributionControl.setPrefix(Cookies.get('pref-attr'))
    L.tileLayer(mapType, {
      maxZoom: 18,
      id: 'baselayer'
    }).addTo(mapRef.current)
  }, [mapType])
  useEffect(() => {
    searchGeometryRef.current.clearLayers()
    const myStyle = {
      fillOpacity: 0
    }
    jsonToDisplay.forEach((f) => {
      const mask = L.geoJSON(JSON.parse(f.json), myStyle)
      const name = JSON.parse(f.json).properties.name
      const area = JSON.parse(f.json).properties.area

      if (JSON.parse(f.json).geometry?.type === 'Point') {
        mask.bindTooltip(() => name, { permanent: true })
        mask.addEventListener('mouseover', () => {
          mask.unbindTooltip()
          const time = new Date(f.createdAt)
          mask.bindTooltip(() => `Создано: ${time.toLocaleString('ru-RU')}`, { permanent: true })
        })
        mask.addEventListener('mouseout', () => {
          mask.unbindTooltip()
          mask.bindTooltip(() => name, { permanent: true })
        })
      } else {
        mask.bindTooltip(() => name + ` (${(area / 10000).toFixed(3)} га)`)
        mask.addEventListener('click', () => (window.innerWidth > 840 && setfieldMaster(f.id)))
      }
      mask.addEventListener('mouseover', () => (mask.openTooltip()))
      searchGeometryRef.current.addLayer(mask)
    })
  }, [jsonToDisplay])

  const downloadIndexImage = async () => {
    //
    // mapRef.current.fitBounds([[bbox[0], bbox[1]], [bbox[2], bbox[3]]])
    if (image) {
      // const file = await GeoTIFF.fromSource(makeFileReaderSource(redImage))
      // const image = await file.getImage()
      // const bbox = image.getBoundingBox()
      const form = new FormData()

      form.append('image', image)
      setLoading(true)
      const resp = await cropIdentification(form).then(r => {
        setLoading(false)
        if (r.response.success) {
          setCropType(r.response.crop)
        } else {
          Alert.error(r.response.message)
        }
      })
    } else {
      Alert.error('Убедитесь, что снимок был загружен!')
    }
  }

  const fitBounds = (field) => {
    const mask = L.geoJSON(field)

    if (field.geometry?.type === 'Point') {
      const lat = field.geometry.coordinates[1]
      const lng = field.geometry.coordinates[0]
      mapRef.current.panTo([lat, lng], 10)
    } else {
      mapRef.current.fitBounds(mask.getLayers()[0].getLatLngs())
    }
  }
  return (
    <>
      <div className='navbar-side-wrapper'>
        <nav className='navbar-side-new'>
          <button className='new-link-element'>
            <img src='/logo192.png' alt='' width='40px' />
          </button>
          <button className={`new-link-element ${SatListShow ? 'dropdown-chosen' : ''}`} onClick={() => { CloseOtherModals(); setSatListShow(!SatListShow) }} title={t('map.sidebar.sources')}>
            <FontAwesomeIcon icon={FA.faSatellite} />
            <h5>{t('map.sidebar.sources')}</h5>
          </button>
          <button className={`new-link-element ${ImageUploadShow ? 'dropdown-chosen' : ''}`} onClick={() => { CloseOtherModals(); setImageUploadShow(!ImageUploadShow) }} title='Загрузка снимков для идентификации'>
            <FontAwesomeIcon icon={FA.faSearch} />
            <h5>Загрузка снимков для идентификации</h5>
          </button>
          <button className='new-link-element' onClick={() => i18n.changeLanguage(i18n.language === 'ru' ? 'kz' : i18n.language === 'kz' ? 'en' : 'ru')} title='Смена языка'>
            <FontAwesomeIcon icon={FA.faGlobe} />
            <h5>
              {t('map.sidebar.lang')} {i18n.language.toUpperCase()}
            </h5>
          </button>
          <button onClick={() => { window.location.href = '/profile' }} className='new-link-element' title={t('common.return')}>
            <FontAwesomeIcon icon={FA.faBackward} />
            <h5>{t('common.return')}</h5>
          </button>
        </nav>
        <nav className='dropdown-content' hidden={!SatListShow}>
          <h5>{t('map.sidebar.sources')}:</h5>
          <br />
          <ul>
            <li>Sentinel-2</li>
            <li>LandSat-8</li>
            <li>KazEOSat-1</li>
            <li>KazEOSat-2</li>
          </ul>
        </nav>
        <div className='dropdown-content new-side' hidden={!ImageUploadShow}>
          <Panel bordered>
            <h3>{t('map.sidebar.upload_image_alt')}</h3>
            <br />
            <h5>Идентификация культур</h5>
            <br />
            <Form id='image-form'>
              <Uploader accept='.jpeg, .jpg, .png' type='file' onChange={imgUpload} autoUpload={false} toggleComponentClass={Button}><Button>Снимок для идентификации</Button></Uploader>
            </Form>
            <br />
            <Button onClick={downloadIndexImage} loading={satLoading}>{t('map.sidebar.enhance.upload')}</Button>
            {
              cropType && <h5>Предсказанная культура: {dic[cropType]}</h5>
            }
          </Panel>
          <br />
        </div>
      </div>
      <div id='map'>
        {isLoading && <Loader inverse size='md' center content='loading' className='z-600' />}
      </div>
    </>
  )
}

export default MapCrops
