import React, { useEffect, useState } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import * as FA from '@fortawesome/free-solid-svg-icons'
import { downloadPredData, downloadWeatherData, getYieldPrediction } from '../http/API'
import { Input, Alert, Button, ControlLabel, InputGroup, SelectPicker, Panel } from 'rsuite'
import Cookies from 'js-cookie'
import { useTranslation } from 'react-i18next'

const YieldPrediction = ({ display, field, setCropType, jsonToDisplay, foundFields, fitBounds, setfieldMasterOrig }) => {
  const { t, i18n } = useTranslation()
  const [data, setData] = useState({
    maxt: 12,
    mint: -1,
    ndvi: 0.23,
    humtop: 11.22,
    humbottom: 11.8,
    wind: 1.7,
    humid: 31,
    precip: 0,
    msavi: 0.11
  })
  const [loading, setLoading] = useState(false)
  const [prediction, setValue] = useState()
  const dic = {
    maslo: 'Масличные',
    gor: 'Зернобобовые',
    zerno: 'Зерновые',
    kormo: 'Кормовые'
  }
  const crops = [
    {
      label: 'Масличные',
      value: 'maslo'
    },
    {
      label: 'Зернобобовые',
      value: 'gor'
    },
    {
      label: 'Зерновые',
      value: 'zerno'
    },
    {
      label: 'Кормовые',
      value: 'kormo'
    }
  ]
  const submit = async () => {
    setLoading(true)
    const resp = await getYieldPrediction(data).then(res => {
      setLoading(false)
      if (res.response.success) {
        setValue(res.response.index)
      } else {
        Alert.error(res.response.message)
      }
    })
  }
  // const downloadXLSX = async () => {
  //   const req = await downloadPredData().then(res => {
  //     const url = window.URL.createObjectURL(new Blob([res]))
  //
  //
  //     const link = document.createElement('a')
  //     link.href = url
  //     link.setAttribute('download', 'fielddata.xlsx')
  //     document.body.appendChild(link)
  //     link.click()
  //   })
  // }
  // const downloadXLSXWeather = async () => {
  //   const req = await downloadWeatherData().then(res => {
  //
  //     const url = window.URL.createObjectURL(new Blob([res], { type: 'application/vnd.openxmlformatsofficedocument.spreadsheetml.sheet' }))
  //
  //     const link = document.createElement('a')
  //     link.href = url
  //     link.setAttribute('download', 'weatherdata.xlsx')
  //     document.body.appendChild(link)
  //     link.click()
  //   })
  // }
  return (
    <div className='dropdown-content' hidden={!display} style={{ maxHeight: '100vh', overflowY: 'scroll' }}>
      {/* <a style={{ width: 'fit-content', float: 'right', fontSize: '20px', padding: '5px' }} className='newbutton nav-element side dropdown' href='/profile'>
      <FontAwesomeIcon icon={FA.faHome} />
    </a>
    <a style={{ width: 'fit-content', float: 'right', fontSize: '20px', padding: '5px' }} className='newbutton nav-element side dropdown' onClick={() => i18n.changeLanguage(i18n.language === 'ru' ? 'kz' : i18n.language === 'kz' ? 'en' : 'ru')}>
      <h5 style={{ margin: 'auto' }}>{i18n.language.toUpperCase()}</h5>
    </a> */}
      <Panel className='sel-crop' hidden={data.croptype || jsonToDisplay.length === 0}>
        <h4>Выберите культуру</h4>
        {
      crops.map((e, key) => <Button key={key * 6} onClick={() => { setData({ ...data, croptype: e.value }); setCropType(e.value) }}>{e.label}</Button>)
    }
      </Panel>
      <div hidden={!data.croptype}>
        <h4>Прогнозирование урожайности</h4>
        {data.croptype && <h5>Культура: {dic[data.croptype]}</h5>}
        <hr />
        <h5>Выбранное поле: {field ? JSON.parse(field.json).properties.name : 'Поле не выбрано'}</h5>
        {!field && <p>Нажмите на поле для выбора</p>}
        <br />
        {/* <h6>NDVI</h6><Input type='number' onChange={e => setData({ ...data, ndvi: e })}/> */}
        {/* <h6>Год</h6><Input type='number' onChange={e => setData({ ...data, years: e })}/> */}
        {/* <h6>Максимальная температура (С)</h6><Input type='number' onChange={e => setData({ ...data, maxt: e })}/> */}
        {/* <h6>Миниимальная температура (С)</h6><Input type='number' onChange={e => setData({ ...data, mint: e })}/> */}
        {/* <h6>Влажность поверхностного слоя грунта (%)</h6><Input type='number' onChange={e => setData({ ...data, humtop: e })}/>
      <h6>Влажность в корневой зоне (%)</h6><Input type='number' onChange={e => setData({ ...data, humbottom: e })}/>
      <h6>Скорость ветра (м/с)</h6><Input type='number' onChange={e => setData({ ...data, wind: e })}/>
      <h6>Влажность (%)</h6><Input type='number' onChange={e => setData({ ...data, humid: e })}/>
      <h6>Осадки (мм)</h6><Input type='number' onChange={e => setData({ ...data, precip: e })}/>
      <h6>MSAVI</h6><Input type='number' onChange={e => setData({ ...data, msavi: e })}/> */}
        <SelectPicker onChange={e => setData({ ...data, years: e })} data={[2022, 2023].map(e => ({ label: e, value: e }))} placeholder='Год' searchable={false} style={{ width: '100%' }} placement='auto' />
        {/* <SelectPicker onChange={e => setData({ ...data, croptype: e })} data={crops} placeholder='Тип культуры' searchable = {false} style={{ width: '100%' }} placement='auto'/> */}
        <Button onClick={submit}>Выполнить расчет</Button><br />
        {/* <Button onClick={}>Отправить</Button> */}
        <hr />
        {
        foundFields.map((e, key) => <Button onClick={() => { setfieldMasterOrig(e); fitBounds(JSON.parse(e.json)) }} key={key * 9}>{JSON.parse(e.json).properties.name}</Button>)
      }
        <hr />
        {loading && <FontAwesomeIcon icon={FA.faSpinner} spin />}
        {prediction && !loading && <h3>Предсказанная урожайность: {prediction} ц/га</h3>}
      </div>
    </div>
  )
}

export default YieldPrediction
