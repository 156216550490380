import { useState, useEffect, useCallback } from 'react'
import { Alert, Button, DateRangePicker, Input, Panel, Slider, Tooltip, Whisper } from 'rsuite'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import * as FA from '@fortawesome/free-solid-svg-icons'
import axios from 'axios'
import L from 'leaflet'
import { useTranslation } from 'react-i18next'
import Cookies from 'js-cookie'

function SatImages ({ adClass, downloadImage, selField, image, download, loading }) {
  const { t } = useTranslation()
  const [dateRange, setDateRange] = useState()
  const [cloudiness, setCloudiness] = useState()
  return (
    <div className={`dropdown-content ${adClass}`}>
      <Panel bordered>
        <h3>Выбранное поле: <br /> {selField && JSON.parse(selField.json).properties.name}</h3>
        <h5>Выберите диапазон снимка: </h5>
        <p>(будет выбран наилучший снимок в этом диапазоне)</p>
        <br />
        <DateRangePicker showOneCalendar onChange={e => setDateRange(e)} disabledDate={DateRangePicker.allowedMaxDays(30)} />
        <br />
        <h5>Выберите максимальную облачность:</h5>
        <br />
        <Slider progress onChange={e => setCloudiness(e)} />
        <br />
        {!loading ? <Button onClick={() => dateRange && downloadImage(selField.id, selField.json, dateRange, cloudiness) ? console.log(dateRange, cloudiness) : Alert.error('Проверьте введенные данные!')}>Загрузить снимок</Button> : <Button><FontAwesomeIcon icon={FA.faSpinner} spin /></Button>}
        <br />
        {image && <Button onClick={download}>Скачать снимок</Button>}
      </Panel>
    </div>
  )
}

export default SatImages
