import '../styles/title.css'
import { useEffect, useState } from 'react'
import Cookies from 'js-cookie'
import { useTranslation } from 'react-i18next'
import { Alert, Input } from 'rsuite'
import { addContactMessage } from '../http/API'

function Title () {
  const { t, i18n } = useTranslation()
  const [data, setData] = useState({})
  //   const [mobMenu, revealMenu] = useState(true)
  const onSubmit = async () => {
    try {
      const resp = await addContactMessage(data).then((res) => {
        const response = res.response
        if (response.success) {
          Alert.success(response.message)
        } else {
          Alert.error(response.message)
        }
      })
    } catch (e) {
      Alert.error(t('error.noconnection'))
    }
  }
  return (
    <div className='title-wrap'>
      <header className='header'>
        <div className='header__logo'>
          <div>
            <img className='header__img' style={{ height: '50px' }} src='./img/favicon.png' alt='' />
            <img className='header__little-img' src='./img/little_icon.svg' alt='' />
          </div>
          <div className='header__name'>
            <div className='header__name-agro'>Smart</div>
            <div className='header__name-bot'>Agronom</div>
          </div>
        </div>
        {/* <nav className="header__navigation">
            <ul className="header__menu"> */}
        <div className='header-item'>
          <li className='header__item header__item_main'><a className='header__link header__link_main' href=''>{t('title.list.main')}</a>
          </li>
          <li className='header__item'><a className='header__link' href=''>{t('title.list.contacts')}</a></li>
          <li className='header__item'><a className='header__link' href=''>{t('title.list.aboutus')}</a></li>
        </div>
        <div className='header-item'>
          <li className='header__item'><a className='header__link' style={{ cursor: 'pointer' }} onClick={() => i18n.changeLanguage(i18n.language === 'ru' ? 'kz' : i18n.language === 'kz' ? 'en' : 'ru')}>{i18n.language.toUpperCase()}</a></li>
          <a
            href={Cookies.get('token') ? '/profile' : '/auth'}
            rel='noreferrer'
            className='btn agrobot__btn btn_shadow'
            id='auth'
          >
            {t('title.list.profile')}
          </a>
        </div>
        {/* </ul>
        </nav> */}
      </header>
      <main>
        <section className='agrobot'>
          <div className='banner'>
            <h1 className='banner__title'><span className='banner__title_green'>Smart</span><span>Agronom</span></h1>
            <p className='banner__description'>{t('title.subtitle')}</p>
            <a className='btn agrobot__btn contact-button' href='#contact-us'><span className='btn__stripes' />{t('title.contactus')}</a>
          </div>
        </section>
        <section className='goods'>
          <div className='goods-flex'>
            <img src='/img/left-panel.png' />
            <img src='/img/phone.png' />
            <img src='/img/right-panel.png' />
          </div>
          <div className='goods-funcs'>
            <div>
              <p>01</p>
              <b>{t('title.ability.forecast')}</b>
            </div>
            <div>
              <p>02</p>
              <b>{t('title.ability.analysis')}</b>
            </div>
            <div>
              <p>03</p>
              <b>{t('title.ability.identify')}</b>
            </div>
          </div>
        </section>
        <section className='funcs'>
          <h2>{t('title.funcs.title')}</h2>
          <p>{t('title.funcs.sub')}</p>
          <div>
            <img src='img/banner1.png' />
            <div>
              <img src='img/banner2.png' />
              <img src='img/banner3.png' />
            </div>
          </div>
        </section>
        <section className='map'>
          <h2>{t('title.office')}</h2>
          <p>{t('title.address')}</p>
          <div className='contact__map'>
            <img className='contact__map' src='img/map_google.png' />
          </div>
        </section>
        <section className='contact'>
          <h2>{t('title.contactus')}</h2>
          <div className='contact__container'>
            <div className='contact__forms'>
              <Input className='contact__forms_input' type='text' placeholder={t('title.feed.name')} required='' name='name' onChange={(e) => setData({ ...data, name: e })} />
              <hr />
              <Input className='contact__forms_input' type='text' placeholder={t('title.feed.number')} required='' name='phone' onChange={(e) => setData({ ...data, phone: e })} />
              <hr />
              <Input className='contact__forms_input' type='text' placeholder={t('title.feed.email')} required='' name='email' onChange={(e) => setData({ ...data, email: e })} />
              <hr />
              <Input className='contact__forms_textarea' componentClass='textarea' rows={5} placeholder={t('title.feed.message')} onChange={(e) => setData({ ...data, message: e })} />
              <br />
              <button className='btn agrobot__btn ' onClick={onSubmit} style={{ padding: '25px' }}>{t('common.send')}</button>
            </div>
          </div>
        </section>
        <br />
      </main>
      <footer className='footer'>
        {/* <span className="footer__logo"></span> */}
        <nav className='site-map'>
          <h4 className='site-map__title'>{t('title.sitemap')}</h4>
          <div className='site-map__list'>
            <ul className='site-map__sublist site-map__sublist_sublist1'>
              <li className='site-map__item'><a className='site-map__link site-map__link_main' href=''>{t('title.list.main')}</a></li>
              <li className='site-map__item'><a className='site-map__link' href=''> {t('title.list.aboutus')}</a></li>
            </ul>
          </div>
        </nav>
        <div className='social'>
          <h4 className='social__title'>{t('title.social')}</h4>
          <ul className='social__list'>
            <li className='social__item'><a className='social__link social__link_facebook' href='https://www.facebook.com/EKTUniversity' rel='noreferrer' target='_blank' />
            </li>
            <li className='social__item'><a className='social__link social__link_instagram' href='https://www.instagram.com/ektu.kz/' rel='noreferrer' target='_blank' />
            </li>
            <li className='social__item'><a className='social__link social__link_youtube' href='https://www.youtube.com/@user-dl8xj5tj3b' rel='noreferrer' target='_blank' /></li>
          </ul>
        </div>
        <p className='copyright'>{t('title.place')} <span className='copyright_bold'>{t('title.rights')}</span> 2022<a className='copyright__link' href=''>
          <br />
          <span className='copyright_bold copyright_light'>Smart Agronom</span>
        </a>
        </p>
      </footer>
      {/* <div className="bot"><a href="#contact-us"><img src="./img/bot.svg" alt="bot" /></a>
        <p className="bot__text">{t('title.contactus')}!</p>
      </div> */}
    </div>
  )
}

export default Title
