import { useState, useEffect } from 'react'
import { Alert, Input, Radio, RadioGroup, SelectPicker } from 'rsuite'
import L from 'leaflet'
import { useTranslation } from 'react-i18next'
import Cookies from 'js-cookie'

function MapTypes ({ adClass, setMap }) {
  const { t } = useTranslation()
  const maps = [
    'https://mt1.google.com/vt/lyrs=y&x={x}&y={y}&z={z}',
    'https://b.tile.opentopomap.org/{z}/{x}/{y}.png',
    'https://mt1.google.com/vt/lyrs=t&x={x}&y={y}&z={z}',
    'https://mt1.google.com/vt/lyrs=h&x={x}&y={y}&z={z}'
  ]
  const attrs = [
    `&copy; ${t('common.tablet')} ${t('common.agro')} v 3.0 , &copy; <a href="https://google.com/">Google</a> &copy; <a href="http://maps.google.com">Google Map</a> contributors`,
    `&copy; ${t('common.tablet')} ${t('common.agro')} v 3.0, © OpenStreetMap-Mitwirkende, SRTM | Kartendarstellung: © OpenTopoMap (CC-BY-SA)`
  ]
  return (
    <div className={`dropdown-content ${adClass}`}>
      <h4>Выбор подложки</h4>
      <hr />
      {/* <RadioGroup name="radioList"> */}
      <Radio checked={Cookies.get('pref-map') === maps[0]} onChange={() => { Cookies.set('pref-map', maps[0]); Cookies.set('pref-attr', attrs[0]); setMap(maps[0]) }}>Спутник</Radio>
      <Radio checked={Cookies.get('pref-map') === maps[1]} onChange={() => { Cookies.set('pref-map', maps[1]); Cookies.set('pref-attr', attrs[1]); setMap(maps[1]) }}>Топологическая</Radio>
      <Radio checked={Cookies.get('pref-map') === maps[2]} onChange={() => { Cookies.set('pref-map', maps[2]); Cookies.set('pref-attr', attrs[0]); setMap(maps[2]) }}>Рельефная</Radio>
      <Radio checked={Cookies.get('pref-map') === maps[3]} onChange={() => { Cookies.set('pref-map', maps[3]); Cookies.set('pref-attr', attrs[0]); setMap(maps[3]) }}>Дорожная</Radio>
      {/* </RadioGroup> */}
    </div>
  )
}

export default MapTypes
