import L from 'leaflet'
import React, { useEffect, useRef, useState } from 'react'
// import 'leaflet/dist/leaflet.css'
import * as FA from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import * as turf from '@turf/turf'
import Cookies from 'js-cookie'
// import 'leaflet-distortableimage'
import 'leaflet-imageoverlay-rotated'
import 'leaflet-toolbar'
import { useTranslation } from 'react-i18next'
import { Alert, Button, Loader } from 'rsuite'
import { addGeoJSON, deleteGeoJSON, getFields, getGeoJSON } from '../http/API'
import UploadFilesModal from '../imports/UploadFilesModal'
import AttributeList from '../sidecomponents/sidewindows/AttributeList'
import CreateField from '../sidecomponents/sidewindows/CreateField'
import CreateMarker from '../sidecomponents/sidewindows/CreateMarker'
import DrawTools from '../sidecomponents/sidewindows/DrawTools'
import FieldDataModal from '../sidecomponents/sidewindows/FieldDataModal'
import FieldsListMain from '../sidecomponents/sidewindows/FieldsListMain'
import LayerList from '../sidecomponents/sidewindows/LayerList'
import MapTypes from '../sidecomponents/sidewindows/MapTypes'
import UpdateField from '../sidecomponents/sidewindows/UpdateField'
import '../styles/sidenav.css'
import MapControls from './MapControls'
// import { HeatmapLayer } from 'react-leaflet-heatmap-layer-v3'

const Map = () => {
  const [curTheme, setTheme] = useState(Cookies.get('theme') === 'black' ? 'black' : 'white')
  const [jsonToSave, saveJSON] = useState('')
  const [markerJSON, setMarkerJSON] = useState('')
  const [jsonToDisplay, setJSON] = useState([])
  const [jsonOrig, setJSONOrig] = useState([])
  const [fieldToUpdate, setFieldToUpdate] = useState()
  const [updateKey, update] = useState(0)
  const [upd, updateF] = useState(0)
  const [checker, sureness] = useState(false)
  const [fieldData, setFieldData] = useState()
  const [chosenCulture, setCulture] = useState()
  const [chosenYear, setYear] = useState()
  const [cultureColors, setColors] = useState({})

  const [mapType, setMap] = useState(Cookies.get('pref-map') || 'http://mt1.google.com/vt/lyrs=y&x={x}&y={y}&z={z}')
  function sortByProperty (property) {
    return function (a, b) {
      if (a[property] > b[property]) {
        return 1
      } else if (a[property] < b[property]) {
        return -1
      }
      return 0
    }
  }
  const CloseOtherModals = () => {
    setLayerListShow(false)
    setAttributeListShow(false)
    setDrawToolsShow(false)
    setUploadModalShow(false)
    setFieldsListShow(false)
    setNotesListShow(false)
    setCreateModalShow(false)
    setDataModalShow(false)
    setRecommendShow(false)
    setUpdateModalShow(false)
    setChartModalShow(false)
    setMapTypesShow(false)
    setCreateMarkerModalShow(false)
    setFieldDataViewerShow(false)
  }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(async () => {
    const fieldsList = await getGeoJSON()
      .catch((e) => {
        if (String(e).includes('401')) {
          // Cookies.remove('token')
          window.location.href = '/map_main'
        }
      })
    if (fieldsList.response.success) {
      setJSON(fieldsList.response.fields.sort(sortByProperty('id')))
      setJSONOrig(fieldsList.response.fields.sort(sortByProperty('id')))
      try {
        if (updateKey === 0) {
          fitBounds(JSON.parse(fieldsList.response.fields[0].json))
        }
      } catch (e) {

      }
      const fieldDataList = await getFields()
      if (fieldDataList.response.success) {
        setFieldData(fieldDataList.response)
        const defColors = [
          '#1f78b4',
          '#33a02c',
          '#e31a1c',
          '#ff7f00',
          '#6a3d9a',
          '#b15928',
          '#a6cee3',
          '#b2df8a',
          '#fb9a99',
          '#fdbf6f',
          '#cab2d6',
          '#ffff99',
          '#8da0cb',
          '#fc8d62',
          '#66c2a5',
          '#ffd92f',
          '#5e4fa2',
          '#3288bd',
          '#d53e4f',
          '#fdae61',
          '#fee08b',
          '#bebada',
          '#ff7f00',
          '#1f78b4',
          '#33a02c',
          '#e31a1c',
          '#ff7f00',
          '#6a3d9a',
          '#b15928'
        ]
        const colorCode = {}
        for (let i = 0; i < fieldDataList.response.cultures.length; i++) {
          colorCode[fieldDataList.response.cultures[i]] = defColors[i % defColors.length]
        }
        setColors(colorCode)
        setYear('2023')
      }
    }
    showLayerRef.current.clearLayers()
  }, [updateKey])
  const addField = async (field, notClear) => {
    try {
      let response
      const resp = await addGeoJSON(field).then((res) => {
        response = res.response
        if (response.success) {
          Alert.success(response.message)
          !notClear && clearLayers()
          !notClear && update(updateKey + 1)
        } else {
          Alert.error(response.message)
        }
      })
    } catch (e) {
      Alert.error(t('error.noconnection'))
    }
  }
  const addMarker = async (field) => {
    try {
      let response
      const resp = await addGeoJSON(field).then((res) => {
        response = res.response
        if (response.success) {
          Alert.success(response.message)
          clearLayers()
          update(updateKey + 1)
        } else {
          Alert.error(response.message)
        }
      })
    } catch (e) {
      Alert.error(t('error.noconnection'))
    }
  }
  const deleteFields = async (field) => {
    if (!checker) {
      Alert.warning(t('warning.clickagain'))
      sureness(true)
    } else {
      sureness(false)
      try {
        let response
        const resp = await deleteGeoJSON(field).then((res) => {
          response = res.response
          if (response.success) {
            Alert.success(response.message)
            clearLayers()
            update(updateKey + 1)
          } else {
            Alert.error(response.message)
          }
        })
      } catch (e) {
        Alert.error(t('error.noconnection'))
      }
    }
  }
  const [LayerListShow, setLayerListShow] = useState(false)
  const [AttributeListShow, setAttributeListShow] = useState(false)
  const [DrawToolsShow, setDrawToolsShow] = useState(false)
  const [UploadModalShow, setUploadModalShow] = useState(false)
  const [CreateModalShow, setCreateModalShow] = useState(false)
  const [CreateMarkerModalShow, setCreateMarkerModalShow] = useState(false)
  const [FieldsListShow, setFieldsListShow] = useState(true)
  const [NotesListShow, setNotesListShow] = useState(false)
  const [DataModalShow, setDataModalShow] = useState(false)
  const [RecommendShow, setRecommendShow] = useState(false)
  const [updateModalShow, setUpdateModalShow] = useState(false)
  const [chartModalShow, setChartModalShow] = useState(false)
  const [MapTypesShow, setMapTypesShow] = useState(false)
  const [FieldDataViewerShow, setFieldDataViewerShow] = useState(false)

  const [selField, setSelField] = useState()
  const [fieldMaster, setfieldMaster] = useState()
  const [fieldNameMaster, setfieldNameMaster] = useState()
  const { t, i18n } = useTranslation()
  // const { dataTiles, fetchDataTiles, activeTile, setActiveTile } = useContext(SatImgContext)
  // const { searchOptions, setSearchOptions, jsonLayers, newOrder, setNewOrder } = useContext(SearchOptionsContext)
  const [isLoading, setIsLoading] = useState(false)

  const MAX_AREA = 6313063483978.778// in ha
  const footprintOptions = {
    stroke: true,
    // color: '#4185f4',
    color: '#E3AF34',
    weight: 3,
    opacity: 0.6,
    fill: true,
    fillColor: null,
    fillOpacity: 0,
    clickable: true
  }

  const mapRef = useRef(null) // map
  const paneRef = useRef(null) // tiles order on top
  const drawnLayerRef = useRef(null) // drawn footrpints - paths (lines) = featureGroup
  const markerRef = useRef(null) // drawn footrpints - paths (lines) = featureGroup
  const showLayerRef = useRef(null) // drawn footrpints - paths (lines) = featureGroup
  const tileLayerRef = useRef(null) // tiles in tilelayer = featureGroup
  const heatmapLayer = useRef(null) // tiles in tilelayer = featureGroup/
  const searchGeometryRef = useRef(null) // search geometries on the map = featureGroup
  // const [maptype, changeMap] = useState('alidade_smooth_dark')
  let map

  const handlePolygonClick = (e) => {
    setCreateModalShow(true)
    const point = turf.point([e.latlng.lng, e.latlng.lat])
    // dataTiles?.forEach(scene => {
    //   if (turf.booleanPointInPolygon(point, scene.geometry)) {
    //     setActiveTile(scene, true)
    //   }
    // })
  }

  // TODO: remove if not used in production = map cleaning - alternative method
  // function removeLayers () {
  //   if (mapRef?.current) {
  //     if (mapRef.current.hasLayer(drawnLayerRef.current)) {
  //       mapRef.current.removeLayer(drawnLayerRef.current)
  //     }
  //     if (mapRef.current.hasLayer(tileLayerRef.current)) {
  //       mapRef.current.removeLayer(tileLayerRef.current)
  //     }
  //   }
  // }

  function clearLayers () {
    if (mapRef?.current) {
      // if (mapRef.current.hasLayer(searchGeometryRef.current)) searchGeometryRef.current.clearLayers()
      if (mapRef.current.hasLayer(drawnLayerRef.current)) drawnLayerRef.current.clearLayers()
      // if (mapRef.current.hasLayer(markerRef.current)) markerRef.current.clearLayers()
      if (mapRef.current.hasLayer(tileLayerRef.current)) tileLayerRef.current.clearLayers()
      // if (mapRef.current.hasLayer(heatmapLayer.current)) heatmapLayer.current.clearLayers()
    }
  }

  /** map initialization */
  useEffect(() => {
    try {
      map = L.map('map', {
        center: [49.945963, 82.613922],
        zoom: 10,
        minZoom: 1,
        zoomControl: false,
        attributionControl: false
      })
      L.tileLayer(mapType, {
        subdomains: ['mt0', 'mt1', 'mt2', 'mt3'],
        maxZoom: 18,
        id: 'baselayer'
      }).addTo(map)
      L.control.attribution({
        position: 'bottomright'
      }).addTo(map)
      // map.fitBounds([[47.12995075666307, 52.064208984375], [51.1807, 71.461]])

      // Searched geometries
      searchGeometryRef.current = L.featureGroup().addTo(map)
      showLayerRef.current = L.featureGroup().addTo(map)
      drawnLayerRef.current = L.featureGroup().addTo(map)
      tileLayerRef.current = L.featureGroup().addTo(map)
      // heatmapLayer.current = HeatmapLayer()
      // heatmapLayer.current.addTo(map)

      mapRef.current = map
      // Pane for tiles topping on the map
      const topPane = mapRef?.current.createPane('leaflet-top-pane', mapRef.current.getPanes().mapPane)
      paneRef.current = topPane

      // Adding Controls
      MapControls(mapRef.current, t)
      mapRef.current.invalidateSize(9)
    } catch (e) {

    }
  }, [])
  useEffect(() => {
    // mapRef.current.removeLayer('baselayer')
    mapRef.current.attributionControl.setPrefix(Cookies.get('pref-attr') || `&copy; ${t('common.tablet')} ${t('common.agro')} v 3.0 , &copy; <a href="https://google.com/">Google</a> &copy; <a href="http://maps.google.com">Google Map</a> contributors`)
    L.tileLayer(mapType, {
      maxZoom: 18,
      id: 'baselayer'
    }).addTo(mapRef.current)
  }, [mapType])
  useEffect(() => {
    searchGeometryRef.current.clearLayers()
    jsonToDisplay.forEach((f) => {
      const mask = L.geoJSON(JSON.parse(f.json))
      const name = JSON.parse(f.json).properties.name
      if (JSON.parse(f.json).geometry?.type === 'Point') {
        mask.bindTooltip(() => name, { permanent: true })
        mask.addEventListener('mouseover', () => {
          mask.unbindTooltip()
          const time = new Date(f.createdAt)
          mask.bindTooltip(() => `${t('common.created')}: ${time.toLocaleString('ru-RU')}`, { permanent: true })
        })
        mask.addEventListener('mouseout', () => {
          mask.unbindTooltip()
          mask.bindTooltip(() => !f.sel ? (name, { permanent: true }) : (`${name}, сезон культуры ${chosenCulture} - ${chosenYear}`))
        })
      } else {
        if (f.disp) {
          mask.setStyle({ color: '#ff0000' })
        } else {
          mask.setStyle({ color: '#ffffff' })
        }
        if (f.croptype) {
          mask.setStyle({ color: cultureColors[f.croptype] })
        }
        mask.bindTooltip(() => `${name} ${f.croptype || ''}`)
        mask.addEventListener('click', () => { setfieldMaster(f.id); setSelField(f) })
        mask.addEventListener('dblclick', () => {
          if (window.innerWidth > 840) {
            fitBounds(JSON.parse(f.json))
            CloseOtherModals()
            // setDataModalShow(true)
            setFieldsListShow(true)
            setSelField(f)
            setFieldDataViewerShow(true)
            setfieldMaster(f.id)
          } else {
            window.open(`/datasave/${f.id}`, '_blank')
          }
        })
      }
      mask.addEventListener('mouseover', () => (mask.openTooltip()))
      searchGeometryRef.current.addLayer(mask)
    })
  }, [jsonToDisplay, upd])
  /** Handling drawing on map */
  const handleDrawCreated = React.useCallback(e => {
    clearLayers()
    const layer = e.layer

    if (e.layerType === 'marker') {
      CloseOtherModals()
      setCreateMarkerModalShow(true)
      const gjson = layer.toGeoJSON()
      gjson.properties.name = '???'
      setMarkerJSON(JSON.stringify(gjson))
      drawnLayerRef.current.addLayer(layer)
    } else {
      const area = L.GeometryUtil.geodesicArea(layer.getLatLngs()[0])
      const gjson = layer.toGeoJSON()
      gjson.properties = {
        name: 'test',
        area: area
      }
      saveJSON(JSON.stringify(gjson))
      if (area <= MAX_AREA) {
        // setSearchLayer(() => layer.toGeoJSON())
      } else {
        Alert.info(t('errors.bigarea'), 5000)
      }
      drawnLayerRef.current.addLayer(layer)
    }
  }, [])
  useEffect(() => {
    // mapRef.current.on('draw:created', function (e) {
    //   // const type = e.layerType
    //   const layer = e.layer
    //   setSearchLayer(layer.toGeoJSON())
    //   searchGeometryRef.current.clearLayers()
    //   searchGeometryRef.current.addLayer(e.layer)
    // })
    mapRef.current.on('draw:created', handleDrawCreated)
  }, [handleDrawCreated])

  useEffect(() => {
    if (chosenYear) {
      // setJSON([])
      const orig = jsonToDisplay

      orig.forEach(field => {
        field.disp = false
        const tempfield = field
        fieldData.fields.forEach(note => {
          if (field.id === note.field_id && note.season === chosenYear) {
            field.croptype = note.croptype
            if (chosenCulture && note.croptype === chosenCulture) {
              field.disp = true
            }
          }
        })
      })
      setJSON(orig)
      updateF(upd + 1)
    }
  }, [chosenCulture, chosenYear])

  const fitBounds = (field) => {
    const mask = L.geoJSON(field)

    if (field.geometry?.type === 'Point') {
      const lat = field.geometry.coordinates[1]
      const lng = field.geometry.coordinates[0]
      mapRef.current.panTo([lat, lng], 10)
    } else {
      mapRef.current.fitBounds(mask.getLayers()[0].getLatLngs())
    }
  }
  const determineTextColor = (backgroundColor) => {
    // Convert the hex color to RGB
    if (!backgroundColor) return '#FFFFFF'
    const hexColor = backgroundColor.slice(1)
    const bigint = parseInt(hexColor, 16)
    const r = (bigint >> 16) & 255
    const g = (bigint >> 8) & 255
    const b = bigint & 255

    // Calculate the perceived brightness
    const brightness = (r * 299 + g * 587 + b * 114) / 1000

    // Use white text on dark backgrounds, black text on light backgrounds
    return brightness > 128 ? '#000000' : '#ffffff'
  }
  return (
    <>
      <nav className='navbar-side-new'>
        {/* <button className='new-link-element'>
                <img src='/logo192.png' alt='' width='40px'/>
      </button> */}
        <button className={`new-link-element ${CreateModalShow ? 'dropdown-chosen' : ''}`} onClick={() => { CloseOtherModals(); setCreateModalShow(!CreateModalShow) }} title={t('map.field.create')}>
          {/* <svg xmlns="http://www.w3.org/2000/svg" style={{ height: '' }} viewBox="0 0 24 24" fill="none" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="feather feather-list"><line x1="8" y1="6" x2="21" y2="6"></line><line x1="8" y1="12" x2="21" y2="12"></line><line x1="8" y1="18" x2="21" y2="18"></line><line x1="3" y1="6" x2="3" y2="6"></line><line x1="3" y1="12" x2="3" y2="12"></line><line x1="3" y1="18" x2="3" y2="18"></line></svg> */}
          <FontAwesomeIcon icon={FA.faEdit} />
          <h5>{t('map.field.create')}</h5>
        </button>
        <button className={`new-link-element ${FieldsListShow ? 'dropdown-chosen' : ''}`} onClick={() => { CloseOtherModals(); setFieldsListShow(!FieldsListShow) }} title={t('map.sidebar.fieldslist')}>
          <FontAwesomeIcon icon={FA.faList} />
          {/* <svg width="100px" height="100px" viewBox="0 0 100 100" version="1.1" xmlns="http://www.w3.org/2000/svg">
    <g id="35.-Layers" strokeWidth="1" fillRule="evenodd" strokeLinecap="round" strokeLinejoin="round">
        <g transform="translate(2.000000, 2.000000)" strokeWidth="4">
            <path d="M21.5152012,36.16 L1.80861194,46.0132946 C-0.567382154,47.2012917 -0.562573357,49.1211127 1.80861194,50.3067054 L43.7065892,71.255694 C46.0825833,72.4436911 49.9222255,72.4412867 52.2934108,71.255694 L94.1913881,50.3067054 C96.5673822,49.1187083 96.5625734,47.1988873 94.1913881,46.0132946 L74.4847988,36.16" id="Layer-1"></path>
            <path d="M94.0046097,21.7599054 C96.4789498,22.9970755 96.4745809,25.005109 94.0046097,26.2400946 L52.4801891,47.0023049 C50.005849,48.2394749 45.9897821,48.2372905 43.5198109,47.0023049 L1.99539028,26.2400946 C-0.478949844,25.0029245 -0.474580913,22.994891 1.99539028,21.7599054 L43.5198109,0.99769514 C45.994151,-0.239474922 50.0102179,-0.237290456 52.4801891,0.99769514 L94.0046097,21.7599054 Z" id="Layer-2"></path>
            <path d="M20.8944465,60.3103773 C16.6531499,62.4310256 10.2912051,65.611998 1.80861194,69.8532946 C-0.567382154,71.0412917 -0.562573357,72.9611127 1.80861194,74.1467054 L43.7065892,95.095694 C46.0825833,96.2836911 49.9222255,96.2812867 52.2934108,95.095694 L94.1913881,74.1467054 C96.5673822,72.9587083 96.5625734,71.0388873 94.1913881,69.8532946 C85.6501144,65.5826578 79.2441591,62.3796801 74.9735223,60.2443617" id="Layer-3"></path>
        </g>
    </g>
</svg> */}
          <h5>{t('map.sidebar.fieldslist')}</h5>
        </button>

        {/* <button className={`new-link-element ${NotesListShow ? 'dropdown-chosen' : ''}`} onClick={() => { CloseOtherModals(); setNotesListShow(!NotesListShow) }}>
              <h5>Ваши заметки</h5>
              </button> */}

        <button className={`new-link-element ${UploadModalShow ? 'dropdown-chosen' : ''}`} onClick={() => { CloseOtherModals(); setUploadModalShow(!UploadModalShow) }} title={t('map.sidebar.upload')}>
          <FontAwesomeIcon icon={FA.faFileDownload} />
          {/* <svg version="1.1" xmlns="http://www.w3.org/2000/svg">
    <g id="Iconly/Bold/Location" strokeWidth="1" fill="none" fillRule="evenodd">
        <g id="Location" transform="translate(3.500000, 2.000000)" fillRule="nonzero">
            <path d="M8.5299,-1.99535389e-05 C10.7899,0.01 12.9499,0.91 14.5299,2.49 C16.1199,4.08 17.0099,6.23 16.9999834,8.46 L16.9999834,8.46 L16.9999834,8.51 C16.9399,11.54 15.2399,14.33 13.1199,16.51 C11.9199,17.74 10.5899,18.83 9.1399,19.75 C8.7499,20.08 8.1799,20.08 7.7899,19.75 C5.6399,18.35 3.7399,16.59 2.1999,14.54 C0.8499,12.76 0.0799,10.62 -0.0001,8.39 C0.0199,3.74 3.8399,-0.01 8.5299,-1.99535389e-05 Z M8.5299,5.93 C7.0399,5.93 5.8399,7.12 5.8399,8.6 C5.8399,10.061 7.0199,11.24 8.4999,11.26 L8.4999,11.26 L8.5299,11.26 C9.2399,11.26 9.9199,10.99 10.4199,10.5 C10.9399,9.99 11.2309,9.311 11.2309,8.6 C11.2309,7.12 10.0199,5.93 8.5299,5.93 Z"></path>
        </g>
    </g>
</svg> */}
          <h5>{t('map.sidebar.upload')}</h5>
        </button>
        {/* <Whisper
              trigger='hover' placement={'right'} speaker={
                <Tooltip>Рекомендации
              <button className={`new-link-element ${RecommendShow ? 'dropdown-chosen' : ''}`} onClick={() => {
                if (window.innerWidth > 840) {
                  CloseOtherModals()
                  setRecommendShow(!RecommendShow)
                } else {
                  window.open('/recommendation', '_blank')
                }
              }}>
                <FontAwesomeIcon icon={FA.faChartBar} />
              </button>
            </Whisper> */}
        <button
          className={`new-link-element ${MapTypesShow ? 'dropdown-chosen' : ''}`} onClick={() => {
            CloseOtherModals()
            setMapTypesShow(!MapTypesShow)
          }} title={t('map.sidebar.tile')}
        >
          <FontAwesomeIcon icon={FA.faLayerGroup} />
          <h5>{t('map.sidebar.tile')}</h5>
        </button>
        <button className='new-link-element' onClick={() => i18n.changeLanguage(i18n.language === 'ru' ? 'kz' : i18n.language === 'kz' ? 'en' : 'ru')} title='Смена языка'>
          <FontAwesomeIcon icon={FA.faGlobe} />
          <h5>
            {t('map.sidebar.lang')} {i18n.language.toUpperCase()}
          </h5>
        </button>

        <button onClick={() => { window.location.href = '/profile' }} className='new-link-element' title={t('common.return')}>
          <FontAwesomeIcon icon={FA.faBackward} />
          <h5>{t('common.return')}</h5>
        </button>
        {/*
              <button onClick={() => update(updateKey + 1)} className='new-link-element' title={t('common.return')}>
                <FontAwesomeIcon icon={FA.faDotCircle} />
                <h5>Обновить</h5>
              </button> */}

      </nav>
      <div className='navbar-side-wrapper'>
        {/* <nav className='navbar-side'>
          <div className='navbar nav-side'>
            <button className='new-link-element' style={{ width: 'fit-content', padding: '15px', fontWeight: 'bolder', fontSize: '20px', marginRight: '50px', color: 'rgb(0,82,180)' }} onClick={() => { window.location.href = '/' }}><h5 style={{ fontSize: '40px' }}>Smart</h5><h5 style={{ fontSize: '40px' }}>&nbsp;Agronom</h5></button>

          </div>
        </nav> */}
        <div className='menu-list'>
          <LayerList adClass={!LayerListShow ? 'hidden' : ''} />
          <AttributeList adClass={!AttributeListShow ? 'hidden' : ''} />
          <DrawTools adClass={!DrawToolsShow ? 'hidden' : ''} />
          <CreateField updatekey={updateKey} drawLayer={drawnLayerRef.current} layer={showLayerRef.current} fitBounds={fitBounds} sendFunc={addField} fieldJSON={jsonToSave} adClass={!CreateModalShow ? 'hidden' : ''} />
          <CreateMarker updatekey={updateKey} drawLayer={drawnLayerRef.current} layer={showLayerRef.current} sendFunc={addField} fieldJSON={markerJSON} adClass={!CreateMarkerModalShow ? 'hidden' : ''} />
          <UpdateField updatekey={update} upkey={updateKey} fieldJSON={fieldToUpdate} adClass={!updateModalShow ? 'hidden' : ''} onHide={() => { setUpdateModalShow(false); setFieldsListShow(true) }} />
          <UploadFilesModal drawLayer={drawnLayerRef.current} saveJSON={saveJSON} searchRef={searchGeometryRef.current} onHide={() => { setUploadModalShow(false); setDataModalShow(false); setCreateModalShow(true) }} adClass={!UploadModalShow ? 'hidden' : ''} />
          <MapTypes adClass={!MapTypesShow ? 'hidden' : ''} slave setMap={setMap} hideFunc={setMapTypesShow} />
        </div>
        <FieldsListMain layer={showLayerRef.current} openEditor={setUpdateModalShow} openCharts={setChartModalShow} setFieldToUpdate={setFieldToUpdate} hideFunc={setFieldsListShow} showData={setDataModalShow} setFieldMaster={setfieldMaster} setFieldNameMaster={setfieldNameMaster} deleteFields={deleteFields} fitBounds={fitBounds} fields={jsonToDisplay} onHide={() => setFieldsListShow(false)} adClass={(!FieldsListShow && !NotesListShow) ? 'hidden' : ''} notesModal={NotesListShow} fieldsModal={FieldsListShow} openFieldViewer={setFieldDataViewerShow} setSelField={setSelField} FieldDataViewerShow={FieldDataViewerShow} />
      </div>
      <div className='menu-list'>
        <CreateField updatekey={updateKey} drawLayer={drawnLayerRef.current} layer={showLayerRef.current} fitBounds={fitBounds} sendFunc={addField} fieldJSON={jsonToSave} adClass={!CreateModalShow ? 'hidden' : ''} />
        <CreateMarker updatekey={updateKey} drawLayer={drawnLayerRef.current} layer={showLayerRef.current} sendFunc={addField} fieldJSON={markerJSON} adClass={!CreateMarkerModalShow ? 'hidden' : ''} />
        <UpdateField updatekey={update} key={updateKey} fieldJSON={fieldToUpdate} adClass={!updateModalShow ? 'hidden' : ''} onHide={() => { setUpdateModalShow(false); setFieldsListShow(true) }} />
        <UploadFilesModal drawLayer={drawnLayerRef.current} saveJSON={saveJSON} searchRef={searchGeometryRef.current} onHide={() => { setUploadModalShow(false); setDataModalShow(false); setCreateModalShow(true) }} adClass={!UploadModalShow ? 'hidden' : ''} />
        <div className='cultures-list'>
          <h4>{t('map.filter.year')}</h4> <Button color='red' onClick={() => { setYear(undefined); setCulture(undefined); update(updateKey + 1) }}>{t('map.filter.reset')}</Button>
          {
          ['2017', '2018', '2019', '2020', '2021', '2022', '2023'].map((e, key) =>
            <Button color={e === chosenYear && 'blue'} key={key * 23} onClick={() => setYear(e)}>{e}</Button>
          )
        }
          <h4>Культуры:</h4>
          {
          fieldData?.cultures && cultureColors && fieldData.cultures.map((e, key) =>
            <Button style={{ background: cultureColors[e], color: determineTextColor(cultureColors[e]) }} key={key * 19}>{e}</Button>
          )
        }
        </div>
      </div>
      {/* <DataSave adClass={!DataModalShow ? 'hidden' : ''} slave fieldId_master={fieldMaster} hideFunc={setDataModalShow} /> */}
      <FieldDataModal hidden={FieldDataViewerShow} chosenField={selField} closeOtherModals={CloseOtherModals} setFieldToUpdate={setFieldToUpdate} openEditor={setUpdateModalShow} adClass={!FieldDataViewerShow ? 'hidden' : ''} deleteFields={deleteFields} />

      <div id='map'>
        {isLoading && <Loader inverse size='md' center content='loading' className='z-600' />}
      </div>
    </>
  )
}

export default Map
