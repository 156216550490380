import React, { useEffect, useState } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import * as FA from '@fortawesome/free-solid-svg-icons'
import { getFields, addField, removeField, getGeoJSON, getFieldProps } from '../http/API'
import { Input, Alert, Button, ControlLabel, InputGroup, SelectPicker } from 'rsuite'
import Cookies from 'js-cookie'
import { useParams } from 'react-router'
import { useTranslation } from 'react-i18next'
import ChartModal from './charts/Chart'
import ChartModalYear from './charts/ChartYear'

function AnalysisOHMK2 ({ slave, fieldId_master, adClass, hideFunc }) {
  const { t, i18n } = useTranslation()
  const years = [2017, 2018, 2019, 2020, 2021, 2022, 2023].map(e => ({ label: e, value: e }))
  const [chosenField, setField] = useState()
  const [chosenYear, setYear] = useState()
  return (
    <div className='data calcmodal-body'>
      <a style={{ width: 'fit-content', float: 'right', fontSize: '20px', padding: '5px' }} className='newbutton nav-element side dropdown' href='/maps'>
        <FontAwesomeIcon icon={FA.faHome} />
      </a>
      {/* <a style={{ width: 'fit-content', float: 'right', fontSize: '20px', padding: '5px' }} className='newbutton nav-element side dropdown' onClick={() => i18n.changeLanguage(i18n.language === 'ru' ? 'kz' : i18n.language === 'kz' ? 'en' : 'ru')}>
        <h5 style={{ margin: 'auto' }}>{i18n.language.toUpperCase()}</h5>
      </a> */}
      <h3>Схемы севооборота полей</h3>
      <hr />
      {/* <SelectPicker searchable={false} placeholder='Выберите поле' block id='croptype' onChange={e => setField(e)} data={fields.map((a) => { return { label: 'ОХМК - ' + a, value: a } })} /> */}
      <SelectPicker searchable={false} placeholder='Выберите год' block id='croptype' onChange={e => setYear(e)} data={years} />
      {
        chosenYear &&
          <>
            <img className='analysis-img' src={`img/img-cultures/${chosenYear}.jpeg`} alt='' />
          </>
      }
    </div>
  )
}

export default AnalysisOHMK2
