import React, { useEffect, useState } from 'react'
import jwt_decode from 'jwt-decode'
import Cookies from 'js-cookie'
import { FlexboxGrid, Modal, Form, Tooltip, Input, Button, Whisper, Alert } from 'rsuite'
import { activate } from '../http/API'
import { useTranslation } from 'react-i18next'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import * as FA from '@fortawesome/free-solid-svg-icons'
import { Link } from 'react-router-dom'

const C3IS = () => {
  const { t, i18n } = useTranslation()
  const token = Cookies.get('token')
  const userRole = token ? jwt_decode(token).role : undefined
  const decyph = token && jwt_decode(token)
  const [screenSize, getDimension] = useState({
    dynamicWidth: window.innerWidth,
    dynamicHeight: window.innerHeight
  })
  const setDimension = () => {
    getDimension({
      dynamicWidth: window.innerWidth,
      dynamicHeight: window.innerHeight
    })
  }

  useEffect(() => {
    window.addEventListener('resize', setDimension)

    return () => {
      window.removeEventListener('resize', setDimension)
    }
  }, [screenSize])
  return (
    <>
      <iframe title='C3IS' src='https://ee-ukgnail.projects.earthengine.app/view/c3is-kgs' width={screenSize.dynamicWidth} height={screenSize.dynamicHeight} style={{ overflow: 'hidden' }} />
    </>
  )
}

export default C3IS
