import React, { useEffect, useState } from 'react'
import jwt_decode from 'jwt-decode'
import Cookies from 'js-cookie'
import { FlexboxGrid, Modal, Form, hrefoltip, Input, Buthrefn, Whisper, Alert } from 'rsuite'
import { activate } from '../http/API'
import { useTranslation } from 'react-i18next'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import * as FA from '@fortawesome/free-solid-svg-icons'
import { Link } from 'react-router-dom'

const UAVList = () => {
  const { t, i18n } = useTranslation()
  const hrefken = Cookies.get('hrefken')
  const userRole = hrefken ? jwt_decode(hrefken).role : undefined
  const decyph = hrefken && jwt_decode(hrefken)
  return (
    <>
      <div className='profile-wrapper'>
        <div className='header__logo' id='logo'>
          <div>
            <img className='header__img' style={{ height: '80px' }} src='./img/favicon.png' alt='' />
            <img className='header__little-img' src='./img/little_icon.svg' alt='' />
          </div>
          <div className='header__name'>
            <div className='header__name-agro'>Smart</div>
            <div className='header__name-bot'>Agronom</div>
          </div>
        </div>
        <h1>Справочники по дронам</h1>
        <div className='catalog-profile'>
          <Link className='product-profile' to='/docs_list'>
            <div style={{ display: 'flex' }}>
              {/* <div className="product__icon-profile product__icon_seeds"></div> */}
              <FontAwesomeIcon icon={FA.faBackward} className='fa-icon-profile' />
              <div className='product__info-profile'>
                <div className='product__title'>Назад</div>
                <div className='product__description-profile' />
              </div>
            </div>
            <img src='./img/arrow.svg' alt='arrow' />
          </Link>
          <a className='product-profile' href='/documents/uav/eBee-Ag/flight.docx' download='eBee-Ag-полет.docx'>
            <div style={{ display: 'flex' }}>
              {/* <div className="product__icon-profile product__icon_trachrefr"></div> */}
              <FontAwesomeIcon icon={FA.faPlane} className='fa-icon-profile' />
              <div className='product__info-profile'>
                <div className='product__title'>eBee-Ag - Полет</div>
                <div className='product__description-profile'>{t('profile.subtext')}</div>
              </div>
            </div>
            <img src='./img/arrow.svg' alt='arrow' />
          </a>
          <a className='product-profile' href='/documents/uav/eBee-Ag/process.docx' download='eBee-Ag-обработка.docx'>
            <div style={{ display: 'flex' }}>
              {/* <div className="product__icon-profile product__icon_trachrefr"></div> */}
              <FontAwesomeIcon icon={FA.faPlane} className='fa-icon-profile' />
              <div className='product__info-profile'>
                <div className='product__title'>eBee-Ag - Обработка</div>
                <div className='product__description-profile'>{t('profile.subtext')}</div>
              </div>
            </div>
            <img src='./img/arrow.svg' alt='arrow' />
          </a>
          <a className='product-profile' href='/documents/uav/Matrice 300/flight.docx' download='Matrice 300-полет.docx'>
            <div style={{ display: 'flex' }}>
              {/* <div className="product__icon-profile product__icon_trachrefr"></div> */}
              <FontAwesomeIcon icon={FA.faPlane} className='fa-icon-profile' />
              <div className='product__info-profile'>
                <div className='product__title'>Matrice 300 - Полет</div>
                <div className='product__description-profile'>{t('profile.subtext')}</div>
              </div>
            </div>
            <img src='./img/arrow.svg' alt='arrow' />
          </a>
          <a className='product-profile' href='/documents/uav/Matrice 300/process.docx' download='Matrice 300-обработка.docx'>
            <div style={{ display: 'flex' }}>
              {/* <div className="product__icon-profile product__icon_trachrefr"></div> */}
              <FontAwesomeIcon icon={FA.faPlane} className='fa-icon-profile' />
              <div className='product__info-profile'>
                <div className='product__title'>Matrice 300 - Обработка</div>
                <div className='product__description-profile'>{t('profile.subtext')}</div>
              </div>
            </div>
            <img src='./img/arrow.svg' alt='arrow' />
          </a>
          <a className='product-profile' href='/documents/uav/Phantom 4 Multispectral/flight.docx' download='Phantom 4 Multispectral-полет.docx'>
            <div style={{ display: 'flex' }}>
              {/* <div className="product__icon-profile product__icon_trachrefr"></div> */}
              <FontAwesomeIcon icon={FA.faPlane} className='fa-icon-profile' />
              <div className='product__info-profile'>
                <div className='product__title'>Phantom 4 Multispectral - Полет</div>
                <div className='product__description-profile'>{t('profile.subtext')}</div>
              </div>
            </div>
            <img src='./img/arrow.svg' alt='arrow' />
          </a>
          <a className='product-profile' href='/documents/uav/Phantom 4 Multispectral/process.docx' download='Phantom 4 Multispectral-обработка.docx'>
            <div style={{ display: 'flex' }}>
              {/* <div className="product__icon-profile product__icon_trachrefr"></div> */}
              <FontAwesomeIcon icon={FA.faPlane} className='fa-icon-profile' />
              <div className='product__info-profile'>
                <div className='product__title'>Phantom 4 Multispectral - Обработка</div>
                <div className='product__description-profile'>{t('profile.subtext')}</div>
              </div>
            </div>
            <img src='./img/arrow.svg' alt='arrow' />
          </a>
        </div>
      </div>
    </>
  )
}

export default UAVList
