import React, { useEffect, useState } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import * as FA from '@fortawesome/free-solid-svg-icons'
import { getFields, addField, removeField, getGeoJSON, getFieldProps } from '../http/API'
import { Input, Alert, Button, ControlLabel, InputGroup, SelectPicker } from 'rsuite'
import Cookies from 'js-cookie'
import { useParams } from 'react-router'
import { useTranslation } from 'react-i18next'
import ChartModal from './charts/Chart'
import ChartModalYear from './charts/ChartYear'

function AnalysisOHMK ({ slave, fieldId_master, adClass, hideFunc }) {
  const { t, i18n } = useTranslation()
  const fields = [4, 6, 7, 18, 40, 43, 58, 64, 68]
  const chems = [
    {
      label: 'Зарегистированные точки',
      value: 'points'
    },
    {
      label: 'Обменный калий',
      value: 'potassium'
    },
    {
      label: 'Подвижный фосфор',
      value: 'phosphorus'
    },
    {
      label: 'Легкогидролизуемый азот',
      value: 'nitrogen'
    },
    {
      label: 'рН солевая',
      value: 'ph_sol'
    },
    {
      label: 'рН водная',
      value: 'ph_humid'
    },
    {
      label: 'Содержание гумуса',
      value: 'humus'
    },
    {
      label: 'Содержание серы',
      value: 'sulfur'
    }
  ]
  const [chosenField, setField] = useState()
  const [chosenChem, setChem] = useState()
  return (
    <div className='data calcmodal-body'>
      <a style={{ width: 'fit-content', float: 'right', fontSize: '20px', padding: '5px' }} className='newbutton nav-element side dropdown' href='/maps'>
        <FontAwesomeIcon icon={FA.faHome} />
      </a>
      {/* <a style={{ width: 'fit-content', float: 'right', fontSize: '20px', padding: '5px' }} className='newbutton nav-element side dropdown' onClick={() => i18n.changeLanguage(i18n.language === 'ru' ? 'kz' : i18n.language === 'kz' ? 'en' : 'ru')}>
        <h5 style={{ margin: 'auto' }}>{i18n.language.toUpperCase()}</h5>
      </a> */}
      <h3>Агрохимический анализ полей</h3>
      <hr />
      <SelectPicker searchable={false} placeholder='Выберите поле' block id='croptype' onChange={e => setField(e)} data={fields.map((a) => { return { label: 'ОХМК - ' + a, value: a } })} />
      <SelectPicker searchable={false} placeholder='Выберите картограмму' block id='croptype' onChange={e => setChem(e)} data={chems} />
      {
        chosenChem && chosenField &&
          <>
            {/* <h5>Легенда:</h5> */}
            <img className='analysis-img' src={`img/analysis/legend/${chosenChem}.png`} alt='' />
            <br />
            <img className='analysis-img' src={`img/analysis/ohmk/${chosenField}/${chosenChem}.png`} />
            <br />
            <h3>Общая карта</h3>
            <img className='analysis-img' src={`img/analysis/fall/${chosenChem}.jpeg`} />
          </>
      }
    </div>
  )
}

export default AnalysisOHMK
