/* eslint-disable prefer-regex-literals */
import React, { useState, useEffect } from 'react'
import { Button, FlexboxGrid, Input, DatePicker, Whisper, Tooltip, InputGroup, Icon, Alert } from 'rsuite'
import { registration, login } from '../http/API'
import { connect } from 'react-redux'
import { useTranslation } from 'react-i18next'
import {
  BrowserRouter,
  Link
} from 'react-router-dom'
import '../styles/auth.css'
import Cookies from 'js-cookie'

const Auth = (props) => {
  const { t } = useTranslation()
  const [authType, setType] = useState('login')
  const [datareg, setDataReg] = useState({
    first_name: '',
    last_name: '',
    email: '',
    password: '',
    place: '',
    rkey: ''
  })
  const [datalogin, setDatalogin] = useState({
    email: '',
    password: ''
  })
  const [eye, setEye] = useState('eye')
  const [passwordType, setPasswordType] = useState('password')
  const [loading, setLoading] = useState(false)
  const togglePassword = () => {
    if (eye === 'eye') {
      setEye('eye-slash')
      setPasswordType('text')
    } else {
      setEye('eye')
      setPasswordType('password')
    }
  }
  const onSubmitLogin = async () => {
    const tempErrors = validate(datalogin)
    // setErrors(temp_errors);
    if (Object.keys(tempErrors).length === 0) {
      setLoading(true)
      try {
        const resp = await login(datalogin.email, datalogin.password).then((res) => {
          setLoading(false)
          const response = res.response
          if (response.success) {
            Alert.success(response.message)
            setTimeout(2000)
            window.location.href = '/profile'
          } else {
            Alert.error(response.message)
          }
        })
      } catch (e) {
        setLoading(false)
        Alert.error(t('error.noconnection'))
      }
    } else {
      Alert.error(t('error.checkdata'))
    }
  }
  const onSubmitReg = async () => {
    const tempErrors = validate(datareg)
    // setErrors(temp_errors);
    if (Object.keys(tempErrors).length === 0) {
      try {
        const resp = await registration(datareg).then((res) => {
          setLoading(false)
          const response = res.response
          if (response.success) {
            Alert.success(response.message)
            window.location.href = '/profile'
          } else {
            Alert.error(response.message)
          }
        })
      } catch (e) {
        setLoading(false)
        Alert.error(t('error.connectionerror'))
      }
    }
  }

  const validate = (data) => {
    const errors = {}
    if (!data.email) {
      errors.email = t('Errors.empty_email')
    }
    const emailPattern = new RegExp('^[a-z0-9]+(.[_a-z0-9]+)*@[a-z0-9-]+(.[a-z0-9-]+)*(.[a-z]{2,15})$', 'i')
    if (data.email && !emailPattern.test(data.email.toLowerCase())) {
      errors.email = t('Errors.valid_email')
    }
    if (!data.password) {
      errors.password = t('Errors.empty_password')
    }
    const passwordPattern = new RegExp('^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])', 'i')
    if (!passwordPattern.test(data.password)) {
      errors.password = t('Errors.valid_password')
    }
    return errors
  }

  return (
    <div className='auth-wrapper-parent'>
      <div className='auth-wrapper'>
        <div className='auth-modal'>
          <h3>{t('auth.title')}</h3>
          <br />
          <div className='auth-strip'>
            <button onClick={() => setType('login')} style={authType == 'login' ? { backgroundColor: 'white' } : {}}>{t('welcome.signin')}</button>
            <button onClick={() => setType('reg')} style={authType == 'reg' ? { backgroundColor: 'white' } : {}}>{t('welcome.signup')}</button>
          </div>
          <div className='auth-login' hidden={authType !== 'login'}>
            <h5>{t('common.email')}</h5>
            <Input
              placeholder={t('common.email')} type='email' id={datalogin.email} onChange={e => setDatalogin({
                ...datalogin,
                email: e
              })} size='md'
            />
            <h5>{t('common.password')}</h5>
            <InputGroup inside>
              <Input
                placeholder={t('common.password')} size='md'
                id={datalogin.password}
                type={passwordType}
                onChange={e => setDatalogin({
                  ...datalogin,
                  password: e
                })}
              />
              <InputGroup.Addon>
                <Icon icon={eye} onClick={togglePassword} />
              </InputGroup.Addon>
            </InputGroup>
            <br />
            <div className='auth-flex'>
              {/* <Button
            appearance='primary' size='sm' id='changetype' onClick={() => setType('')} className='m-r-15  inc'
          >
            Назад
          </Button> */}
              <Button
                appearance='primary' size='xxl' onClick={onSubmitLogin} loading={loading}
              >
                {t('login.button')}
              </Button>
            </div>
          </div>
          <div className='auth-reg' hidden={authType !== 'reg'}>
            <FlexboxGrid>
              <h5>{t('common.name')}</h5>
              <FlexboxGrid.Item colspan={24} className='p-15'>
                <Input
                  placeholder={t('common.name')} onChange={e => setDataReg({
                    ...datareg,
                    first_name: e
                  })} size='md'
                />
              </FlexboxGrid.Item>

              <h5>{t('common.surname')}</h5>
              <FlexboxGrid.Item colspan={24} className='p-15'>
                <Input
                  placeholder={t('common.surname')} onChange={e => setDataReg({
                    ...datareg,
                    last_name: e
                  })} size='md'
                />
              </FlexboxGrid.Item>
              <h5>{t('common.place_dif')}</h5>
              <FlexboxGrid.Item colspan={24} className='p-15'>
                <Input
                  placeholder={t('common.place_dif')} onChange={e => setDataReg({
                    ...datareg,
                    place: e
                  })} size='md'
                />
              </FlexboxGrid.Item>

              <h5>{t('common.email_dif')}</h5>
              <FlexboxGrid.Item colspan={24} className='p-15'>
                <Input
                  placeholder={t('common.email_dif')} csize='md' type='email' onChange={e => setDataReg({
                    ...datareg,
                    email: e
                  })}
                />
              </FlexboxGrid.Item>

              <h5>{t('common.password')}</h5>
              <FlexboxGrid.Item colspan={24} className='p-15'>
                <Whisper
                  trigger='focus' speaker={
                    <Tooltip>{t('signup.passwordtooltip')} </Tooltip>
                }
                >
                  <InputGroup inside>
                    <Input
                      placeholder={t('common.password')} size='md'
                      type={passwordType}
                      onChange={e => setDataReg({
                        ...datareg,
                        password: e
                      })}
                    />
                    <InputGroup.Addon>
                      <Icon icon={eye} onClick={togglePassword} />
                    </InputGroup.Addon>
                  </InputGroup>
                </Whisper>
              </FlexboxGrid.Item>
              <h5>{t('common.regkey')}</h5>
              <FlexboxGrid.Item colspan={24} className='p-15'>
                <Whisper
                  trigger='active' speaker={
                    <Tooltip>{t('signup.regkeytooltip')} <a href='https://forms.gle/ZVNpMFMhtxgMbpFK9' target='_blank' rel='noreferrer'>{t('profile.activatelink')}</a> </Tooltip>
                }
                >
                  <Input
                    placeholder={t('common.regkey')} csize='md' onChange={e => setDataReg({
                      ...datareg,
                      rkey: e
                    })}
                  />
                </Whisper>
              </FlexboxGrid.Item>
            </FlexboxGrid>
            <div className='auth-flex'>
              <Button
                appearance='primary' size='sm' onClick={onSubmitReg} loading={loading} className='m-r-15  inc'
              >
                {t('welcome.signup')}
              </Button>
            </div>
          </div>
          <Link to='/profile'><h3>Войти без аккаунта</h3></Link>
        </div>

        <div hidden>
          <h2>Добро пожаловать в SmartAgronom!</h2>
          <br />
          <p style={{ fontSize: 'large' }}>Пожалуйста, войдите в свой аккаунт или создайте новый!</p>
          <br />
          <div className='auth-flex'>
            <a id='changetype' href='/'>
              <Button appearance='primary' size='sm' id='changetype' className='m-r-15  inc'>
                Назад
              </Button>
            </a>
            <Button appearance='primary' size='sm' id='changetype' onClick={() => setType('reg')} className='m-r-15  inc'>
              Регистрация
            </Button>
            <Button appearance='primary' size='sm' id='changetype' onClick={() => setType('login')} className='m-r-15  inc'>
              Вход
            </Button>
          </div>
        </div>
        <div hidden>
          {/* <div hidden={authType !== 'login'}> */}
          <h2>Вход в аккаунт</h2>
          <br />
          <br />
          <Input
            placeholder={t('common.email')} type='email' id={datalogin.email} onChange={e => setDatalogin({
              ...datalogin,
              email: e
            })} size='md'
          />
          <InputGroup inside>
            <Input
              placeholder={t('common.password')} size='md'
              id={datalogin.password}
              type={passwordType}
              onChange={e => setDatalogin({
                ...datalogin,
                password: e
              })}
            />
            <InputGroup.Addon>
              <Icon icon={eye} onClick={togglePassword} />
            </InputGroup.Addon>
          </InputGroup>
          <div className='auth-flex'>
            <Button
              appearance='primary' size='sm' id='changetype' onClick={() => setType('')} className='m-r-15  inc'
            >
              Назад
            </Button>
            <Button
              appearance='primary' size='xxl' onClick={onSubmitLogin} loading={loading}
            >
              {t('login.button')}
            </Button>
          </div>
        </div>
        <div hidden>
          {/* <div hidden={authType !== 'reg'}> */}
          <h2>Регистрация нового аккаунта</h2>
          <FlexboxGrid>
            <FlexboxGrid.Item colspan={24} className='p-15'>
              <Input
                placeholder={t('common.name')} onChange={e => setDataReg({
                  ...datareg,
                  first_name: e
                })} size='md'
              />
            </FlexboxGrid.Item>

            <FlexboxGrid.Item colspan={24} className='p-15'>
              <Input
                placeholder={t('common.surname')} onChange={e => setDataReg({
                  ...datareg,
                  last_name: e
                })} size='md'
              />
            </FlexboxGrid.Item>
            <FlexboxGrid.Item colspan={24} className='p-15'>
              <Input
                placeholder={t('common.place_dif')} onChange={e => setDataReg({
                  ...datareg,
                  place: e
                })} size='md'
              />
            </FlexboxGrid.Item>

            <FlexboxGrid.Item colspan={24} className='p-15'>
              <Input
                placeholder={t('common.email_dif')} csize='md' type='email' onChange={e => setDataReg({
                  ...datareg,
                  email: e
                })}
              />
            </FlexboxGrid.Item>

            <FlexboxGrid.Item colspan={24} className='p-15'>
              <Whisper
                trigger='focus' speaker={
                  <Tooltip>{t('signup.passwordtooltip')} </Tooltip>
                }
              >
                <InputGroup inside>
                  <Input
                    placeholder={t('common.password')} size='md'
                    type={passwordType}
                    onChange={e => setDataReg({
                      ...datareg,
                      password: e
                    })}
                  />
                  <InputGroup.Addon>
                    <Icon icon={eye} onClick={togglePassword} />
                  </InputGroup.Addon>
                </InputGroup>
              </Whisper>
            </FlexboxGrid.Item>
            <FlexboxGrid.Item colspan={24} className='p-15'>
              <Whisper
                trigger='active' speaker={
                  <Tooltip>{t('signup.regkeytooltip')} <a href='https://forms.gle/ZVNpMFMhtxgMbpFK9' target='_blank' rel='noreferrer'>{t('profile.activatelink')}</a> </Tooltip>
                }
              >
                <Input
                  placeholder={t('common.regkey')} csize='md' onChange={e => setDataReg({
                    ...datareg,
                    rkey: e
                  })}
                />
              </Whisper>
            </FlexboxGrid.Item>
          </FlexboxGrid>
          <div className='auth-flex'>
            <Button
              appearance='primary' size='sm' id='changetype' onClick={() => setType('')} className='m-r-15  inc'
            >
              Назад
            </Button>
            <Button
              appearance='primary' size='sm' onClick={onSubmitReg} loading={loading} className='m-r-15  inc'
            >
              {t('welcome.signup')}
            </Button>
          </div>
        </div>
      </div>
    </div>

  )
}

export default Auth
