import { $authHost, $host } from './index'
import jwt_decode from 'jwt-decode'
import Cookies from 'js-cookie'
// import { openDB } from 'idb'
import store from 'store2'
import { Alert } from 'rsuite'

const getOnlineStatus = () => {
  const stat = typeof navigator !== 'undefined' &&
  typeof navigator.onLine === 'boolean'
    ? navigator.onLine
    : true
  stat === false && Alert.warning('Вы находитесь в оффлайн-режиме с ограниченными возможностями!')
  return stat
}

const recordDB = (name, value) => {
  console.log('recording ', value, ' to ', name)
  store.remove(name)
  store.add(name, value)
  console.log('recorded ', value, ' to ', name)
}

const getDB = (name) => {
  console.log('getting ', name)
  console.log(store.get(name))
  var data = JSON.parse(store.get(name)) || data
  const success = data !== null && data !== undefined
  return { data, success, message: success ? 'Успешно получена локальная база данных' : 'Запись в оффлайн-базе не найдена!' }
}

export const addContactMessage = async (props) => {
  const { data } = await $host.post('user/contact', { ...props })
  return { response: data }
}
export const registration = async (props) => {
  if (getOnlineStatus()) {
    const { data } = await $host.post('user/registration', { ...props })
    if (data.success) {
      await login(props.email, props.password)
      return { decyph: jwt_decode(data.token), response: data }
    } else {
      return { response: data }
    }
  } else {
    return { response: { success: false, message: 'Функция недоступна в оффлайн-режиме!' } }
  }
}

export const login = async (email, password) => {
  if (getOnlineStatus()) {
    const { data } = await $host.post('user/login', { email, password })
    console.log(data)
    if (data.success) {
      Cookies.set('first_name', data.user.first_name)
      Cookies.set('last_name', data.user.last_name)
      Cookies.set('email', data.user.email)
      Cookies.set('place', data.user.place)
      Cookies.set('apikey', data.user.apikey)
      Cookies.set('token', data.token)
      recordDB('user', JSON.stringify(data))
      return { decyph: jwt_decode(data.token), response: data }
    } else {
      return { response: data }
    }
  } else {
    const res = getDB('user')
    console.log(res)
    Cookies.set('first_name', res.data.user.first_name)
    Cookies.set('last_name', res.data.user.last_name)
    Cookies.set('email', res.data.user.email)
    Cookies.set('place', res.data.user.place)
    Cookies.set('apikey', res.data.user.apikey)
    Cookies.set('token', res.data.token)
    return { response: { data: res.data, success: res.success, local: true, message: res.message } }
  }
}

export const addField = async (props) => {
  if (getOnlineStatus()) {
    const { data } = await $authHost.post('fielddata/post', { field: { ...props } })
    return { response: data }
  } else {
    return { response: { success: false, message: 'Функция недоступна в оффлайн-режиме!' } }
  }
}
export const addWeather = async (props) => {
  if (getOnlineStatus()) {
    const { data } = await $authHost.post('fielddata/weather/post', { field: { ...props } })
    return { response: data }
  } else {
    return { response: { success: false, message: 'Функция недоступна в оффлайн-режиме!' } }
  }
}
export const getFields = async () => {
  if (getOnlineStatus()) {
    const { data } = await $authHost.get('fielddata/get')
    recordDB('notes', JSON.stringify(data))
    return { response: data }
  } else {
    const res = getDB('notes')
    return { response: { data: res.data, success: res.success, local: true, message: res.message } }
  }
}
export const getWeather = async () => {
  const { data } = await $authHost.get('fielddata/weather/get')
  return { response: data }
}
export const removeField = async (id) => {
  if (getOnlineStatus()) {
    const { data } = await $authHost.post('fielddata/remove', { id })
    return { response: data }
  } else {
    return { response: { success: false, message: 'Функция недоступна в оффлайн-режиме!' } }
  }
}
export const removeWeather = async (id) => {
  const { data } = await $authHost.post('fielddata/weather/remove', { id })
  return { response: data }
}
export const generateRegKey = async (props) => {
  if (getOnlineStatus()) {
    const { data } = await $authHost.post('admin/regkey', { props })
    return { response: data }
  } else {
    return { response: { success: false, message: 'Функция недоступна в оффлайн-режиме!' } }
  }
}
export const deactivateKey = async (key) => {
  if (getOnlineStatus()) {
    const { data } = await $authHost.post('admin/deactivate', { key })
    return { response: data }
  } else {
    return { response: { success: false, message: 'Функция недоступна в оффлайн-режиме!' } }
  }
}
export const getKeys = async () => {
  const { data } = await $authHost.get('admin/getkeys')
  return { response: data }
}
export const getMessages = async () => {
  const { data } = await $authHost.get('admin/getmessages')
  return { response: data }
}
export const removeMessage = async (id) => {
  const { data } = await $authHost.post('admin/removemessage', { id })
  return { response: data }
}
export const getUsers = async () => {
  const { data } = await $authHost.get('admin/getusers')
  return { response: data }
}
export const activate = async (regkey) => {
  const { data } = await $authHost.post('user/activate', { regkey, email: Cookies.get('email') })
  if (data.success) {
    Cookies.set('token', data.token)
    return { decyph: jwt_decode(data.token), response: data }
  } else {
    return { response: data }
  }
}
export const addGeoJSON = async (json) => {
  if (getOnlineStatus()) {
    const { data } = await $authHost.post('map/add', { json })
    return { response: data }
  } else {
    return { response: { success: false, message: 'Функция недоступна в оффлайн-режиме!' } }
  }
}
export const addMarker = async (json) => {
  if (getOnlineStatus()) {
    const { data } = await $authHost.post('map/marker/add', { json })
    return { response: data }
  } else {
    return { response: { success: false, message: 'Функция недоступна в оффлайн-режиме!' } }
  }
}
export const getGeoJSON = async (onlyProps = false) => {
  if (getOnlineStatus()) {
    const { data } = await $authHost.get(`map/get?onlyProps=${onlyProps}`)
    recordDB('jsonfields', JSON.stringify(data))
    return { response: data }
  } else {
    const res = getDB('jsonfields')
    return { response: { ...res.data, success: res.success, local: true, message: res.message } }
  }
}
export const getDemoGeoJSON = async () => {
  const { data } = await $host.get('user/demofields')
  return { response: data }
}
export const deleteGeoJSON = async (id) => {
  if (getOnlineStatus()) {
    const { data } = await $authHost.post('map/delete', { id })
    return { response: data }
  } else {
    return { response: { success: false, message: 'Функция недоступна в оффлайн-режиме!' } }
  }
}
export const getPlaces = async () => {
  const { data } = await $host.get('user/places')
  return { response: data }
}
export const quickLook = async () => {
  const { data } = await $authHost.get('map/quicklook')
  return { response: data }
}
export const updateGeoJSON = async (id, newname, cadaster, soil) => {
  if (getOnlineStatus()) {
    const { data } = await $authHost.put('map/update', { id, newname, cadaster, soil })
    return { response: data }
  } else {
    return { response: { success: false, message: 'Функция недоступна в оффлайн-режиме!' } }
  }
}
export const addRecommendation = async (props) => {
  if (getOnlineStatus()) {
    const { data } = await $authHost.post('map/recommend', { ...props })
    return { response: data }
  } else {
    return { response: { success: false, message: 'Функция недоступна в оффлайн-режиме!' } }
  }
}
export const getRecommendation = async () => {
  const { data } = await $authHost.get('map/recommend')
  return { response: data }
}
export const removeRecommendation = async (id) => {
  const { data } = await $authHost.post('map/recommend/delete', { id })
  return { response: data }
}
export const getFieldProps = async (name) => {
  const { data } = await $authHost.post('fielddata/props', { name })
  return { response: data }
}
export const getFieldMap = async (props) => {
  const { data } = await $authHost.post('map/satellite', { ...props })
  return { response: data }
}
export const getFieldIndexes = async (props) => {
  const { data } = await $authHost.post('map/indexes', { ...props })
  return { response: data }
}
export const getElementsMap = async (props) => {
  const { data } = await $authHost.post('map/element_map', { ...props })
  return { response: data }
}
export const verifyToken = async () => {
  if (getOnlineStatus()) {
    const { data } = await $authHost.get('user/verify')
    // recordDB('user', JSON.stringify(data))
    return { response: data }
  } else {
    const res = getDB('user')
    return { response: { ...res.data, success: res.success, local: true, message: res.message } }
  }
}
export const getVehicleList = async () => {
  const { data } = await $authHost.get('vehicle/all')
  return { response: data }
}
export const getVehicleTelemetry = async () => {
  const { data } = await $authHost.get('vehicle/telemetry')
  return { response: data }
}
export const getSatImages = async () => {
  if (getOnlineStatus()) {
    const { data } = await $authHost.get('map/satimages')
    recordDB('satimages', JSON.stringify(data))
    return { response: data }
  } else {
    const res = getDB('satimages')
    return { response: { data: res.data, success: res.success, local: true, message: res.message } }
  }
}
export const deleteSatImage = async (name) => {
  if (getOnlineStatus()) {
    const { data } = await $authHost.delete(`map/satimages?name=${name}`)
    return { response: data }
  } else {
    return { response: { success: false, message: 'Функция недоступна в оффлайн-режиме!' } }
  }
}
export const downloadSatImage = async (name, type) => {
  if (getOnlineStatus()) {
    const { data } = await $authHost.get(`map/download?name=${name}&type=${type}`)
    return data
  } else {
    return { response: { success: false, message: 'Функция недоступна в оффлайн-режиме!' } }
  }
}
export const downloadPredData = async () => {
  const { data } = await $authHost.get('fielddata/downloadpreddata')
  return data
}
export const downloadWeatherData = async () => {
  const data = await $authHost.get('fielddata/downloadweatherdata')
  return data
}
export const getDataPoints = async ({ fid, season, chem }) => {
  const { data } = await $authHost.get(`map/datapoint?fid=${fid}&season=${season}&chem=${chem}`)
  return { response: data }
}
export const getDataPointsClasses = async (fid, season, type) => {
  const { data } = await $authHost.get(`map/classes?fid=${fid}`)
  return { response: data }
}
export const deleteDataPoint = async (id) => {
  const { data } = await $authHost.delete(`map/datapoint?id=${id}`)
  return { response: data }
}
export const createDataPoint = async (props) => {
  const { data } = await $authHost.post('map/datapoint', { ...props })
  return { response: data }
}
export const getIndexEnhance = async (form) => {
  if (getOnlineStatus()) {
    const { data } = await $authHost.post('map/enhanceindex', form, {
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    })
    return { response: data }
  } else {
    return { response: { success: false, message: 'Функция недоступна в оффлайн-режиме!' } }
  }
}
export const getImageEnhance = async (form) => {
  if (getOnlineStatus()) {
    const { data } = await $authHost.post('map/enhanceimage', form, {
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    })
    return { response: data }
  } else {
    return { response: { success: false, message: 'Функция недоступна в оффлайн-режиме!' } }
  }
}
export const getVehicleRoute = async (id) => {
  const { data } = await $authHost.get(`vehicle/route?id=${id}`)
  return { response: data }
}
export const getElementData = async () => {
  const { data } = await $authHost.get('map/element_data')
  return { response: data }
}
export const getYieldPrediction = async (props) => {
  const { data } = await $authHost.post('fielddata/getprediction', props)
  return { response: data }
}
export const cropIdentification = async (form) => {
  const { data } = await $authHost.post('map/identify_crop', form, {
    headers: {
      'Content-Type': 'multipart/form-data'
    }
  })
  return { response: data }
}
