import { BrowserRouter as Router, Switch, Route } from 'react-router-dom'
import mapMain from './pages/map_main'
import Title from './pages/Title'
import Aboutus from './pages/AboutUs'
import Profile from './pages/Profile'
import Normcalculator from './pages/NormCalculator'
import Calculatorlist from './pages/CalculatorList'
import Analysis from './pages/Analysis'
import Manual from './pages/Manual'
import React from 'react'
import Recommend from './pages/Recommend'
import DataSave from './pages/DataSave'
import AdminSettings from './pages/AdminSettings'
import jwt_decode from 'jwt-decode'
import Cookies from 'js-cookie'
import ExternalLinks from './pages/ExternalLinks'
import Info from './pages/Info'
import DataAnalysis from './pages/DataAnalysis'
import WeatherSave from './pages/WeatherSave'
import Auth from './pages/Auth'
import MapSat from './map/MapSat'
import mapSatWrap from './pages/map_sat'
import APIref from './pages/APIref'
import AnalysisOHMK from './pages/AnalysisOHMK'
import InfoSpecial from './pages/InfoSpecial'
import { verifyToken } from './http/API'
import VehicleTracking from './pages/VehicleTracking'
import mapVeh from './pages/map_veh'
import MapEnc from './map/MapEnc'
import MapChem from './map/MapChem'
import Docs from './pages/Docs'
import YieldPrediction from './pages/YieldPrediction'
import MapPred from './map/MapPred'
import InfoList from './pages/InfoList'
import InfoPest from './pages/InfoPest'
import AnalysisOHMK2 from './pages/AnalysisOHMK2'
import MapBase from './pages/MapBase'
import DocsList from './pages/DocsList'
import MapDemo from './map/MapBasic'
import SoilList from './pages/SoilList'
import C3IS from './pages/C3IS'
import MapMacro from './map/MapMacro'
import MapCrops from './map/MapCrops'
import UAVList from './pages/UAVList'
import AnalysisOHMK3 from './pages/AnalysisOHMK3'
import AnalysisBorod from './pages/AnalysisBorod'

function App () {
  const isVerifiedToken = async () => {
    const check = await verifyToken().then(res => {
      if (res.response.success) {
        Cookies.set('first_name', res.response.first_name)
        Cookies.set('last_name', res.response.last_name)
        Cookies.set('email', res.response.email)
        Cookies.set('place', res.response.place)
        Cookies.set('apikey', res.response.apikey)
      } else {
        Cookies.remove('token')
        window.location.reload()
      }
    })
  }
  const token = Cookies.get('token')
  let userRole
  try {
    userRole = token ? jwt_decode(token).role : undefined
  } catch {
    Cookies.remove('token')
    window.location.reload()
  }
  if (token) {
    try {
      const isTokenExpired = t => Date.now() >= (JSON.parse(atob(t.split('.')[1]))).exp * 1000
      if (isTokenExpired(token)) {
        Cookies.remove('token')
        window.location.reload()
      }
      isVerifiedToken()
      console.log('token check')
    } catch {
      Cookies.remove('token')
      window.location.reload()
    }
  }
  return (
    <>
      <Router>
        <Switch>
          <Route path='/auth' component={Auth} />
          <Route path='/profile' component={Profile} />
          <Route path='/admin' component={userRole === 'ADMIN' ? AdminSettings : Profile} />
          <Route path='/map_main' component={userRole ? mapMain : MapDemo} />
          <Route path='/maps' component={MapBase} />
          <Route path='/chem_ohmk' component={AnalysisOHMK3} />
          <Route path='/chem_borod' component={AnalysisBorod} />
          <Route path='/map_sat' component={mapSatWrap} />
          <Route path='/map_crop' component={userRole ? MapCrops : Profile} />
          <Route path='/apiref' component={APIref} />
          <Route path='/analysis' component={userRole ? MapChem : Profile} />
          <Route path='/info' component={userRole ? Info : Profile} />
          <Route path='/infospecial' component={userRole ? InfoSpecial : Profile} />
          <Route path='/infolist' component={InfoList} />
          <Route path='/info_pest' component={InfoPest} />
          <Route path='/soil' component={SoilList} />
          <Route path='/c3is' component={C3IS} />
          {/* <Route path='/vehicles' component={userRole ? VehicleTracking : Profile} /> */}
          <Route path='/vehiclemap' component={userRole ? mapVeh : Profile} />
          {/* <Route path='/map_enc' component={userRole ? MapEnc : Profile} /> */}
          <Route path='/docs' component={Docs} />
          <Route path='/docs_list' component={DocsList} />
          <Route path='/uav_docs' component={UAVList} />
          <Route path='/yield_prediction' component={userRole ? MapPred : Profile} />
          <Route path='/culturesmap' component={userRole ? AnalysisOHMK2 : Profile} />
          <Route path='/chemmap' component={userRole ? AnalysisOHMK : Profile} />
          <Route path='/map_elements' component={userRole ? MapMacro : Profile} />
          <Route path='/datasave/:id' component={userRole ? DataSave : Profile} />
          <Route exact path='*' component={Title} />
        </Switch>
      </Router>
    </>
  )
}

export default App
