function LayerList ({ adClass }) {
  return (
    <div className={`dropdown-content ${adClass}`}>
      <menu className='dropdown-lister'>
        <div>
          <h4>Физические объекты</h4>
          <div><input type='checkbox' />&nbsp;Крупные реки</div>
          <div><input type='checkbox' />&nbsp;Океаны</div>
        </div>
        <div>
          <h4>Поля</h4>
          <div><input type='checkbox' />&nbsp;ОХМК</div>
        </div>
        <div>
          <h4>Демография</h4>
          <div><input type='checkbox' />&nbsp;Страны</div>
          <div><input type='checkbox' />&nbsp;Населенные пункты</div>
          <div><input type='checkbox' />&nbsp;Точки сбора</div>
        </div>
      </menu>
    </div>
  )
}

export default LayerList
