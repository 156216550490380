import * as FA from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Alert, Button, DateRangePicker, Panel, SelectPicker } from 'rsuite'
import { getFieldIndexes } from '../../http/API'

function MacroElements ({ fieldID, layerRef, adClass, coordinates, mapRef, fieldsList, setFieldID, goto, update, ukey }) {
  const { t } = useTranslation()
  const [data, setData] = useState({})
  const [dispData, setDispData] = useState({})
  const [points, setPoints] = useState()
  const [selName, setName] = useState()
  const [indexNDVI, setNDVI] = useState()
  const [indexMSAVI, setMSAVI] = useState()
  const [resChems, setResChems] = useState()
  const [dateRange, setDateRange] = useState()
  const [cloudiness, setCloudiness] = useState()
  const [selElement, setElement] = useState()
  const [cropType, setCrop] = useState()
  const [loading, setLoading] = useState(false)
  const crops = [
    {
      label: t('common.tp.maslo'),
      value: 'maslo'
    },
    {
      label: t('common.tp.zbob'),
      value: 'zbob'
    },
    {
      label: t('common.tp.zerno'),
      value: 'zerno'
    },
    {
      label: t('common.tp.kormo'),
      value: 'kormo'
    }
  ]
  const dic = {
    maslo: t('common.tp.maslo'),
    zbob: t('common.tp.zbob'),
    zerno: t('common.tp.zerno'),
    kormo: t('common.tp.kormo')
  }
  const {
    afterToday
  } = DateRangePicker
  const chems = [
    t('common.humus'),
    t('common.nitro'),
    t('common.potass'),
    t('common.phos')
  ]
  const chemCodes = [
    'humus',
    'N',
    'K2O',
    'P2O5'
  ]
  const dataList = [0, 1, 2, 3].map(e => ({ label: chems[e], value: e }))

  useEffect(async () => {
    if (mapRef?.current?.hasLayer(layerRef.current)) layerRef.current.clearLayers()
    setData({ field_id: fieldID, chem: data.chem })
    const field = fieldsList.filter(e => e.id == fieldID)[0]
    setName(field && JSON.parse(field?.json).properties.name)
    setDispData({ fid: fieldID, chem: dispData.chem })
  }, [fieldID])

  useEffect(() => {
    coordinates && setData({ ...data, coordinates: coordinates.geometry.coordinates })
  }, [coordinates])

  const getChemData = async () => {
    if (cropType && dateRange && fieldID) {
      setLoading(true)
      const resp = await getFieldIndexes({ id: fieldID, daterange: dateRange, cropType }).then(r => {
        setLoading(false)
        update(ukey + 1)
        if (r.response.success) {
          setResChems(r.response.chems)
          setNDVI(r.response.ndvi)
          setMSAVI(r.response.msavi)
        } else {
          Alert.error(r.response.message)
        }
      })
    } else {
      Alert.error(t('mc.errordata'))
    }
  }
  function arrayBufferToBase64 (buffer) {
    let binary = ''
    const bytes = new Uint8Array(buffer)
    const len = bytes.byteLength
    for (let i = 0; i < len; i++) {
      binary += String.fromCharCode(bytes[i])
    }
    return window.btoa(binary)
  }
  return (
    <div className={`datapoints-list ${adClass}`}>
          <h4>{t('mc.selfield')} <br />{selName || t('mc.nofield')}</h4>
      <Panel bordered>
        <h5>{t('mc.fieldselection')}</h5>
        <div className='datapoint-fieldlist'>
        {
          fieldsList.map((e, key) => {
            const props = JSON.parse(e.json).properties
            //
            return (
              <button key = {(key + 100) * 39} className='datapoint-fielditem' style={e.id === fieldID ? { color: 'lime' } : { color: '#FFF' }} onClick={() => { setFieldID(e.id); setName(props.name) }}>
                {props.name}
              </button>
            )
          })
        }
        </div>
      </Panel>
      <br />
      <Panel bordered className='dp-center'>
        <h5>{t('mc.rangeselection')} </h5>
        <p>{t('mc.rangetip')}</p>
        <br />
        <DateRangePicker placement='top' showOneCalendar onChange={e => setDateRange(e)} hoverRange="month" oneTap ranges={[]} disabledDate={afterToday()}/>
        <br />
        <SelectPicker placement='topLeft' onChange={(e) => setCrop(e)} data={crops} placeholder={t('map.sidebar.enhance.crop')} />
        <br />
        {/* <h5>Выберите максимальную облачность:</h5>
        <br />
        <Slider progress onChange={e => setCloudiness(e)} />
        <br /> */}
        {!loading ? <Button onClick={() => dateRange && fieldID && getChemData() ? console.log(dateRange, cloudiness) : Alert.error('Проверьте введенные данные!')}>{t('mc.download')}</Button> : <Button><FontAwesomeIcon icon={FA.faSpinner} spin /></Button>}
        </Panel>
        <br />
        {resChems && <Panel bordered className='dp-center'>
          <h5>{t('mc.res')}</h5>
          {indexNDVI > 0.2 &&
          <table className='dp-table'>
          <tr>
            <th>{t('common.humus')} {}:</th>
            <th>N:</th>
            <th>K<sub>2</sub>O:</th>
            <th>P<sub>2</sub>O<sub>5</sub>:</th>
          </tr>
          <tr>
            <td>(%)</td>
            <td>{t('mc.mgperf')}</td>
            <td>{t('mc.mgperf')}</td>
            <td>{t('mc.mgperf')}</td>
          </tr>
          <tr>
            <td>{resChems[0].toFixed(3)}</td>
            <td>{resChems[1].toFixed(3)}</td>
            <td>{resChems[2].toFixed(3)}</td>
            <td>{resChems[3].toFixed(3)}</td>
          </tr>
          </table>}
        {indexNDVI && <p><h5>NDVI: {indexNDVI}</h5>  </p>}
        {indexMSAVI && <p><h5>MSAVI: {indexMSAVI}</h5>  </p>}
        {indexNDVI > 0.2
          ? <><h6>{t('mc.goto')} <a onClick={goto}> {t('mc.maps')}</a></h6>
        {/* <p>(занимает около минуты)</p>
        <SelectPicker placement='top' data={dataList} searchable={false} value={selElement} onChange={setElement} /> */}
        <br />
        {/* {!loading ? <Button onClick={downloadIndexImage} >Сгенерировать картограмму</Button> : <Button><FontAwesomeIcon icon={FA.faSpinner} spin /></Button>}  */}
        </>
          : <h6>{t('mc.impossible')}</h6>}
      </Panel>}
    </div>
  )
}

export default MacroElements
